import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'

import { routesApp } from 'src/routesService'
import { checkPermissions } from 'src/helpers/permissions'
import { NEWS_PAGE_PERMISSIONS } from 'src/utils/constants/permissions'

import PageContainer from 'src/containers/shared/PageContainer'

import BarraBusqueda from './shared/BarraBusqueda'
import TableNewsPages from './shared/TableNewsPages'
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';

const NewsPages = ({
  news, pagination, pageStates, loading, match, ...rest
}) => {
  const newsRotues = routesApp.newsPage.children;
  const { permissions } = useSelector((state) => state.auth.data);

  const hasPermission = {
    add: checkPermissions(permissions, [NEWS_PAGE_PERMISSIONS.CREATE_PAGINAS]),
    edit: checkPermissions(permissions, [NEWS_PAGE_PERMISSIONS.EDIT_PAGINAS]),
    delete: checkPermissions(permissions, [NEWS_PAGE_PERMISSIONS.DELETE_PAGINAS]),
  }

  return (
    <PageContainer title="Páginas de Noticias" maxWidth={false}>
      {
          hasPermission.add
          && (
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <PrimaryLinkButton to={`${match.url}/${newsRotues.add.path}`}>
              + Añadir Página de Noticias
            </PrimaryLinkButton>
          </Box>
          )
        }
      <BarraBusqueda states={pageStates} />
      <Box mt={3}>
        <TableNewsPages
          className=""
          data={news}
          hasPermission={hasPermission}
          currentPage={pagination.current_page}
          itemsCountPerPage={pagination.per_page}
          totalItemsCount={pagination.total}
          nextPage={pagination.next_page_url}
          prevPage={pagination.prev_page_url}
          lastPage={pagination.last_page}
          loading={loading}
          getAllPaginas={rest.fetchPage}
          deletePagina={rest.deletePage}
        />
      </Box>
    </PageContainer>
  )
}

export default NewsPages
