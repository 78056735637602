import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router';
import { routesApp } from 'src/routesService';
import { validateOnePermission } from 'src/helpers/permissions';
import { USERS_PERMISSIONS } from 'src/utils/constants/permissions';
import DeleteItem from 'src/components/shared/DeleteItem';

const ITEM_HEIGHT = 48;

export default function Actions({ authPermissions, user, deleteUser }) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {
          validateOnePermission(authPermissions, USERS_PERMISSIONS.EDIT_USERS) &&
          <MenuItem onClick={() => {
            history.push(routesApp.editUser.path.replace(":userId", user.id))
          }}>
            Editar
          </MenuItem>
        }
        {
          validateOnePermission(authPermissions, USERS_PERMISSIONS.DELETE_USERS) &&
          <DeleteItem 
            deleteAction={deleteUser}
            item={user.id}
            message={`¿Deseas eliminar el rol "${user.name} ${user.lastname}"?`}
            title="Eliminar Usuario"
          />
        }
      </Menu>
    </div>
  );
}
