import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  // Button,
  // createTheme,
  FormControl,
  FormControlLabel,
  // FormHelperText,
  // FormLabel,
  Grid,
  // IconButton,
  // makeStyles,
  // Radio,
  // RadioGroup,
  Switch,
  TextField,
  // ThemeProvider,
  Typography,
} from '@material-ui/core';
// import { Save as SaveIcon } from '@material-ui/icons';
import { LOGOS_PERMISSIONS } from 'src/utils/constants/permissions';
import { validateOnePermission } from 'src/helpers/permissions';
import { dataURItoBlob, URLRegex } from 'src/helpers/random';
// import { routesApp } from 'src/routesService';
import {
  // BackButton as GoBackBtn,
  SaveButton as SubmitBtn
} from './Buttons';
import EditorImage from './EditorImage';

const FormLogo = (props) => {
  const { logo, loading, authPermissions, hasMania = false } = props;

  const editorRef = React.createRef();
  const [fileTouched, setFileTouched] = React.useState(false);

  return (
    <Formik
      initialValues={{
        text: logo ? logo.alt_text : '',
        url: logo ? logo.url : '',
        file: logo ? `${process.env.REACT_APP_BASE_URL_STORAGE}${logo.file}?${Date.now()}` : '',
        active: logo?.active === true,
      }}
      validationSchema={Yup.object().shape({
        text: Yup.string().max(200, 'Sólo se permite un maximo de 200 caracteres'),
        url: Yup.string()
          .max(255, 'Sólo se permite un máximo de 255 caracteres')
          .required('El url es requerido')
          .test('valid-url', 'La URL ingresada es inválida', (str) => !!URLRegex.test(str)),
        file: Yup.string().required('El logo es requerido')
      })}
      onSubmit={(data) => {
        const newData = new FormData();
        const isFilename = (typeof data.file === 'string' || data.file instanceof String);

        let isSVG;
        if (isFilename) {
          const splitURL = data.file.split('/');
          const filenameArr = splitURL[splitURL.length - 1].split('?')[0].split('.');
          isSVG = filenameArr[filenameArr.length - 1] === 'svg';
        } else {
          isSVG = data.file.type === 'image/svg+xml';
        }

        if (isSVG) {
          if (isFilename) {
            newData.append('filename', logo.file);
          } else {
            newData.append('file', data.file);
          }
          newData.append('styles', JSON.stringify(
            {
              borderRadius: editorRef.current.props.borderRadius,
              transform: `rotate(${editorRef.current.props.rotate}deg) scale(${editorRef.current.props.scale})`,
              rotate: editorRef.current.props.rotate,
              scale: editorRef.current.props.scale,
              height: editorRef.current.props.height,
              width: editorRef.current.props.width,
              positionX: editorRef.current.props.position.x,
              positionY: editorRef.current.props.position.y,
              bottom: (editorRef.current.props.position.y - 0.5) * 200,
              right: (editorRef.current.props.position.x - 0.5) * 200,
            }
          ));
        } else {
          if (fileTouched || !isFilename) {
            const canvas = editorRef.current.getImageScaledToCanvas();
            const blob = dataURItoBlob(canvas.toDataURL());
            newData.append('file', blob);
          } else {
            newData.append('filename', logo.file);
          }

          newData.append('styles', JSON.stringify({
            borderRadius: editorRef.current.props.borderRadius,
            height: editorRef.current.props.height,
            width: editorRef.current.props.width,
            positionX: editorRef.current.props.position.x,
            positionY: editorRef.current.props.position.y
          }));
        }

        newData.append('alt_text', data.text);
        newData.append('url', data.url);
        newData.append('active', data.active ? 1 : 0);

        props.updateLogo(newData);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        // setFieldTouched, Build forms in React, with EXTRA tears
        touched,
        values
      }) => (
        <Form>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <TextField
                error={Boolean(touched.text && errors.text)}
                fullWidth
                helperText={touched.text && errors.text}
                label="Texto alternativo*"
                margin="normal"
                name="text"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.text}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <TextField
                error={Boolean(touched.url && errors.url)}
                fullWidth
                helperText={touched.url && errors.url}
                label="URL"
                margin="normal"
                name="url"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.url}
                variant="outlined"
              />
            </Grid>
            {hasMania && (
              <>
                <Typography color="textPrimary" variant="overline" className="font-weight-bold">Estado</Typography>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={(
                      <Switch
                        checked={values.active}
                        onChange={handleChange}
                        name="active"
                        color="primary"
                      />
                    )}
                    label="Activo"
                  />
                </FormControl>
              </>
            )}
          </Grid>
          <EditorImage
            editorRef={editorRef}
            borderRadiusProp={logo && JSON.parse(logo.styles).borderRadius ? JSON.parse(logo.styles).borderRadius : 0}
            positionXProp={logo && JSON.parse(logo.styles).positionX ? JSON.parse(logo.styles).positionX : 0.5}
            positionYProp={logo && JSON.parse(logo.styles).positionY ? JSON.parse(logo.styles).positionY : 0.5}
            rotateProp={logo && JSON.parse(logo.styles).rotate ? JSON.parse(logo.styles).rotate : 0}
            scaleProp={logo && JSON.parse(logo.styles).scale ? JSON.parse(logo.styles).scale : 1}
            widthProp={logo && JSON.parse(logo.styles).width ? JSON.parse(logo.styles).width : 250}
            heightProp={logo && JSON.parse(logo.styles).height ? JSON.parse(logo.styles).height : 250}
            handleBlur={handleBlur}
            handleChange={(value) => setFieldValue('file', value)}
            setFileTouched={setFileTouched}
            touched={fileTouched}
            errors={errors.file}
            value={values.file}
            styleProp={{ backgroundColor: 'rgb(52, 186, 188)' }}
            changed={false}
          />
          {
            validateOnePermission(authPermissions, LOGOS_PERMISSIONS.EDIT_LOGO)
            && (
              <Grid container>
                <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
                  <SubmitBtn disabled={loading} />
                </Grid>
              </Grid>
            )
          }
        </Form>
      )}
    </Formik>
  );
};

FormLogo.propTypes = {
  logo: PropTypes.object.isRequired,
  // styles: PropTypes.string,
};

export default FormLogo;
