import React, { useState } from 'react'
import { Grid, TextField } from '@material-ui/core';

import { getDataFormatedForSelects, dataURItoBlob } from 'src/helpers/random';
import SelectField from 'src/components/shared/SelectField';
import axiosInstance from 'src/api';
import { COMPONENTS_TYPES } from 'src/utils/constants/api';
import { addNewFila, getOptionsRows } from 'src/helpers/components';

import FormDestacadoPrincipal from './form-destacado-principal'
import FormDestacadoImagenDesplazada from './form-destacado-imagen-desplazada';
import FormBuscador from './form-buscador';
import FormTitulo from './form-titulo';
import FormTexto from './form-texto';
import FormEmbebido from './form-embebido';
import FormNoticia from './form-noticia';

const FormWithFilas = ({ fComponent: Component, ...rest }) => {
  const [filas, setFilas] = useState(getOptionsRows(rest.allComponents));

  function addFila() {
    setFilas(addNewFila(filas))
  }

  return (
    <Component
      filas={filas}
      addFila={addFila}
      {...rest}
    />
  )
}

const FormComponent = (props) => {
  const {
    // eslint-disable-next-line camelcase
    component, types_component, allComponents, loading, ...rest
  } = props
  const [type, setType] = useState(component ? { label: component.type.display_name, value: component.type.id } : getDataFormatedForSelects(types_component, 'id', 'display_name')[0])

  const appendImage = (formData, data, editorImage, fileIsTouched) => {
    if (process.env.REACT_APP_CKFINDER_ACTIVE === 'false') {
      const isFilename = (typeof data.image === 'string' || data.image instanceof String);
      let isSVG;

      if (isFilename) {
        const splitURL = data.image.split('/');
        const filenameArr = splitURL[splitURL.length - 1].split('?')[0].split('.');
        isSVG = filenameArr[filenameArr.length - 1] === 'svg';
      } else {
        isSVG = data.image.type === 'image/svg+xml';
      }

      if (isSVG) {
        if (isFilename) {
          const imageRoute = data.image.split('/');
          imageRoute.splice(0, 3);
          formData.append('image[filename]', imageRoute.join('/'));
        } else {
          formData.append('image[file]', data.image);
        }

        const imageStyles = JSON.stringify(
          {
            borderRadius: editorImage.props.borderRadius,
            transform: `rotate(${editorImage.props.rotate}deg) scale(${editorImage.props.scale})`,
            rotate: editorImage.props.rotate,
            scale: editorImage.props.scale,
            height: editorImage.props.height,
            width: editorImage.props.width,
            positionX: editorImage.props.position.x,
            positionY: editorImage.props.position.y,
            bottom: (editorImage.props.position.y - 0.5) * 200,
            right: (editorImage.props.position.x - 0.5) * 200,
          }
        );
        formData.append('image[styles]', imageStyles);
      } else {
        if (fileIsTouched || !isFilename) {
          const canvas = editorImage.getImageScaledToCanvas();
          const blob = dataURItoBlob(canvas.toDataURL());
          formData.append('image[file]', blob);
        } else {
          const imageRoute = data.image.split('/');
          imageRoute.splice(0, 3);
          formData.append('image[filename]', imageRoute.join('/'));
        }

        formData.append('image[styles]', JSON.stringify(
          {
            borderRadius: editorImage.props.borderRadius,
            height: editorImage.props.height,
            width: editorImage.props.width,
            positionX: editorImage.props.position.x,
            positionY: editorImage.props.position.y
          }
        ));
      }
    } else {
      const aux = data.image.split('/');
      aux.splice(0, 3);
      formData.append('image[filename]', aux.join('/'));
    }

    formData.append('image[alt_text]', data.alt_text);
  };

  // eslint-disable-next-line no-unused-vars
  const appendBackground = (formData, background, data, editorImage, fileIsTouched) => {
    formData.append('background[type]', background === '1' ? 'image' : 'color');
    if (background === '1' && data.image_background) {
      if (process.env.REACT_APP_CKFINDER_ACTIVE === 'false') {
        if (typeof data.image_background === 'string' || data.image_background instanceof String) {
          const bkgImageRoute = data.image_background.split('/');
          bkgImageRoute.splice(0, 3);
          formData.append('background[filename]', bkgImageRoute.join('/'));
        } else {
          const imageToAppend = data.image_background;
          formData.append('background[file]', imageToAppend);
        }
        formData.append('background[styles]', JSON.stringify(
          {
            borderRadius: editorImage.props.borderRadius,
            height: editorImage.props.height,
            width: editorImage.props.width,
            rotate: editorImage.props.rotate,
            scale: editorImage.props.scale,
            positionX: editorImage.props.position.x,
            positionY: editorImage.props.position.y,
          }
        ));
      } else {
        const aux = data.image_background.split('/');
        aux.splice(0, 3);
        formData.append('background[filename]', aux.join('/'));
      }
    } else if (data.color_background) {
      formData.append('background[color_hex]', data.color_background);
    }
  };

  function deleteImage(imageFile) {
    if (imageFile) {
      const fileName = encodeURIComponent(imageFile.filename);
      console.log('filename', imageFile.filename, fileName);
      axiosInstance.delete(`/delete?filename=${fileName}`);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
        {
          component
            ? (
              <TextField
                fullWidth
                label="Componente"
                margin="normal"
                type="text"
                value={component.type.display_name}
                variant="outlined"
                disabled
              />
            ) : (
              <SelectField
                placeholder="Seleccione tipo..."
                getOptions={() => getDataFormatedForSelects(types_component, 'id', 'display_name')}
                label="Componente"
                setFieldValue={(value) => {
                  setType(value);
                }}
                value={type}
              />
            )
        }
      </Grid>
      {
        type.value === COMPONENTS_TYPES.DESTACADO_PRINCIPAL && (
          <FormDestacadoPrincipal
            componentType={type}
            component={component}
            allComponents={allComponents}
            loading={loading}
            addComponent={rest.addComponent}
            updateComponent={rest.updateComponent}
            appendImage={appendImage}
            appendBackground={appendBackground}
            deleteImage={deleteImage}
          />
        )
      }
      {
        type.value === COMPONENTS_TYPES.DESTACADO_IMAGEN_CENTRO && (
          <FormWithFilas
            fComponent={FormDestacadoImagenDesplazada}
            allComponents={allComponents}
            componentType={type}
            component={component}
            loading={loading}
            addComponent={rest.addComponent}
            updateComponent={rest.updateComponent}
            appendImage={appendImage}
            appendBackground={appendBackground}
          />
        )
      }
      {
        type.value === COMPONENTS_TYPES.TITULO && (
          <FormWithFilas
            fComponent={FormTitulo}
            allComponents={allComponents}
            componentType={type}
            component={component}
            loading={loading}
            addComponent={rest.addComponent}
            updateComponent={rest.updateComponent}
          />
        )
      }
      {
        type.value === COMPONENTS_TYPES.TEXTO && (
          <FormWithFilas
            fComponent={FormTexto}
            allComponents={allComponents}
            componentType={type}
            component={component}
            loading={loading}
            addComponent={rest.addComponent}
            updateComponent={rest.updateComponent}
          />
        )
      }
      {
        type.value === COMPONENTS_TYPES.DESTACADO_IMAGEN_IZQUIERDA && (
          <FormWithFilas
            fComponent={FormDestacadoImagenDesplazada}
            allComponents={allComponents}
            componentType={type}
            component={component}
            loading={loading}
            addComponent={rest.addComponent}
            updateComponent={rest.updateComponent}
            appendImage={appendImage}
            appendBackground={appendBackground}
          />
        )
      }
      {
        type.value === COMPONENTS_TYPES.BUSCADOR_RECURSOS && (
          <FormWithFilas
            fComponent={FormBuscador}
            allComponents={allComponents}
            component={component}
            loading={loading}
            addComponent={rest.addComponent}
            updateComponent={rest.updateComponent}
            componentType={type}
            appendBackground={appendBackground}
          />
        )
      }
      {
        type.value === COMPONENTS_TYPES.EMBEBIDO && (
          <FormWithFilas
            fComponent={FormEmbebido}
            allComponents={allComponents}
            component={component}
            loading={loading}
            addComponent={rest.addComponent}
            updateComponent={rest.updateComponent}
            componentType={type}
          />
        )
      }
      {
        type.value === COMPONENTS_TYPES.NOTICIA && (
          <FormNoticia
            componentType={type}
            component={component}
            allComponents={allComponents}
            loading={loading}
            addComponent={rest.addComponent}
            updateComponent={rest.updateComponent}
            appendImage={appendImage}
            appendBackground={appendBackground}
          />
        )
      }
    </Grid>
  )
}

export default FormComponent
