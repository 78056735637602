import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { validateOnePermission } from 'src/helpers/permissions';

const AuthorizedRoute = ({
  component: Component,
  permission,
  ...rest
}) => {
  const user = useSelector((state) => state.auth.data);

  if (!user?.permissions) return <></>

  return (
    // Solo muestra el componente si se tienen los permisos indicados
    // Sino, redirige a la pagina 403 Forbidden
    <Route
      render={(props) => (
        validateOnePermission(user.permissions, permission)
          ? <Component {...props} />
          : <Redirect to="/403" />
      )}
      {...rest}
    />
  );
};

export default AuthorizedRoute;
