import api from '../api';

const endpointName = 'news_pages'

const NewsPagesService = {
  getPaginas: (page, limit, filters) => {
    return api.get(`${endpointName}?page=${page}&limit=${limit}&${filters !== '' ? filters.substring(1) : filters}`)
  },

  addPagina: (data) => (
    api.post(`${endpointName}`, data)
  ),

  getOnePagina: (id) => (
    api.get(`${endpointName}/${id}`)
  ),

  updatePagina: (data, id) => (
    api.put(`${endpointName}/${id}`, data)
  ),

  deletePagina: (id) => (
    api.delete(`${endpointName}/${id}`)
  ),

  getStates: () => (
    api.get('institutional/states')
  ),

  generatePreviewInstitucional: (id) => (
    api.post(`${endpointName}/json/${id}`, {})
  ),

  generateTemporalPreviewInstitucional: (data) => (
    api.post(`${endpointName}/json/tmp`, data)
  ),

  temporalPreview: (data) => (
    api.post(`${endpointName}/json/tmp`, data)
  ),

  getListActive: () => (
    api.get(`${endpointName}/list`)
  ),
}

export { NewsPagesService as default };
