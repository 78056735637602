import {
  MENU_USR_TYPES
} from '../actions/types';

const INIT_STATE = {
  all: null,
  one: null,
  prev: null,
  errors: null,
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MENU_USR_TYPES.GET_MENUS_USR:
      return {
        ...state,
        loading: true
      };
    case MENU_USR_TYPES.GET_MENUS_USR_SUCCESS:
      return {
        ...state,
        all: action.payload,
        loading: false
      };
    case MENU_USR_TYPES.GET_MENUS_USR_FAILURE:
      return {
        ...state,
        all: null,
        loading: false
      };
    case MENU_USR_TYPES.GET_ONE_MENU_USR:
      return {
        ...state,
        loading: true
      };
    case MENU_USR_TYPES.GET_ONE_MENU_USR_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        one: action.payload
      };
    case MENU_USR_TYPES.GET_ONE_MENU_USR_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        one: null
      };
    case MENU_USR_TYPES.STORE_MENU_USR:
      return {
        ...state,
        loading: true
      };
    case MENU_USR_TYPES.STORE_MENU_USR_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case MENU_USR_TYPES.STORE_MENU_USR_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case MENU_USR_TYPES.GET_PREV_MENUS_USR:
      return {
        ...state,
        loading: true
      };
    case MENU_USR_TYPES.GET_PREV_MENUS_USR_SUCCESS:
      return {
        ...state,
        prev: action.payload,
        loading: false
      };
    case MENU_USR_TYPES.GET_PREV_MENUS_USR_FAILURE:
      return {
        ...state,
        prev: null,
        loading: false
      };
    default:
      return {
        ...state
      };
  }
};
