import React from 'react';
import CKEditorJuanaManso from '../CkeditorJuanaManso';

const CKEditorTexto = (props) => {
  const configCkeditorTexto = {
    height: 400,
    toolbar: [
      { name: 'heading', items: ['Format'] },
      { name: 'basicstyles', items: ['Bold', 'Italic'] },
      { name: 'styles', items: ['TextColor', 'BGColor', 'FontSize', 'Font'] },
      { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'HorizontalRule'] },
      { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
      // '/',
      { name: 'editing', items: ['Find', 'Replace', 'SelectAll', '-', 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
    ],
  };

  return (
    <div>
      <CKEditorJuanaManso
        configParent={configCkeditorTexto}
        {...props}
      />
    </div>
  );
};

export default CKEditorTexto;
