import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';

import TableHeader from 'src/components/shared/TableHeader';
import { PORTADAS_STATES } from 'src/utils/constants/api';

import Actions from './Actions';

const TablePortadas = ({
  className,
  data,
  currentPage,
  itemsCountPerPage,
  totalItemsCount,
  prevPage,
  nextPage,
  lastPage,
  hasPermission,
  loading,
  ...rest
}) => {
  const handlePageChange = (event, page) => {
    rest.getPortadasPage(page + 1, parseInt(itemsCountPerPage, 10));
  };

  const handleChangeRowsPerPage = (event) => {
    rest.getPortadasPage(1, Number(event.target.value));
  };

  const showAction = Object.entries(hasPermission).some(([key, value]) => key !== 'add' && value);

  return (
    <Card
      className={className}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Typography variant="subtitle1" className="mt-3 ml-3 mb-2">
            Listado de Portadas
            {
              loading
              && <CircularProgress size={24} style={{ marginLeft: 15 }} />
            }
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader property="name">Nombre</TableHeader>
                <TableHeader property="slug">Slug</TableHeader>
                <TableHeader property="state.name">Activo</TableHeader>
                {showAction && <TableHeader>Acciones</TableHeader>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((portada) => (
                <TableRow
                  hover
                  key={portada.id}
                >
                  <TableCell>
                    {portada.name}
                  </TableCell>
                  <TableCell>
                    {`${process.env.REACT_APP_JUANAMANSO_REA}${portada.slug}`}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      disabled
                      indeterminate={portada.state.id !== PORTADAS_STATES.PUBLICADO}
                      style={{ color: portada.state.id === PORTADAS_STATES.PUBLICADO ? 'green' : 'red' }}
                    />
                  </TableCell>
                  <TableCell>
                    <Actions
                      portada={portada}
                      deletePortada={rest.deletePortada}
                      hasPermission={hasPermission}
                      publicarPortada={rest.publicarPortada}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {
        data.length === 0
        && (
        <div className="text-center mt-2">
          No hay resultados para su búsqueda
        </div>
        )
      }
      <TablePagination
        component="div"
        className="paginador"
        count={totalItemsCount}
        // count={data.length}//hasta que venga la api
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={currentPage - 1}
        rowsPerPage={parseInt(itemsCountPerPage, 10)}
        rowsPerPageOptions={[5, 10, 20]}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </Card>
  );
};

export default TablePortadas;
