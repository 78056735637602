import React from 'react'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser';

import Page from '../Page'
import PageContainer from 'src/containers/shared/PageContainer';

const PrevisualizarPagina = (props) => {
  const { pagina } = props
  // const match = useRouteMatch()
  return (
    <PageContainer title="Previsualización" maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          Previsualización de página institucional
        </Typography>
        <Card>
          <CardContent className="ck-content" style={{padding:"2rem"}}>
            {
              pagina ?
                ReactHtmlParser(pagina.body)
                :
                localStorage.getItem('PAGE_PREVISUALIZAR')
                  ?
                  ReactHtmlParser(localStorage.getItem('PAGE_PREVISUALIZAR'))
                  :
                  "No hay contenido para previsualizar"
            }
          </CardContent>
        </Card>
    </PageContainer>
  )
}

export default PrevisualizarPagina
