import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button, createTheme, FormControl, FormHelperText, Grid, makeStyles, ThemeProvider, Typography
} from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Link, useRouteMatch } from 'react-router-dom';
import CKEditorTitulo from 'src/components/shared/CKEditor/CKEditorTitulo';
import { routesApp } from 'src/routesService';
import SelectField from 'src/components/shared/SelectField';

import { TAMANIOS_COMPONENTES_OPTIONS } from 'src/utils/constants/api';
import { getOptionsSize } from 'src/helpers/components';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});
const useStyles = makeStyles((_theme) => ({
  color: {
    margin: _theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));
const FormTitulo = (props) => {
  const classes = useStyles()
  const match = useRouteMatch()
  const {
    componentType,
    component,
    loading,
    filas,
    addFila,
    updateComponent,
    addComponent,
  } = props;

  const [maxSize, setMaxSize] = useState(component ? getOptionsSize(component.col) : []);

  return (
    <Formik
      initialValues={{
        title: component ? component.title : '',
        fila: component ? { label: component.row, value: component.row } : '',
        columna: component ? { label: component.col, value: component.col } : '',
        tamanio: component ? { label: component.size, value: component.size } : '',
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required('El titulo es requerido'),
        fila: Yup.string().required('El número de fila es requerido'),
        columna: Yup.string().required('El número de columna es requerido'),
        tamanio: Yup.string().required('El tamaño es requerido'),
      })}
      onSubmit={async (data) => {
        const newData = {
          front_page_id: match.params.portadaId,
          title: data.title,
          component_type_id: componentType.value,
          row: data.fila.value,
          col: data.columna.value,
          size: data.tamanio.value
        }
        if (component) {
          await updateComponent(newData, component.id)
        } else {
          await addComponent(newData)
        }
      }}
    >
      {({
        errors,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <Form className="w-100">
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <FormControl margin="normal" fullWidth error={Boolean(touched.fila && errors.fila)}>
                <SelectField
                  id="fila-titulo"
                  name="fila"
                  placeholder="Seleccione una fila..."
                  getOptions={() => filas}
                  label="Fila"
                  setFieldValue={(value) => {
                    setFieldValue('fila', value);
                    setFieldValue('columna', '');
                    setFieldValue('tamanio', '');
                  }}
                  value={values.fila}
                  addNewOption
                  addOption={() => addFila()}
                />
                <FormHelperText className="text-danger">{(touched.fila && errors.fila) && errors.fila}</FormHelperText>
              </FormControl>
              <FormControl margin="normal" fullWidth error={Boolean(touched.columna && errors.columna)}>
                <SelectField
                  id="columna-titulo"
                  name="columna"
                  placeholder="Seleccione una columna..."
                  getOptions={() => TAMANIOS_COMPONENTES_OPTIONS}
                  label="Columna"
                  setFieldValue={(value) => {
                    setFieldValue('columna', value);
                    setFieldValue('tamanio', '');
                    setMaxSize(getOptionsSize(value.value))
                  }}
                  value={values.columna}
                />
                <FormHelperText className="text-danger">{(touched.columna && errors.columna) && errors.columna}</FormHelperText>
              </FormControl>
              <FormControl margin="normal" fullWidth error={Boolean(touched.tamanio && errors.tamanio)}>
                <SelectField
                  id="tamanio-titulo"
                  name="tamanio"
                  placeholder="Seleccione un tamaño..."
                  getOptions={() => maxSize}
                  label="Tamaño"
                  setFieldValue={(value) => {
                    setFieldValue('tamanio', value);
                  }}
                  value={values.tamanio}
                />
                <FormHelperText className="text-danger">{(touched.tamanio && errors.tamanio) && errors.tamanio}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} className="pr-3 pl-3">
              <FormControl margin="normal" fullWidth error={Boolean(errors.title)}>
                <Typography align="left" color="textPrimary" variant="overline">Título</Typography>
                <CKEditorTitulo
                  id="ck-titulo"
                  value={values.title}
                  name="title"
                  styles={[
                    { name: 'height', value: '100px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.title && errors.title)}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={theme}>
                <Link to={routesApp.componentes.path.replace(':portadaId', match.params.portadaId)}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
export default FormTitulo
