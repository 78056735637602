import { PANEL_USER_TYPES } from '../actions/types';

const INIT_STATE = {
  all: null,
  one: null,
  errors: null,
  loading: false
};

export default ( state = INIT_STATE, action ) => {
  switch ( action.type ) {

    case PANEL_USER_TYPES.GET_PANEL_USER:
      return {
        ...state,
        loading: true
      }

    case PANEL_USER_TYPES.GET_PANEL_USER_SUCCESS:
      return {
        ...state,
        all: action.payload,
        loading: false
      }

    case PANEL_USER_TYPES.GET_PANEL_USER_FAILURE:
      return {
        ...state,
        all: null,
        loading: false
      }

    case PANEL_USER_TYPES.GET_ONE_PANEL_USER:
      return {
        ...state,
        loading: true
      };

    case PANEL_USER_TYPES.GET_ONE_PANEL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        one: action.payload,
        errors: null
      };

    case PANEL_USER_TYPES.GET_ONE_PANEL_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        one: null
      };

    case PANEL_USER_TYPES.DELETE_PANEL_USER:
        return {
            ...state, 
            loading: true
        }
    
    case PANEL_USER_TYPES.STORE_PANEL_USER: 
        return {
            ...state, 
            loading: false,
            erros: null
        }
    
    case PANEL_USER_TYPES.SHOW_PANEL_USER: 
        return {
            ...state,
            loading: false, 
            all: action.payload
        }


    default:
        return {
            ...state
        };
  }

};
