import api from '../api';

const AuthService = {
  login: (data) => {
    return api.post(`auth/login`, data)
  },
  logout: () => {
    return api.post(`auth/logout`)
  },
  getUser: () => {
    return api.get(`auth/user`)
  },
  updateProfile: (data) => {
    return api.put(`auth/user`, data)
  },
  sendMailResetPassword: (data) => {
    return api.post(`auth/password/recovery`, data)
  },
  sendNewPassword: (data) => {
    return api.post(`auth/password/reset`, data)
  }
}

export { AuthService as default };
