/* eslint-disable react/prefer-stateless-function */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { NotificationManager } from 'react-notifications'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'

import { getDataUser } from 'src/actions/authActions'
import AuthorizedRoute from 'src/components/shared/AuthorizedRoute'
import Loading from 'src/components/shared/loading'
import DashboardLayout from 'src/layouts/DashboardLayout/DashboardLayout'
import { routesApp } from 'src/routesService'
import { showDeveloperMessageConsole } from 'src/helpers/random'

class AppContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
    }

    this.onUserStatusUpdate = this.onUserStatusUpdate.bind(this);
    this.rejectLogin = this.rejectLogin.bind(this);
  }

  componentDidMount() {
    const { dispatchUser, auth: { authenticated } } = this.props
    if (authenticated) {
      dispatchUser()
        .catch((error) => {
          NotificationManager.error(error.response?.data?.message ?? null, 'Error trayendo user', 5000)
          this.rejectLogin()
        })
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { loading } = this.state;
    const { auth } = this.props;

    return loading !== nextState.loading
      || auth.authenticated !== nextProps.auth.authenticated
      || auth.loading !== nextProps.auth.loading
  }

  componentDidUpdate(prevProps) {
    const { auth } = this.props

    if (prevProps.auth.loading !== auth.loading) {
      this.onUserStatusUpdate(auth.loading)
    }

    if (prevProps.auth.data !== auth.data) {
      showDeveloperMessageConsole('user', auth.data ?? { guest: true })
      // showDeveloperMessageConsole('user-json', JSON.stringify(auth.data))
    }
  }

  onUserStatusUpdate(newStatus) {
    this.setState({
      loading: newStatus,
    })
  }

  rejectLogin() {
    const { history } = this.props

    localStorage.removeItem('access_token')
    history.push('/login')
  }

  render() {
    const { loading } = this.state
    const { auth: { authenticated } } = this.props

    if (loading) {
      return <Loading fullWidth />
    }

    if (!authenticated) {
      return <Redirect to="/login" />
    }

    return (
      <DashboardLayout>
        <Switch>
          {
          Object.keys(routesApp).map((key) => {
            const { element: ChildComponent, permission } = routesApp[key];
            if (routesApp[key].permission) {
              if (routesApp[key].notExact) {
                return (
                  <AuthorizedRoute
                    key={key}
                    path={`${routesApp[key].path}`}
                    component={ChildComponent}
                    permission={permission}
                  />
                )
              }

              return (
                <AuthorizedRoute
                  key={key}
                  exact
                  path={`${routesApp[key].path}`}
                  component={ChildComponent}
                  permission={permission}
                />
              )
            }
            return (
              <Route key={key} path={`${routesApp[key].path}`} component={routesApp[key].element} exact />
            )
          })
        }
        </Switch>
      </DashboardLayout>
    )
  }
}

AppContainer.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = {
  dispatchUser: getDataUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
