import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  itemIcon: {
    paddingLeft: "0.25rem"
  },
  itemSize:{
    fontSize:"0.90rem"
  }
}));

const NavItem = ({ className, href, icon: Icon, title, subItems, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false)

  const CustomLink = React.forwardRef((props, ref) => <Link ref={ref} to={href} {...props} />)
  
  if (subItems) {
    return (
      <>
        <ListItem button onClick={() => setOpen(!open)}>
          <Tooltip title={title}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          </Tooltip>
          <ListItemText primary={title} classes={{primary:classes.itemSize}} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="pl-2">
            {
              subItems.map((item, index) => (
                <NavItem
                  key={index}
                  icon={item.icon}
                  href={item.href}
                  title={item.title}
                />
              ))
            }
          </List>
        </Collapse>
      </>
    )
  }

  return (
    <ListItem button component={CustomLink}>
      <Tooltip title={title}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      </Tooltip>
      <ListItemText primary={title} classes={{primary:classes.itemSize}} />
    </ListItem>
  );
};

export default NavItem;
