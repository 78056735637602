import { NotificationManager } from 'react-notifications';

import { AUTH_TYPES } from './types'
import AuthService from '../services/AuthService'

export default {
  login: (user) => ({ type: AUTH_TYPES.LOGIN, payload: user }),
  update: (user) => ({ type: AUTH_TYPES.UPDATE_USER, payload: user })
}

export const getDataUser = () => async (dispatch) => {
  dispatch({
    type: AUTH_TYPES.GET_DATA_USER
  })
  try {
    const result = await AuthService.getUser();
    dispatch({
      type: AUTH_TYPES.GET_DATA_USER_SUCCESS,
      payload: result.data
    })
  } catch (error) {
    dispatch({
      type: AUTH_TYPES.GET_DATA_USER_FAILURE
    })
  }
}

export const logout = () => async (dispatch) => {
  dispatch({
    type: AUTH_TYPES.LOGOUT
  })
  NotificationManager.info('Cerrando sesión...')
  try {
    await AuthService.logout();
    localStorage.removeItem('access_token')
    dispatch({
      type: AUTH_TYPES.LOGOUT_SUCCESS
    })
  } catch (error) {
    console.error('Logout: ', error)
    dispatch({
      type: AUTH_TYPES.LOGOUT_FAILURE
    })
  }
}
