import Axios from 'axios';
// import { all_mock } from 'src/utils/mocks/headers';
import api from '../api';

const HeaderService = {
  getHeaders: (page, limit, filters) => {
    // return all_mock;
    return api.get(`header/menu?page=${page}&limit=${limit}&${filters !== '' ? filters.substring(1) : filters}`);
  },
  addHeader: (data) => {
    return api.post('header/menu', data);
  },
  getOneHeader: (id) => {
    // return one_mock;
    return api.get(`header/menu/${id}`);
  },
  updateHeader: (data, id) => {
    return api.put(`header/menu/${id}`, data);
  },
  deleteHeader: (id) => {
    return api.delete(`header/menu/${id}`);
  },
  getLogo: () => {
    return api.get('header/logo');
  },
  updateLogo: (data) => {
    return api.post(`header/logo`, data, {
      headers:{
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Accept': 'application/x-www-form-urlencoded'
      }
    });
  },
  getHeaderLogoPrev: () => {
    //return one_mock;
    return Axios.get(`${process.env.REACT_APP_BASE_URL_STORAGE}header/logo.json?${Date.now()}`);
  },
  getHeaderMenuPrev: () => {
    //return one_mock;
    return Axios.get(`${process.env.REACT_APP_BASE_URL_STORAGE}header/menu.json?${Date.now()}`);
  },
  getListActive: () => {
    // return all_mock.data;
    return api.get(`header/menu/list`);
  },
};

export { HeaderService as default };
