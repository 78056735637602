import api from '../api';

const UsersService = {
  getUsers: (page, limit, filters) => {
    return api.get(`users?page=${page}&limit=${limit}&${filters !== '' ? filters.substring(1) : filters}`)
  },
  addUser:(data)=>{
    return api.post(`users`, data)
  },
  getOneUser:(id)=>{
    return api.get(`users/${id}`)
  },
  updateUser:(data, id)=>{
    return api.put(`users/${id}`, data)
  },
  deleteUser:(id)=>{
    return api.delete(`users/${id}`)
  },
}

export { UsersService as default };
