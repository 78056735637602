import { NotificationManager } from 'react-notifications';

import { showDeveloperMessageConsole } from 'src/helpers/random';
import { PanelUserService } from 'src/services/PanelUserService';
import { PANEL_USER_TYPES } from './types';

export const getAllPanelUser = (page = 1, rows = 10, filters = '') => {
  return async (dispatch) => {
    dispatch({
      type: PANEL_USER_TYPES.GET_PANEL_USER
    });
    try {
      const res = await PanelUserService.index(page, rows, filters);
      dispatch({
        type: PANEL_USER_TYPES.GET_PANEL_USER_SUCCESS,
        payload: res.data
      });
    } catch (error) {
      dispatch({
        type: PANEL_USER_TYPES.GET_PANEL_USER_FAILURE
      });
      showDeveloperMessageConsole('Error al obtener el/los panel de usuario/s', error);
    }
  }
};

export const getOnePanelUser = (id) => async (dispatch) => {
  dispatch({
    type: PANEL_USER_TYPES.GET_ONE_PANEL_USER
  });
  try {
    const res = await PanelUserService.showPanelUser(id);
    dispatch({
      type: PANEL_USER_TYPES.GET_ONE_PANEL_USER_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: PANEL_USER_TYPES.GET_ONE_PANEL_USER_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener el panel de usuario: ', id, ' ', error);
  }
}

export const deletePanelUser = (id) => async (dispatch) => {

    dispatch({
        type: PANEL_USER_TYPES.DELETE_PANEL_USER
    }); 

    try {
        await PanelUserService.deletePanelUser(id);
        NotificationManager.success('Panel de usuario eliminado correctamente');
        
    } catch (error) {
        NotificationManager.error('Error al intentar eliminar el panel de usuario');
        showDeveloperMessageConsole('Error al intentar eliminar el panel de usuario', error)
    } finally {
        dispatch({
            type: PANEL_USER_TYPES.DELETE_PANEL_USER_SUCCESS
        })
    }; 
}; 

export const storePanelUser = (id, data) => async ( dispatch ) => {

    dispatch({
        type: PANEL_USER_TYPES.STORE_PANEL_USER
    });

    try {
        if (!id) {
          await PanelUserService.postPanelUser(data);
          NotificationManager.success('Panel de usuario creado exitosamente');
        } else {
          await PanelUserService.updatePanelUser(data, id);
          NotificationManager.success('Panel de usuario actualizado exitosamente');
        }
        dispatch({
          type: PANEL_USER_TYPES.STORE_PANEL_USER_SUCCESS
        });
    } catch (error) {
    dispatch({
        type: PANEL_USER_TYPES.STORE_PANEL_USER_FAILURE,
        payload: error.response ? error.response.data.errors : null
    });
    NotificationManager.error('Error al guardar el panel de usuario.');
  
      showDeveloperMessageConsole('Error al guardar el panel de usuario: ', error);
    }
}
