import React from 'react';
import {
    Card, CardContent, Container, Typography
} from '@material-ui/core';
import { FORM_ACTIONS } from 'src/utils/constants/general';

import Page from '../Page';
import ErrorsValidation from '../shared/displayErrorsValidations';
import FormFooter from './shared/FormFooter';
import PageContainer from 'src/containers/shared/PageContainer';

const EditFooter = (props) => {
  const {
    footer,
    errors,
    loading,
    paginasInst,
    portadas,
    updateFooter,
  } = props;

  // const match = useRouteMatch()
  return (
    <PageContainer title="Editar Pie de pagina" maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          Editando Pie de Página {`${footer.name}`}
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <FormFooter
              action={FORM_ACTIONS.EDIT}
              footer={footer}
              updateFooter={updateFooter}
              loading={loading}
              paginasInst={paginasInst}
              portadas={portadas}
            />
          </CardContent>
        </Card>
    </PageContainer>
  );
};

export default EditFooter;
