import React from 'react'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import Page from '../Page'
import { FORM_ACTIONS } from 'src/utils/constants/general'
import ErrorsValidation from '../shared/displayErrorsValidations'
import FormRol from './shared/form'
import PageContainer from 'src/containers/shared/PageContainer';

const AddRol = (props) => {
  const {
    permissions,
    errors,
    loading,
    addRol,
  } = props;

  return (
    <PageContainer title="Agregar Rol" maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          Agregando Rol
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <FormRol
              action={FORM_ACTIONS.ADD}
              permissions={permissions}
              addRol={addRol}
              loading={loading}
            />
          </CardContent>
        </Card>
    </PageContainer>
  )
}

export default AddRol
