import {
  USER_TYPES
} from '../actions/types';

const INIT_STATE = {
  all: null,
  one: null,
  errors: null,
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case USER_TYPES.GET_USERS:
      return {
        ...state,
        loading: true
      }
    case USER_TYPES.GET_USERS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        one: null,
        loading: false
      }
    case USER_TYPES.GET_USERS_FAILURE:
      return {
        ...state,
        all: null,
        one: null,
        loading: false
      }
    case USER_TYPES.STORE_USER:
      return {
        ...state,
        loading: true
      }
    case USER_TYPES.STORE_USER_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false
      }
    case USER_TYPES.STORE_USER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false
      }
    case USER_TYPES.GET_ONE_USER:
      return {
        ...state,
        loading: true
      }
    case USER_TYPES.GET_ONE_USER_SUCCESS:
      return {
        ...state,
        errors: null,
        one: action.payload,
        loading: false
      }
    case USER_TYPES.GET_ONE_USER_FAILURE:
      return {
        ...state,
        errors: null,
        one: null,
        loading: false
      }
    default: return { ...state };
  }
}
