import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Actions from 'src/components/roles/shared/actions';
import { validatePermissions } from 'src/helpers/permissions';
import { ROLES_PERMISSIONS } from 'src/utils/constants/permissions';
import TableHeader from 'src/components/shared/TableHeader';

const TableRoles = ({ auth, className, data, loading, ...rest }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card
      className={className}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Typography variant="subtitle1" className="mt-3 ml-3 mb-2">
            Listado de Roles
              {
              loading &&
              <CircularProgress size={24} style={{ marginLeft: 15 }} />
            }
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader property="id">ID</TableHeader>
                <TableHeader property="name">Nombre</TableHeader>
                <TableHeader property="display_name">Nombre a mostrar</TableHeader>
                <TableHeader property="description">Descripción</TableHeader>
                <TableCell>Permisos</TableCell>
                {
                  validatePermissions(auth.permissions, { edit: ROLES_PERMISSIONS.EDIT_ROLES, delete: ROLES_PERMISSIONS.DELETE_ROLES }) &&
                  <TableCell>Acciones</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((rol) => (
                  <TableRow
                    hover
                    key={rol.id}
                  >
                    <TableCell>
                      {rol.id}
                    </TableCell>
                    <TableCell>
                      {rol.name}
                    </TableCell>
                    <TableCell>
                      {rol.display_name}
                    </TableCell>
                    <TableCell>
                      {rol.description}
                    </TableCell>
                    <TableCell>
                      {
                        rol.permissions.map(p => {
                          return <Chip className="m-1" key={p.id} label={p.display_name} />
                        })
                      }
                    </TableCell>
                    {
                      validatePermissions(auth.permissions, { edit: ROLES_PERMISSIONS.EDIT_ROLES, delete: ROLES_PERMISSIONS.DELETE_ROLES }) &&
                      <TableCell>
                        <Actions rol={rol} deleteRol={rest.deleteRol} authPermissions={auth.permissions} />
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {
        data.length === 0 &&
        <div className="text-center mt-2" >
          No hay resultados para su búsqueda
        </div>
      }
      <TablePagination
        component="div"
        className="paginador"
        count={data.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </Card>
  );
};

export default TableRoles;
