import api from '../api';

const PaginasInstitucionalesService = {
  getPaginas: (page, limit, filters) => {
    return api.get(`institutional?page=${page}&limit=${limit}&${filters !== '' ? filters.substring(1) : filters}`)
  },
  addPagina:(data)=>{
    return api.post(`institutional`, data)
  },
  getOnePagina:(id)=>{
    return api.get(`institutional/${id}`)
  },
  updatePagina:(data, id)=>{
    return api.put(`institutional/${id}`, data)
  },
  deletePagina:(id)=>{
    return api.delete(`institutional/${id}`)
  },
  getStates:()=>{
    return api.get(`institutional/states`)
  },
  generatePreviewInstitucional: (id) => {
    return api.post(`institutional/json/${id}`, {});
  },
  generateTemporalPreviewInstitucional: (data) => {
    return api.post('institutional/json/tmp', data);
  },
  temporalPreview: (data) => (
    api.post('institutional/json/tmp', data)
  ),
  getListActive:()=>{
    return api.get(`institutional/list`)
  }
}

export { PaginasInstitucionalesService as default };
