import { AGRUPADORES_TYPES } from './types'
import AgrupadoresService from './../services/AgrupadoresService';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { routesApp } from 'src/routesService';
import { NotificationManager } from 'react-notifications';

export const getAllAgrupadores = (page = 1, rows = 10, filters = '') => async dispatch => {
  dispatch({
    type: AGRUPADORES_TYPES.GET_AGRUPADORES
  });
  try {
    const result = await AgrupadoresService.getAgrupadores(page, rows, filters);
    dispatch({
      type: AGRUPADORES_TYPES.GET_AGRUPADORES_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: AGRUPADORES_TYPES.GET_AGRUPADORES_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener los agrupadores: ', error);
  }
}

export const getOneAgrupador = (id) => async (dispatch) => {
  dispatch({
    type: AGRUPADORES_TYPES.GET_ONE_AGRUPADOR
  });
  try {
    const result = await AgrupadoresService.getOneAgrupador(id);
    dispatch({
      type: AGRUPADORES_TYPES.GET_ONE_AGRUPADOR_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: AGRUPADORES_TYPES.GET_ONE_AGRUPADOR_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener el agrupador: ', error);
  }
};

export const storeAgrupador = (id, data, history) => async dispatch => {
  dispatch({
    type: AGRUPADORES_TYPES.STORE_AGRUPADOR
  })
  try {
    if (id) {
      await AgrupadoresService.updateAgrupador(data, id);
      NotificationManager.success("Agrupador actualizado exitosamente")
    } else {
      await AgrupadoresService.addAgrupador(data);
      NotificationManager.success("Agrupador creado exitosamente")
    }

    dispatch({
      type: AGRUPADORES_TYPES.STORE_AGRUPADOR_SUCCESS
    })

    //redirigir
    history.push(routesApp.agrupadores.path)
  } catch (error) {
    dispatch({
      type: AGRUPADORES_TYPES.STORE_AGRUPADOR_FAILURE,
      payload: error.response ? error.response.data.errors : null
    })
    NotificationManager.error("Error al guardar el agrupador.")

    showDeveloperMessageConsole("Error al guardar el agrupador: ", error)
  }
}

export const getAllFAQs = (idAgrupador, page = 1, rows = 10, filters = '') => async dispatch => {
  dispatch({
    type: AGRUPADORES_TYPES.GET_FAQS
  });
  try {
    const result = await AgrupadoresService.getFAQs(idAgrupador, page, rows, filters);
    dispatch({
      type: AGRUPADORES_TYPES.GET_FAQS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: AGRUPADORES_TYPES.GET_FAQS_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener los FAQs: ', error);
  }
};

export const getOneFAQ = (id, agrupadorId) => async (dispatch) => {
  dispatch({
    type: AGRUPADORES_TYPES.GET_ONE_FAQ
  });
  try {
    const result = await AgrupadoresService.getOneFAQ(id, agrupadorId);
    dispatch({
      type: AGRUPADORES_TYPES.GET_ONE_FAQ_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: AGRUPADORES_TYPES.GET_ONE_FAQ_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener el FAQ: ', error);
  }
};

export const storeFAQ = (id, agrupadorId, data, history) => async dispatch => {
  dispatch({
    type: AGRUPADORES_TYPES.STORE_FAQ
  })
  try {
    if (id) {
      await AgrupadoresService.updateFAQ(data, id, agrupadorId);
      NotificationManager.success("FAQ creado exitosamente")
    } else {
      await AgrupadoresService.addFAQ(data, agrupadorId);
      NotificationManager.success("FAQ creado exitosamente")
    }

    dispatch({
      type: AGRUPADORES_TYPES.STORE_FAQ_SUCCESS
    })

    //redirigir
    history.push(routesApp.faqs.path.replace(":agrupadorId", agrupadorId))
  } catch (error) {
    dispatch({
      type: AGRUPADORES_TYPES.STORE_FAQ_FAILURE,
      payload: error.response ? error.response.data.errors : null
    })
    NotificationManager.error("Error al guardar el FAQ.")

    showDeveloperMessageConsole("Error al guardar el FAQ: ", error)
  }
}
