import Axios from 'axios';
import api from '../api';

const MenuUsrService = {
  getMenusUsr: (page = 1, limit = 10, filters = '') => {
    //return all_mock;
    return api.get(`user/menu?page=${page}&limit=${limit}&${filters !== '' ? filters.substring(1) : filters}`);
  },
  getMenuUsrText: () => {
    return api.get(`user/text`)
  },
  storeTextMenuUsr:(data) => {
    return api.post(`user/text`, data)
  },
  addMenuUsr: (data) => {
    return api.post('user/menu', data);
  },
  getOneMenuUsr: (id) => {
    //return one_mock;
    return api.get(`user/menu/${id}`);
  },
  updateMenuUsr: (data, id) => {
    return api.post(`user/menu/${id}?_method=PUT`, data);
  },
  deleteMenuUsr: (id) => {
    return api.delete(`user/menu/${id}`);
  },
  getMenuUsrTextPrev: () => {
    //return one_mock;
    return Axios.get(`${process.env.REACT_APP_BASE_URL_STORAGE}user_panel/texto.json?${Date.now()}`);
  },
  getMenuUsrMenuPrev: () => {
    //return one_mock;
    return Axios.get(`${process.env.REACT_APP_BASE_URL_STORAGE}user_panel/menu.json?${Date.now()}`);
  },
};

export { MenuUsrService as default };
