import React from 'react'
import { Card, CardContent, FormHelperText } from '@material-ui/core'

const ErrorsValidation = ({ errors }) => {
  if (!errors) return <></>;

  return (
    <Card className="mb-3">
      <CardContent className="pl-3">
        <p>Verifique los siguientes errores antes de continuar:</p>
        {
          Object.keys(errors).map(element =>
            errors[element].map((e, index) => {
              return (
                <FormHelperText key={index} className="text-danger">- {e}</FormHelperText>
              )
            })
          )
        }
      </CardContent>
    </Card>
  )
}

export default ErrorsValidation
