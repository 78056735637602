/* eslint-disable indent */
/* eslint-disable brace-style */
/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

export const PrimaryLinkButton = ({ to, children, external, className, size }) =>
{
    if (external)
    {
        return (
<a href={to} target="_blank" rel="noopener noreferrer">
    <Button className={className} size={size} color="primary" variant="contained">{children}</Button>
</a>
        )
    }

    return (
<Link to={to}>
    <Button className={className} size={size} color="primary" variant="contained">{children}</Button>
</Link>
    )
}
