import React from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'

import { routesApp } from 'src/routesService'
import { checkPermissions } from 'src/helpers/permissions'
import { PORTADAS_PERMISSIONS } from 'src/utils/constants/permissions'
import PageContainer from 'src/containers/shared/PageContainer'

import BarraBusquedaPortada from './shared/BarraBusquedaPortada'
import TablePortadas from './shared/TablePortadas'
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';

const PortadasList = ({
  portadas, pagination, loading, ...rest
}) => {
  const { permissions } = useSelector((state) => state.auth.data)

  const hasPermission = {
    view: checkPermissions(permissions, [PORTADAS_PERMISSIONS.VIEW_PORTADA]),
    add: checkPermissions(permissions, [PORTADAS_PERMISSIONS.CREATE_PORTADA]),
    edit: checkPermissions(permissions, [PORTADAS_PERMISSIONS.EDIT_PORTADA]),
    delete: checkPermissions(permissions, [PORTADAS_PERMISSIONS.DELETE_PORTADA]),
  }

  return (
    <PageContainer title="Portadas" maxWidth={false}>
      {
        hasPermission.add
        && (
        <Box
          display="flex"
          justifyContent="flex-end"
        >
          <PrimaryLinkButton to={`${routesApp.addPortada.path}`}>
            + Añadir Portada
          </PrimaryLinkButton>
        </Box>
        )
      }
      <BarraBusquedaPortada />
      <Box mt={3}>
        <TablePortadas
          data={portadas}
          currentPage={pagination.current_page}
          itemsCountPerPage={pagination.per_page}
          totalItemsCount={pagination.total}
          nextPage={pagination.next_page_url}
          prevPage={pagination.prev_page_url}
          lastPage={pagination.last_page}
          loading={loading}
          hasPermission={hasPermission}
          getPortadasPage={rest.getAllPortadas}
          deletePortada={rest.deletePortada}
          publicarPortada={rest.publicarPortada}
        />
      </Box>

    </PageContainer>
  )
}

export default PortadasList
