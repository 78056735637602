import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  createTheme,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { routesApp } from 'src/routesService';
import {
  getDataFormatedForSelects,
  selectFileWithCKFinder,
  dataURItoBlob,
  URLRegex
} from 'src/helpers/random';

import { SketchPicker } from 'react-color';
import CKEditorCopete from 'src/components/shared/CKEditor/CKEditorCopete';
import SelectField from 'src/components/shared/SelectField';
import EditorImage from 'src/components/shared/EditorImage';

const muiTheme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) => ({
  color: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const linkTypes = {
  URL: 'url',
  INSTITUTIONAL: 'institutional',
  FRONT_PAGE: 'front_page'
};

const backType = {
  image: '1',
  color: '2'
}

const PanelForm = ({
  panelUser,
  loading,
  institucionales,
  portadas,
  submitAction
}) => {
  const classes = useStyles();

  const editorRefIcon = React.createRef();
  const editorRefImageBackground = React.createRef()

  const [linkType, setLinkType] = useState(panelUser.link_type);
  const [touchedIcon, setTouchedIcon] = useState(false);
  const [background, setBackground] = useState(panelUser.panel_card && panelUser.panel_card.background ? (backType[panelUser.panel_card.background.type]) : '1');
  const [backgroundFileTouched, setBackgroundFileTouched] = React.useState(false);

  function formatSelect(menu, pages) {
    if (pages) {
      const pageId = menu.institutional_page ? menu.institutional_page.id : menu.front_page.id;
      const page = pages.find((p) => p.id === pageId);
      return { value: page.id, label: page.link };
    }
    return { value: 0, label: 'NULL' };
  }

  function handleChangeType(values, type) {
    values.link_type = type;
    values.link = '';
    setLinkType(type);
  }

  function getInstitucionales() {
    if (institucionales) {
      return getDataFormatedForSelects(institucionales, 'id', 'link');
    }
    return {
      label: '',
      value: []
    }
  }

  function getPortadas() {
    if (portadas) {
      return getDataFormatedForSelects(portadas, 'id', 'link');
    }
    return {
      label: '',
      value: []
    }
  }

  function handleSubmit(values) {
    const formData = new FormData();

    formData.append('name', values.name);
    formData.append('order', values.order);
    formData.append('active', values.active ? 1 : 0);
    formData.append('copete', values.copete);
    formData.append('copeteLength', values.copeteLength);
    formData.append('link_type', values.link_type);
    formData.append('target', values.target ? 'blank' : 'self');

    switch (linkType) {
      case linkTypes.URL:
        formData.append('link', values.link);
        break;
      case linkTypes.INSTITUTIONAL:
        formData.append('institutional_page_id', values.institutional ? values.institutional.value : null);
        break;
      case linkTypes.FRONT_PAGE:
        formData.append('front_page_id', values.portada ? values.portada.value : null);
        break;
      default:
        console.log('Error link type');
    }

    if (typeof values.icon !== 'string' && !(values.icon instanceof String)) {
      if (values.icon.type === 'image/svg+xml' && !touchedIcon) {
        formData.append('icon', values.icon);
      } else {
        const canvas = editorRefIcon.current.getImageScaledToCanvas();
        const blob = dataURItoBlob(canvas.toDataURL());
        formData.append('icon', blob);
      }
    }

    formData.append('background[type]', background === '1' ? 'image' : 'color');
    if (background === '1') {
      if (process.env.REACT_APP_CKFINDER_ACTIVE === 'false') {
        if (typeof values.image_background === 'string' || values.image_background instanceof String) {
          const bkgImageRoute = values.image_background.split('/');
          bkgImageRoute.splice(0, 3);
          formData.append('background[filename]', bkgImageRoute.join('/'));
        } else {
          const imageToAppend = values.image_background;
          formData.append('background[file]', imageToAppend);
        }

        const backgroundCanvas = editorRefImageBackground.current;
        formData.append('background[styles]', JSON.stringify(
          {
            borderRadius: backgroundCanvas?.props?.borderRadius ?? 0,
            height: backgroundCanvas?.props?.height ?? 400,
            width: backgroundCanvas?.props?.width ?? 400,
            rotate: backgroundCanvas?.props?.rotate ?? 0,
            scale: backgroundCanvas?.props?.scale ?? 0,
            positionX: backgroundCanvas?.props?.position.x ?? 0,
            positionY: backgroundCanvas?.props?.position.y ?? 0,
          }
        ));
      } else {
        const aux = values.image_background.split('/');
        aux.splice(0, 3);
        formData.append('background[filename]', aux.join('/'));
      }
    } else {
      formData.append('background[color_hex]', values.color_background);
    }

    submitAction(formData, panelUser.id);
  }

  return (
    <Formik
      initialValues={{
        name: panelUser ? panelUser.name : '',
        nameLength: panelUser.panelUser?.name?.length ?? 0,
        order: panelUser.order ?? '',
        copete: panelUser.panel_card ? panelUser.panel_card.copete : '',
        copeteLength: panelUser?.panel_card?.copete?.length ?? 0,
        icon: panelUser ? `${process.env.REACT_APP_BASE_URL_STORAGE}${panelUser.icon}` : '',
        link: panelUser.link ?? '',
        institutional: panelUser.institutional_page ? formatSelect(panelUser, institucionales) : '',
        portada: panelUser.front_page ? formatSelect(panelUser, portadas) : '',
        active: Boolean(panelUser.active),
        link_type: panelUser.link_type ?? 'url',
        color_background: panelUser.panel_card && panelUser.panel_card.background && panelUser.panel_card.background.color_hex ? panelUser.panel_card.background.color_hex : '',
        image_background: panelUser.panel_card && panelUser.panel_card.background && panelUser.panel_card.background.color_hex === null ? `${process.env.REACT_APP_BASE_URL_API}/${panelUser.panel_card.background.image_file.filename}` : '',
        target: panelUser.target === 'blank'
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('El nombre es requerido'),
        nameLength: Yup.number().max(25, 'El largo del copete debe ser menor a 100 caracteres'),
        order: Yup.number().required('El orden es requerido'),
        copete: Yup.string().required('El copete es requerido'),
        copeteLength: Yup.number().max(100, 'El largo del copete debe ser menor a 100 caracteres'),
        link: Yup.string().nullable().test('valid-url', 'La URL ingresada es inválida',
          (str) => (str ? !!URLRegex.test(str) : true)),
        image_background: background === '1' && Yup.string().required('La imagen del background es requerida'),
        color_background: background === '2' && Yup.string().required('El color del background es requerido'),
        target: Yup.string().required('Abrir nueva pestaña es requerido.')
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <Form style={{ width: '100%' }}>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">General</Typography>
              <FormControl className="ckeditor-height" margin="normal" fullWidth error={Boolean(touched.copete && errors.copete)}>
                <Typography color="textPrimary" variant="overline">Título*</Typography>
                <CKEditorCopete
                  id="ck-copete-title"
                  value={values.name}
                  name="name"
                  nameLength="nameLength"
                  styles={[
                    { name: 'height', value: '150px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.name && errors.name)}</FormHelperText>
                <FormHelperText className="text-danger">{(touched.nameLength && errors.nameLength)}</FormHelperText>
              </FormControl>
              <TextField
                error={Boolean(touched.order && errors.order)}
                fullWidth
                helperText={touched.order && errors.order}
                label="Orden"
                margin="normal"
                name="order"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.order}
                variant="outlined"
              />
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Estado</Typography>
              <FormControl fullWidth>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={values.active}
                      onChange={handleChange}
                      name="active"
                      color="primary"
                    />
                  )}
                  label="Activo"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Enlace</Typography>

              <FormControl fullWidth margin="normal" style={{ marginBottom: 6 }}>
                <FormLabel component="legend">Tipo Enlace</FormLabel>
                <RadioGroup row aria-label="gender" name="tipoEnlace" value={linkType} onChange={handleChange}>
                  <FormControlLabel value={linkTypes.URL} onChange={() => handleChangeType(values, linkTypes.URL)} control={<Radio />} label="URL" />
                  <FormControlLabel value={linkTypes.INSTITUTIONAL} onChange={() => handleChangeType(values, linkTypes.INSTITUTIONAL)} control={<Radio />} label="Páginas Institucionales" />
                  <FormControlLabel value={linkTypes.FRONT_PAGE} onChange={() => handleChangeType(values, linkTypes.FRONT_PAGE)} control={<Radio />} label="Portadas" />
                </RadioGroup>
              </FormControl>
              {
                linkType === linkTypes.URL && (
                  <TextField
                    error={Boolean(touched.link && errors.link)}
                    fullWidth
                    helperText={touched.link && errors.link}
                    label="Enlace*"
                    name="link"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.link}
                    variant="outlined"
                  />
                )
              }
              {
                linkType === linkTypes.INSTITUTIONAL && (
                  <FormControl
                    fullWidth
                    error={Boolean(touched.institutional && errors.institutional)}
                  >
                    <SelectField
                      placeholder="Seleccione enlace..."
                      name="institutional"
                      getOptions={getInstitucionales}
                      label="Enlace*"
                      setFieldValue={(value) => {
                        setFieldValue('institutional', value);
                      }}
                      value={values.institutional}
                    />
                    <FormHelperText className="text-danger">{(touched.institutional && errors.institutional) && errors.institutional}</FormHelperText>
                  </FormControl>
                )
              }
              {
                linkType === linkTypes.FRONT_PAGE && (
                  <FormControl
                    fullWidth
                    error={Boolean(touched.portada && errors.portada)}
                  >
                    <SelectField
                      placeholder="Seleccione enlace..."
                      name="portada"
                      getOptions={getPortadas}
                      label="Enlace*"
                      setFieldValue={(value) => {
                        setFieldValue('portada', value);
                      }}
                      value={values.portada}
                    />
                    <FormHelperText className="text-danger">{(touched.portada && errors.portada) && errors.portada}</FormHelperText>
                  </FormControl>
                )
              }
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Nueva pestaña</Typography>
              <FormControl fullWidth>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={values.target}
                      onChange={handleChange}
                      name="target"
                      color="primary"
                    />
                  )}
                  label="Nueva pestaña"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <FormControl className="ckeditor-height" margin="normal" fullWidth error={Boolean(touched.copete && errors.copete)}>
                <Typography color="textPrimary" variant="overline">Copete</Typography>
                <CKEditorCopete
                  id="ck-copete-panel"
                  value={values.copete}
                  name="copete"
                  nameLength="copeteLength"
                  styles={[
                    { name: 'height', value: '150px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.copete && errors.copete)}</FormHelperText>
                <FormHelperText className="text-danger">{(touched.copeteLength && errors.copeteLength)}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          {
            process.env.REACT_APP_CKFINDER_ACTIVE === 'false'
              ? (
                <EditorImage
                  label="Icono"
                  editorRef={editorRefIcon}
                  borderRadiusProp={0}
                  widthProp={150}
                  heightProp={150}
                  positionXProp={0.5}
                  positionYProp={0.5}
                  rotateProp={0}
                  scaleProp={1}
                  handleBlur={handleBlur}
                  handleChange={(value) => setFieldValue('icon', value)}
                  setFileTouched={setTouchedIcon}
                  touched={touchedIcon}
                  errors={errors.icon}
                  value={values.icon}
                />
              )
              : (
                <Grid container>
                  <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
                    <TextField
                      error={Boolean(errors.image && errors.image)}
                      fullWidth
                      helperText={touched.image && errors.image}
                      label="Imagen Destacado"
                      margin="normal"
                      name="image"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.image}
                      variant="outlined"
                      // disabled
                      InputProps={{ endAdornment: <IconButton onClick={() => selectFileWithCKFinder(setFieldValue, 'image')}><CloudUploadIcon /></IconButton> }}
                    />
                  </Grid>
                </Grid>
              )
          }
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <FormControl fullWidth margin="normal" style={{ marginBottom: 6 }}>
                <Typography color="textPrimary" variant="overline" className="font-weight-bold">Background</Typography>

                <FormLabel component="legend">Tipo de background</FormLabel>
                <RadioGroup row aria-label="gender" name="tipoEnlace" value={background} onChange={handleChange}>
                  <FormControlLabel value="1" onChange={() => setBackground('1')} control={<Radio />} label="Imagen" />
                  <FormControlLabel value="2" onChange={() => setBackground('2')} control={<Radio />} label="Color" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {
            background === '1' && (
              process.env.REACT_APP_CKFINDER_ACTIVE === 'false'
                ? (
                  <EditorImage
                    label="Imagen del background"
                    editorRef={editorRefImageBackground}
                    borderRadiusProp={
                      panelUser.panel_card
                      && panelUser.panel_card.background.image_file
                      && JSON.parse(panelUser.panel_card.background.image_file.styles).borderRadius
                        ? JSON.parse(panelUser.panel_card.background.image_file.styles).borderRadius
                        : 0
                    }
                    widthProp={
                      panelUser.panel_card
                      && panelUser.panel_card.background.image_file
                      && JSON.parse(panelUser.panel_card.background.image_file.styles).width
                        ? JSON.parse(panelUser.panel_card.background.image_file.styles).width
                        : 250
                    }
                    heightProp={
                      panelUser.panel_card
                      && panelUser.panel_card.background.image_file
                      && JSON.parse(panelUser.panel_card.background.image_file.styles).height
                        ? JSON.parse(panelUser.panel_card.background.image_file.styles).height
                        : 250
                    }
                    positionXProp={
                      panelUser.panel_card
                      && panelUser.panel_card.background.image_file
                      && JSON.parse(panelUser.panel_card.background.image_file.styles).positionX
                        ? JSON.parse(panelUser.panel_card.background.image_file.styles).positionX
                        : 0.5
                    }
                    positionYProp={
                      panelUser.panel_card
                      && panelUser.panel_card.background.image_file
                      && JSON.parse(panelUser.panel_card.background.image_file.styles).positionY
                        ? JSON.parse(panelUser.panel_card.background.image_file.styles).positionY
                        : 0.5
                    }
                    rotateProp={
                      panelUser.panel_card
                      && panelUser.panel_card.background.image_file
                      && JSON.parse(panelUser.panel_card.background.image_file.styles).rotate
                        ? JSON.parse(panelUser.panel_card.background.image_file.styles).rotate
                        : 0
                    }
                    scaleProp={
                      panelUser.panel_card
                      && panelUser.panel_card.background.image_file
                      && JSON.parse(panelUser.panel_card.background.image_file.styles).scale
                        ? JSON.parse(panelUser.panel_card.background.image_file.styles).scale
                        : 1
                    }
                    handleBlur={handleBlur}
                    handleChange={(value) => setFieldValue('image_background', value)}
                    setFileTouched={setBackgroundFileTouched}
                    touched={backgroundFileTouched}
                    errors={errors.image_background}
                    value={values.image_background}
                  />
                ) : (
                  <Grid container>
                    <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
                      <TextField
                        error={Boolean(errors.image_background && errors.image_background)}
                        fullWidth
                        helperText={touched.image_background && errors.image_background}
                        label="Imagen Background"
                        margin="normal"
                        name="image_background"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.image_background}
                        variant="outlined"
                        // disabled
                        InputProps={{ endAdornment: <IconButton onClick={() => selectFileWithCKFinder(setFieldValue, 'image_background')}><CloudUploadIcon /></IconButton> }}
                      />
                    </Grid>
                  </Grid>
                )
            )
          }
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              {
                background === '2' && (
                  <FormControl fullWidth error={touched.institutional && errors.institutional}>
                    <SketchPicker
                      width="100%"
                      color={values.color_background}
                      onChangeComplete={(color) => setFieldValue('color_background', color.hex)}
                    />
                    <FormHelperText className="text-danger">{(touched.institutional && errors.institutional) && errors.institutional}</FormHelperText>
                  </FormControl>
                )
              }
            </Grid>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={muiTheme}>
                <Link to={routesApp.panelUser.path}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading || isSubmitting}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default PanelForm;
