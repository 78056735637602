import React from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  makeStyles,
  Grid
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  useHistory,
  useLocation
} from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const BarraBusquedaAgrupadores = ({ className, ...props }) => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();

  return (
    <Box mt={3}>
      <Card color="#eeeeee">
        <CardContent>
          <Formik
            initialValues={{
              title: query.get('title_filter') ? query.get('title_filter') : '',
              order: query.get('order_filter') ? query.get('order_filter') : '',
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().max(255, 'El título no debe superar los 255 caracteres'),
              order: Yup.number(),
            })}
            onSubmit={async (data) => {
              query.set('title_filter', data.title);
              query.set('order_filter', data.order);
              history.push(`?${query.toString()}`);
            }}
            onReset={(data, actions) => {
              actions.setValues({
                title: '',
                order:'',
              });
              query.set('title_filter', '');
              query.set('order_filter', '');
              history.push(`?${query.toString()}`);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleReset,
              isSubmitting,
              setFieldValue,
              touched,
              values
            }) => (
              <Form className={classes.root} noValidate autoComplete="off">
                <Grid container>
                  <Grid xs={12} md={4} className="p-2" item> {/* 12 4 */}
                    <TextField
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      fullWidth
                      label="Título"
                      name="title"
                      variant="outlined"
                      type="text"
                    />
                  </Grid>
                  <Grid xs={12} md={4} className="p-2" item>
                    <TextField
                      error={Boolean(touched.order && errors.order)}
                      helperText={touched.order && errors.order}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.order}
                      fullWidth
                      label="Orden"
                      name="order"
                      variant="outlined"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} className="pt-2 pl-2 text-right">
                    <Button
                      className={classes.importButton}
                      onClick={handleReset}
                      type="button"
                    >
                      Limpiar
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BarraBusquedaAgrupadores;
