// Actions Forms
export const FORM_ACTIONS = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE'
}

export const FORM_NAMES = {
  [FORM_ACTIONS.ADD]: 'Agregar',
  [FORM_ACTIONS.EDIT]: 'Editar',
  [FORM_ACTIONS.SHOW]: 'Mostrar',
}
