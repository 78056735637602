import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import {
  Button,
  createTheme,
  makeStyles,
  ThemeProvider
} from '@material-ui/core';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) => ({
  color: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const BackButton = (props) => {
  const { to } = props;
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Link
        to={to}
      >
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<BackIcon />}
          className={classes.color}
        >
          Atrás
        </Button>
      </Link>
    </ThemeProvider>
  );
};

export default BackButton;
