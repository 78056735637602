import React from 'react';
import { Box, Container } from '@material-ui/core';
import { routesApp } from 'src/routesService';

import Page from '../Page';
import TableHeaders from './shared/tableHeaders';
import BarraBusqueda from './shared/barraBusquedaHeaders';
import { validateOnePermission } from 'src/helpers/permissions';
import { MENU_PERMISSIONS } from 'src/utils/constants/permissions';
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';
import PageContainer from 'src/containers/shared/PageContainer';

const Headers = (props) => {
  const {
    headers,
    loading,
    auth,
    getAllHeaders,
    deleteHeader,
  } = props;

  return (
    <PageContainer title="Encabezados" maxWidth={false}>
        {
          validateOnePermission(auth.permissions, MENU_PERMISSIONS.CREATE_MENU) &&
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <PrimaryLinkButton to={`${process.env.REACT_APP_JUANAMANSO_REA}preview/header`} external className="mr-1">
              Previsualizar Encabezado
            </PrimaryLinkButton>
            <PrimaryLinkButton to={routesApp.addHeader.path}>
              + Añadir Encabezado
            </PrimaryLinkButton>
          </Box>
        }
        <BarraBusqueda />
        <Box mt={3}>
          <TableHeaders
            auth={auth}
            data={headers.data}
            currentPage={headers.current_page}
            itemsCountPerPage={headers.per_page}
            totalItemsCount={headers.total}
            nextPage={headers.next_page_url}
            prevPage={headers.prev_page_url}
            lastPage={headers.last_page}
            loading={loading}
            getAllHeaders={getAllHeaders}
            deleteHeader={deleteHeader}
          />
        </Box>
    </PageContainer>
  );
};

export default Headers;
