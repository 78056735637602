import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import FooterReducer from './FooterReducer';
import HeaderReducer from './HeaderReducer';
import PaginasInstitucionalesReducer from './PaginasInstitucionalesReducer';
import PermissionsReducer from './PermissionsReducer';
import RoleReducer from './RoleReducer';
import UserReducer from './UserReducer';
import ContactFormReducer from './ContactFormReducer';
import PortadasReducer from './PortadasReducer';
import AgrupadoresReducer from './AgrupadoresReducer';
import VisitorRegisterReducer from './VisitorRegisterReducer';
import MenuUsrReducer from './MenuUsrReducer';
import PanelUserReducer from './PanelUserReducer';

const rootReducer = combineReducers({
  auth: AuthReducer,
  users: UserReducer,
  permissions: PermissionsReducer,
  roles: RoleReducer,
  paginasInstitucionales: PaginasInstitucionalesReducer,
  headers: HeaderReducer,
  footers: FooterReducer,
  contact: ContactFormReducer,
  portadas: PortadasReducer,
  agrupadores: AgrupadoresReducer,
  visitor: VisitorRegisterReducer,
  menuUsr: MenuUsrReducer,
  panelUser: PanelUserReducer,
});

export default rootReducer;

export const selectUser = (state) => state.auth.data
