import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
// import TablePagination from '@material-ui/core/TablePagination';
import { convertDate } from 'src/helpers/random';
import TableHeader from 'src/components/shared/TableHeader';
import Actions from './actions';
import { FrontPageComponentStatus } from './chips/FrontPageComponentStatus';

function removeHTML(str) {
  const tmp = document.createElement('div');
  tmp.innerHTML = str;
  return tmp.textContent.replace(/^(.{20}[^\s]*).*/, '$1') || tmp.innerText.replace(/^(.{20}[^\s]*).*/, '$1') || ''; // Lógica para eliminar etiquetas html y truncar texto largo
}

const TableComponentes = ({
  className,
  abilities,
  data,
  currentPage,
  itemsCountPerPage,
  totalItemsCount,
  prevPage,
  nextPage,
  lastPage,
  loading,
  ...rest
}) => {
  // const handlePageChange = (event, page) => {
  //   rest.getAllComponentes(page + 1, parseInt(itemsCountPerPage));
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   rest.getAllComponentes(1, event.target.value);
  // };

  const showActionMenu = abilities.edit || abilities.delete

  return (
    <Card
      className={className}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Typography variant="subtitle1" className="mt-3 ml-3 mb-2">
            Listado de componentes de la portada
            {' '}
            {`"${rest.portadaTitle}"`}
            {' '}
            { loading && <CircularProgress size={24} style={{ marginLeft: 15 }} /> }
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader property="row">Fila</TableHeader>
                <TableHeader property="col">Col</TableHeader>
                <TableHeader property="size">Ocupa</TableHeader>
                <TableHeader property="type.display_name">Tipo Componente</TableHeader>
                <TableHeader property="name">Título</TableHeader>
                <TableHeader property="created_at">Fecha creación</TableHeader>
                <TableHeader property="updatedBy.username">Usuario última modificación</TableHeader>
                <TableHeader property="updated_at">Fecha última modificación</TableHeader>
                <TableHeader property="state.display_name">Estado</TableHeader>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((componente) => (
                <TableRow
                  hover
                  key={componente.id}
                >
                  <TableCell>
                    {componente.row ? componente.row : '-'}
                  </TableCell>
                  <TableCell>
                    {componente.col ? componente.col : '-'}
                  </TableCell>
                  <TableCell>
                    {componente.size ? componente.size : '-'}
                  </TableCell>
                  <TableCell>
                    {componente.type.display_name}
                  </TableCell>
                  <TableCell>
                    {removeHTML(componente.title)}
                  </TableCell>
                  <TableCell>
                    {convertDate(componente.created_at, 'YYYY-MM-DD', 'DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {componente.updated_by.username}
                  </TableCell>
                  <TableCell>
                    {convertDate(componente.updated_at, 'YYYY-MM-DD', 'DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <FrontPageComponentStatus state={componente.state} />
                  </TableCell>
                  <TableCell>
                    {showActionMenu && (
                      <Actions
                        componente={componente}
                        portadaId={rest.portadaId}
                        deleteComponente={rest.deleteComponente}
                        abilities={abilities}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {data.length === 0 && (
        <div className="text-center mt-2 mb-2">No hay resultados para su búsqueda</div>
      )}
      {/* <TablePagination
        component="div"
        className="paginador"
        count={totalItemsCount}
        // count={data.length}//hasta que venga la api
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={currentPage - 1}
        rowsPerPage={parseInt(itemsCountPerPage)}
        rowsPerPageOptions={[5, 10, 20]}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      /> */}
    </Card>
  );
};

export default TableComponentes;
