import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import {
    getAllPaginasInstitucionales, storePaginaInstitucional, getOnePaginaInstitucional, getStates, generateTemporalPreview
} from 'src/actions/paginasInstitucionalesActions'
import Loading from 'src/components/shared/loading'
import { routesApp } from 'src/routesService'
import PaginasInstitucionales from 'src/components/paginas-institucionales/PaginasInstitucionales'
import AddPagina from 'src/components/paginas-institucionales/AddPagina'
import EditPagina from 'src/components/paginas-institucionales/EditPagina'
import PrevisualizarPagina from 'src/components/paginas-institucionales/PrevisualizarPagina'
import PaginasInstitucionalesService from 'src/services/PaginasInstitucionalesService'
import { NotificationManager } from 'react-notifications'
import { showDeveloperMessageConsole } from 'src/helpers/random'

const PaginasInstitucionalesContainer = (props) => {
  const {
    paginasInstitucionales, auth, history, match, location
  } = props

  const handleGetAllPaginas = (page, rows) => {
    props.getAllPaginasInstitucionales(page, rows, location.search)
  };

  const handleAddPagina = (data) => {
    props.storePaginaInstitucional(null, data, history)
  };

  const handleUpdatePagina = (data, id) => {
    props.storePaginaInstitucional(id, data, history)
  };

  const handleDeletePagina = async (id) => {
    NotificationManager.info('Eliminando página...', null, 1500)
    try {
      await PaginasInstitucionalesService.deletePagina(id);
      await props.getAllPaginasInstitucionales();
      NotificationManager.success('Página eliminada correctamente')
    } catch (error) {
      showDeveloperMessageConsole('Error al eliminar pagina: ', error)
      NotificationManager.error('Ha ocurrido un error. Intente nuevamente.')
    }
  }
  /*
  function handlePreview() {
    props.generateTemporalPreview();
  }
 */
  useEffect(() => {
    props.getStates()
    if (match.path === routesApp.paginasInstitucionales.path) {
      props.getAllPaginasInstitucionales(1, 10, location.search)
    } else if (match.path === routesApp.editPaginasInstitucionales.path) {
      props.getOnePaginaInstitucional(match.params.paginaId)
    } else if (match.path === routesApp.prevPaginasInstitucionales.path && match.params.paginaId) {
      props.getOnePaginaInstitucional(match.params.paginaId)
    }

    // eslint-disable-next-line
  }, [match.path, location.search])

  if (match.path === routesApp.prevPaginasInstitucionales.path) {
    if (!match.params.paginaId) {
      return <PrevisualizarPagina />
    } if (paginasInstitucionales.one !== null && paginasInstitucionales.one.id.toString() === match.params.paginaId) {
      return <PrevisualizarPagina pagina={paginasInstitucionales.one} />
    }
    return <Loading />
  }

  if ((match.path === routesApp.paginasInstitucionales.path && paginasInstitucionales.all === null) || paginasInstitucionales.states === null) {
    return <Loading />
  }

  if (match.path === routesApp.addPaginasInstitucionales.path) {
    return (
      <AddPagina
        states={paginasInstitucionales.states}
        errors={paginasInstitucionales.errors}
        loading={paginasInstitucionales.loading}
        auth={auth}
        addPagina={handleAddPagina}
        generateTemporalPreview={props.generateTemporalPreview}
      />
    );
  }

  if (match.path === routesApp.editPaginasInstitucionales.path) {
    if (paginasInstitucionales.one !== null && paginasInstitucionales.one.id.toString() === match.params.paginaId) {
      return (
        <EditPagina
          pagina={paginasInstitucionales.one}
          states={paginasInstitucionales.states}
          errors={paginasInstitucionales.errors}
          loading={paginasInstitucionales.loading}
          auth={auth}
          updatePagina={handleUpdatePagina}
          generateTemporalPreview={props.generateTemporalPreview}
        />
      );
    }
    return <Loading />
  }

  return (
    <PaginasInstitucionales
      auth={auth.data}
      paginas={paginasInstitucionales.all}
      states={paginasInstitucionales.states}
      loading={paginasInstitucionales.loading}
      getAllPaginas={handleGetAllPaginas}
      deletePagina={handleDeletePagina}
    />
  );
};

const mapStateToProps = ({ auth, paginasInstitucionales }) => {
  return {
    auth,
    paginasInstitucionales
  };
};

const mapDispatchToProps = {
  getAllPaginasInstitucionales,
  getOnePaginaInstitucional,
  storePaginaInstitucional,
  getStates,
  generateTemporalPreview
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginasInstitucionalesContainer);
