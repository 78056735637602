import React from 'react'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

const DatePickerField = ({
  onChange, value, onBlur, locale = 'es', ...rest
}) => {
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="DD/MM/yyyy"
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          'aria-label': 'Cambiar fecha',
        }}
        inputVariant="outlined"
        onBlur={onBlur}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePickerField
