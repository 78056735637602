import {
  INSTITUCIONALES_TYPES
} from '../actions/types';

const INIT_STATE = {
  all: null,
  one: null,
  states: null,
  listActive: null, 
  errors: null,
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case INSTITUCIONALES_TYPES.GET_INSTITUCIONALES:
      return {
        ...state,
        loading: true
      }
    case INSTITUCIONALES_TYPES.GET_INSTITUCIONALES_SUCCESS:
      return {
        ...state,
        all: action.payload,
        one: null,
        loading: false
      }
    case INSTITUCIONALES_TYPES.GET_INSTITUCIONALES_FAILURE:
      return {
        ...state,
        all: null,
        one: null,
        loading: false
      }
    case INSTITUCIONALES_TYPES.STORE_INSTITUCIONAL:
      return {
        ...state,
        loading: true
      }
    case INSTITUCIONALES_TYPES.STORE_INSTITUCIONAL_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false
      }
    case INSTITUCIONALES_TYPES.STORE_INSTITUCIONAL_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false
      }
    case INSTITUCIONALES_TYPES.GET_ONE_INSTITUCIONAL:
      return {
        ...state,
        loading: true
      }
    case INSTITUCIONALES_TYPES.GET_ONE_INSTITUCIONAL_SUCCESS:
      return {
        ...state,
        errors: null,
        one: action.payload,
        loading: false
      }
    case INSTITUCIONALES_TYPES.GET_ONE_INSTITUCIONAL_FAILURE:
      return {
        ...state,
        errors: null,
        one: null,
        loading: false
      }
    case INSTITUCIONALES_TYPES.GET_STATES:
      return {
        ...state,
        loading: true
      }
    case INSTITUCIONALES_TYPES.GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload,
        loading: false
      }
    case INSTITUCIONALES_TYPES.GET_STATES_FAILURE:
      return {
        ...state,
        states: null,
        loading: false
      }
    case INSTITUCIONALES_TYPES.GET_LIST_ACTIVE:
      return {
        ...state,
        loading: true
      }
    case INSTITUCIONALES_TYPES.GET_LIST_ACTIVE_SUCCESS:
      return {
        ...state,
        listActive: action.payload,
        loading: false
      }
    case INSTITUCIONALES_TYPES.GET_LIST_ACTIVE_FAILURE:
      return {
        ...state,
        listActive: null,
        loading: false
      }
    case INSTITUCIONALES_TYPES.POST_TEMPORAL_JSON:
      return {
        ...state,
        loading: true
      }
    case INSTITUCIONALES_TYPES.POST_TEMPORAL_JSON_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false
      }
    case INSTITUCIONALES_TYPES.POST_TEMPORAL_JSON_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false
      }
    default: return { ...state };
  }
}
