import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router';
import { routesApp } from 'src/routesService';
import { validateOnePermission } from 'src/helpers/permissions';
import { ROLES_PERMISSIONS } from 'src/utils/constants/permissions';
import DeleteItem from 'src/components/shared/DeleteItem';

const ITEM_HEIGHT = 48;

export default function Actions(props) {
  const {
    rol,
    authPermissions,
    deleteRol,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {
          validateOnePermission(authPermissions, ROLES_PERMISSIONS.EDIT_ROLES) &&
          <MenuItem onClick={() => {
            history.push(routesApp.editRol.path.replace(":rolId", rol.id))
          }}>
            Editar
          </MenuItem>
        }
        {
          validateOnePermission(authPermissions, ROLES_PERMISSIONS.DELETE_ROLES) &&
          <DeleteItem 
            deleteAction={deleteRol}
            item={rol.id}
            message={`¿Deseas eliminar el rol "${rol.display_name}"?`}
            title="Eliminar Rol"
          />
        }
      </Menu>
    </div>
  );
}
