import React from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import { Button, createTheme, FormControl, FormHelperText, Grid, makeStyles, TextField, ThemeProvider, Typography } from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons'
import { red } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';

import { routesApp } from 'src/routesService';
import { string_to_slug, getDataFormatedForSelects } from 'src/helpers/random';
import CKEditorFull from 'src/components/shared/CKEditor/CKEditorFull';
import SelectField from '../../shared/SelectField';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) => ({
  color: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const FormPagina = (props) => {
  const classes = useStyles()
  const {
    pagina,
    states,
    loading,
    auth,
    updatePagina,
    addPagina,
    generateTemporalPreview,
  } = props;

  const getEstadosOptions = () => {
    return getDataFormatedForSelects(states, 'id', 'display_name');
  };

  return (
    <Formik
      initialValues={{
        title: pagina ? pagina.title : '',
        state: pagina ? { value: pagina.state.id, label: pagina.state.display_name } : states[0].id,
        slug: pagina ? pagina.slug : '',
        enlace: pagina ? `${pagina.slug}` : ``,
        body: pagina ? pagina.body : '',
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255, 'El título no debe superar los 255 caracteres').required('El título es requerido'),
        state: Yup.string().required('El estado es requerido'),
        slug: Yup.string().max(255, 'El slug no debe superar los 255 caracteres').required('El slug es requerido'),
        enlace: Yup.string().max(255, 'El enlace no debe superar los 255 caracteres'),
        body: Yup.string().required("El cuerpo de la página es requerido")
      })}
      onSubmit={async (data, actions) => {
        const newData = {
          id: pagina ? pagina.id : null,
          title: data.title,
          slug: data.slug,
          link: `${process.env.REACT_APP_JUANAMANSO_REA}${data.enlace}`,
          body: data.body,
          state_id: data.state.value
        }

        if (pagina) {
          await updatePagina(newData, pagina.id)
        } else {
          await addPagina(newData)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <Form>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">General</Typography>
              <TextField
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                label="Título"
                name="title"
                value={values.title}
                type="text"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue("title", e.target.value)
                  setFieldValue("slug", string_to_slug(e.target.value))
                  setFieldValue("enlace", string_to_slug(e.target.value))
                }}
                fullWidth
                margin="normal"
                variant="outlined"

              />
              <FormControl variant="outlined" fullWidth margin="normal" error={Boolean(touched.state && errors.state)} >
                <SelectField
                  placeholder="Seleccione estado..."
                  name="state"
                  getOptions={getEstadosOptions}
                  label="Estado"
                  setFieldValue={(value) => {
                    setFieldValue('state', value);
                  }}
                  value={values.state}
                />
                <FormHelperText className="text-danger">{(touched.state && errors.state) && errors.state}</FormHelperText>
              </FormControl>
              <TextField
                error={Boolean(touched.slug && errors.slug)}
                fullWidth
                helperText={touched.slug && errors.slug}
                label="Slug"
                margin="normal"
                name="slug"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue('slug', string_to_slug(e.target.value))
                  setFieldValue('enlace', string_to_slug(e.target.value))
                }}
                type="text"
                value={values.slug}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.enlace && errors.enlace)}
                helperText={touched.enlace && errors.enlace}
                label="Enlace"
                name="enlace"
                placeholder="https://www.enlace.com"
                value={`${process.env.REACT_APP_JUANAMANSO_REA}${values.enlace}`}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} md={12} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Cuerpo de la página</Typography>
              <FormControl margin="normal" fullWidth error={Boolean(touched.body && errors.body)}>
                <CKEditorFull
                  permissions={auth.data.permissions}
                  id="institucional"
                  value={values.body}
                  name="body"
                  styles={[
                    { name: 'height', value: '400px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.body && errors.body) && errors.body}</FormHelperText>

              </FormControl>
            </Grid>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={theme}>
                <Link to={routesApp.paginasInstitucionales.path}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
                // onClick={(e) => localStorage.setItem('PAGE_PREVISUALIZAR', '')}
              >
                Guardar
              </Button>
              <Button
                className="ml-3"
                color="secondary"
                onClick={async () => generateTemporalPreview(values)}
              >
                Previsualizar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default FormPagina
