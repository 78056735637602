import React from 'react';
import { NotificationManager } from 'react-notifications';

import ContactFormService from 'src/services/ContactFormService';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Typography
} from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';

import CKEditorTexto from 'src/components/shared/CKEditor/CKEditorTexto';
import ErrorsValidation from '../shared/displayErrorsValidations';
import Page from '../Page';
import PageContainer from 'src/containers/shared/PageContainer';
// import ErrorsValidation from '../shared/displayErrorsValidations';

const TextContactMessage = ({ errors, message, title }) => {
  async function handleSubmit(values, formikBag) {
    await ContactFormService.storeContactMessage({ contact: values.message })
      .then(() => {
        NotificationManager.success('Mensaje de confirmación contacto actualizado correctamente');
      })
      .catch((error) => formikBag.setErrors(error));
  }

  return (
    <PageContainer title={title} maxWidth={false}>
        <Typography color="textPrimary" variant="h2" className="mb-3">
          {title}
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <Formik
              initialValues={{ message }}
              validationSchema={Yup.object().shape({
                message: Yup.string().required('El texto del mensaje del contacto es requerido')
              })}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                touched,
                values
              }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={12} md={12} className="pr-3 pl-3 pt-2">
                      <Typography color="textPrimary" variant="overline" className="font-weight-bold">Texto</Typography>
                      <FormControl margin="normal" fullWidth error={Boolean(touched.body && errors.body)}>
                        <CKEditorTexto
                          id="contact-message"
                          value={values.message}
                          name="message"
                          styles={[
                            { name: 'height', value: '400px' },
                          ]}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                        <FormHelperText className="text-danger">{touched.message && errors.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        startIcon={<SaveIcon />}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
    </PageContainer>
  );
}

export default TextContactMessage;
