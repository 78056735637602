/* eslint-disable no-unused-vars */
import React from 'react'
import {
  Card, CardContent, Typography
} from '@material-ui/core'

import { FORM_NAMES } from 'src/utils/constants/general'
import Loading from 'src/components/shared/loading'
import PageContainer from 'src/containers/shared/PageContainer'
import ErrorsValidation from 'src/components/shared/displayErrorsValidations'

import FormPortada from './shared/FormPortada'

const PortadaView = (props) => {
  const {
    action, errors, loading, portadas, match: { params }, ...rest
  } = props
  const formTitle = FORM_NAMES[action];
  const portada = portadas?.find((p) => p.id === Number(params.portadaId)) ?? {}

  if (!portada) {
    return <Loading />
  }

  return (
    <PageContainer title={`${formTitle} Portada`} maxWidth={false}>
      <Typography
        color="textPrimary"
        variant="h2"
        className="mb-3"
      >
        {`${formTitle.replace(/.$/, 'ndo')} Portada`}
      </Typography>
      <ErrorsValidation errors={errors} />
      <Card>
        <CardContent>
          <FormPortada
            portada={portada}
            onSubmit={rest.onSubmitCallback(params?.portadaId)}
            loading={loading}
          />
        </CardContent>
      </Card>
    </PageContainer>
  )
}

export default PortadaView
