import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { NotificationManager } from 'react-notifications';

import { getAllHeaders, storeHeader, getOneHeader, getLogo, storeLogo, getListActive as getListActiveHeaders } from 'src/actions/headersAction';
import { getListActive as getListActiveInstitucionales} from 'src/actions/paginasInstitucionalesActions';
import { getListActive as getListActivePortadas } from 'src/actions/portadasActions';
import Loading from 'src/components/shared/loading';
import AddHeader from 'src/components/headers/AddHeader';
import EditHeader from 'src/components/headers/EditHeader';
import Headers from 'src/components/headers/Headers';
import { routesApp } from 'src/routesService';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import HeaderService from '../services/HeaderService';
import LogoHeader from 'src/components/headers/LogoHeader';

const HeaderContainer = (props) => {
  const { auth, headers, paginasInstitucionales, history, match, location, portadas } = props;

  const handleGetAllHeaders = (page, rows) => {
    props.getAllHeaders(page, rows, location.search);
  };

  const handleAddHeader = (data) => {
    props.storeHeader(null, data, history);
  };

  const handleUpdateHeader = (data, id) => {
    props.storeHeader(id, data, history);
  };

  const handleDeleteHeader = async (id) => {
    NotificationManager.info("Eliminando encabezado...", null, 1500);
    try {
      await HeaderService.deleteHeader(id);
      await props.getAllHeaders();
      NotificationManager.success('Encabezado eliminado correctamente');

    } catch (error) {
      showDeveloperMessageConsole('Error al eliminar encabezado: ', error);
      NotificationManager.error('Ha ocurrido un error. Intente nuevamente.');
    }
  };

  const handleUpdateLogo = (data) => {
    props.storeLogo(data);
  };

  useEffect(() => {
    if (match.path === routesApp.headers.path) {
      props.getAllHeaders(1, 10, location.search);
    } else if (match.path === routesApp.editHeader.path) {
      props.getOneHeader(match.params.headerId);
      props.getListActiveHeaders();
      props.getListActiveInstitucionales();
      props.getListActivePortadas();
    } else if (match.path === routesApp.addHeader.path) {
      props.getListActiveHeaders();
      props.getListActiveInstitucionales();
      props.getListActivePortadas();
    } else if (match.path === routesApp.logoHeader.path) {
      props.getLogo()
    }
    //eslint-disable-next-line
  }, [match.path, location.search])

  if (match.path === routesApp.logoHeader.path) {
    if (headers.logo === null) {
      return <Loading />
    }

    return <LogoHeader
      auth={auth.data}
      logo={headers.logo}
      errors={headers.errors}
      updateLogo={handleUpdateLogo}
    />
  }

  if (match.path === routesApp.addHeader.path) {
    if (paginasInstitucionales.listActive === null || portadas.listActive === null) {
      return <Loading />
    }

    return <AddHeader
      errors={headers.errors}
      loading={headers.loading}
      addHeader={handleAddHeader}
      paginasInst={paginasInstitucionales.listActive}
      portadas={portadas.listActive}
      headers={headers.listActive}
    />
  }

  if (match.path === routesApp.editHeader.path) {
    if (headers.one !== null && headers.one.id.toString() === match.params.headerId && paginasInstitucionales.listActive !== null && portadas.listActive !== null) {
      return <EditHeader
        header={headers.one}
        errors={headers.errors}
        loading={headers.loading}
        updateHeader={handleUpdateHeader}
        paginasInst={paginasInstitucionales.listActive}
        portadas={portadas.listActive}
        headers={headers.listActive}
      />
    } else {
      return <Loading />
    }
  }

  if (match.path === routesApp.headers.path && headers.all === null) {
    return <Loading />;
  }

  return (
    <Headers
      auth={auth.data}
      headers={headers.all}
      loading={headers.loading}
      getAllHeaders={handleGetAllHeaders}
      deleteHeader={handleDeleteHeader}
    />
  );
};

const mapStateToProps = ({ headers, paginasInstitucionales, auth, portadas }) => {
  return {
    headers,
    paginasInstitucionales,
    auth,
    portadas
  };
};

const mapDispatchToProps = {
  getAllHeaders,
  storeHeader,
  getOneHeader,
  getLogo,
  storeLogo,
  getListActiveHeaders,
  getListActiveInstitucionales,
  getListActivePortadas
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
