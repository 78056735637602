import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { routesApp } from 'src/routesService';
import { getTextContact, storeTextContact } from 'src/actions/contactFormActions';
import ContactFormService from 'src/services/ContactFormService';
import Loading from 'src/components/shared/loading';
import TextContactForm from 'src/components/contactForm/TextContactForm';
import TextContactMessage from 'src/components/contactMessage/TextContactMessage';

const ContactFormContainer = (props) => {
  const { contact, match } = props;
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ContactFormService.fetchContactMessage()
      .then((response) => response.data)
      .then((response) => setMessage(response))
      .catch((err) => setError({ error: err }))
      .finally(() => setLoading(false));
  }, [setMessage]);

  function handleUpdateTextContact(data) {
    props.storeTextContact(data);
  }

  useEffect(() => {
    props.getTextContact()
    //eslint-disable-next-line
  }, [])

  if (match.path === routesApp.contactForm.path) {
    if (contact.data === null) {
      return <Loading />;
    }

    return <TextContactForm text={contact.data} updateContact={handleUpdateTextContact} errors={contact.errors} loading={contact.loading} />
  }

  if (match.path === routesApp.contactMessage.path) {
    if (loading) {
      return <Loading />;
    }

    return <TextContactMessage errors={error} message={message} title="Mensaje Contacto"/>
  }
}

const mapStateToProps = ({ contact }) => {
  return {
    contact
  };
};

const mapDispatchToProps = {
  getTextContact,
  storeTextContact
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormContainer)
