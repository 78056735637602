import React from 'react'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import Page from '../Page'
import { FORM_ACTIONS } from 'src/utils/constants/general'
import ErrorsValidation from 'src/components/shared/displayErrorsValidations'
import FormPagina from './shared/form'
import PageContainer from 'src/containers/shared/PageContainer';

const AddPagina = (props) => {
  const {
    states,
    errors,
    loading,
    auth,
    addPagina,
    generateTemporalPreview,
  } = props;

  return (
    <PageContainer title="Agregar Página Institucional" maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          Agregando Página Institucional
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <FormPagina 
              action={FORM_ACTIONS.ADD}
              states={states}
              addPagina={addPagina}
              loading={loading}
              auth={auth}
              generateTemporalPreview={generateTemporalPreview}
            />
          </CardContent>
        </Card>
    </PageContainer>
  )
}

export default AddPagina
