import { CONTACT_FORM_TYPES } from './types'
import ContactFormService from './../services/ContactFormService'
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { NotificationManager } from 'react-notifications';

export const getTextContact = () => async dispatch => {
  dispatch({
    type: CONTACT_FORM_TYPES.GET_CONTACT
  })
  try {
    const result = await ContactFormService.getContactText();
    dispatch({
      type: CONTACT_FORM_TYPES.GET_CONTACT_SUCCESS,
      payload: result.data
    })
  } catch (error) {
    dispatch({
      type: CONTACT_FORM_TYPES.GET_CONTACT_FAILURE,
    })
    showDeveloperMessageConsole("Error al obtener el formulario de contacto: ", error)
  }
}

export const storeTextContact = (data) => async dispatch => {
  dispatch({
    type: CONTACT_FORM_TYPES.STORE_TEXT_FORM
  })
  try {
    await ContactFormService.storeTextContact(data);
    dispatch({
      type: CONTACT_FORM_TYPES.STORE_TEXT_FORM_SUCCESS,
    })
    NotificationManager.success("Formulario de contacto actualizado correctamente")

  } catch (error) {
    dispatch({
      type: CONTACT_FORM_TYPES.STORE_TEXT_FORM_FAILURE,
      payload: error.response ? error.response.data.errors : null
    })

    NotificationManager.error("Error al guardar el texto del formulario")
    showDeveloperMessageConsole("Error al guardar el texto del formulario: ", error)
  }
}
