import React from 'react';
import {
    Card, CardContent, Container, Typography
} from '@material-ui/core';
import { FORM_ACTIONS } from 'src/utils/constants/general';

import Page from '../Page';
import FormHeader from './shared/form';
import ErrorsValidation from '../shared/displayErrorsValidations';
import PageContainer from 'src/containers/shared/PageContainer';

const EditHeader = (props) => {
  const {
    header,
    errors,
    loading,
    paginasInst,
    portadas,
    headers,
    updateHeader,
  } = props;

  // const match = useRouteMatch()
  return (
      <PageContainer title="Editar Encabezado" maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          Editando Encabezado {`${header.name}`}
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <FormHeader
              action={FORM_ACTIONS.EDIT}
              header={header}
              updateHeader={updateHeader}
              loading={loading}
              paginasInst={paginasInst}
              portadas={portadas}
              headers={headers}
            />
          </CardContent>
        </Card>
      </PageContainer>
  );
};

export default EditHeader;
