import React from 'react';
import { Box, Container, Card, CardContent, Typography, Button } from '@material-ui/core';

import Page from '../Page';
import FormLogo from '../shared/FormLogo';
import ErrorsValidation from '../shared/displayErrorsValidations';
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';
import PageContainer from 'src/containers/shared/PageContainer';
// import { Link } from 'react-router-dom';
// import { routesApp } from 'src/routesService';

const LogoFooter = (props) => {
  const {
    auth,
    logo,
    loading,
    name,
    errors,
    switchActive = false,
    updateLogo,
  } = props;

  return (
    <PageContainer title="Logo" maxWidth={false}>
        <Card>
          <CardContent>

            <Box
              display="flex"
              justifyContent="space-between"
            >
              <Typography
                color="textPrimary"
                variant="h2"
                className="mb-3"
              >
                {name}
              </Typography>
              <PrimaryLinkButton to={`${process.env.REACT_APP_JUANAMANSO_REA}preview/footer`} external>
                Previsualizar Pie de página
              </PrimaryLinkButton>
            </Box>
            <ErrorsValidation errors={errors} />
            <Box mt={3}>
              <FormLogo
                authPermissions={auth.permissions}
                footer
                updateLogo={updateLogo}
                loading={loading}
                logo={logo}
                hasMania={switchActive}
              />
            </Box>
          </CardContent>
        </Card>
    </PageContainer>
  );
};

export default LogoFooter;
