//Esto es viejo
import React from 'react';
import { Box, Container } from '@material-ui/core';

import Page from '../Page';
import TableFooters from './shared/tableFooters';
import { routesApp } from 'src/routesService';
import { MENU_PERMISSIONS } from 'src/utils/constants/permissions';
import { validateOnePermission } from 'src/helpers/permissions';
import BarraBusquedaHeaders from '../headers/shared/barraBusquedaHeaders';
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';
import PageContainer from 'src/containers/shared/PageContainer';

const Footers = (props) => {
  const {
    footers,
    auth,
    loading,
    getAllFooters,
    deleteFooter,
  } = props;

  return (
    <PageContainer title="Footers" maxWidth={false}>
        {
          validateOnePermission(auth.permissions, MENU_PERMISSIONS.CREATE_MENU) &&
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <PrimaryLinkButton to={`${process.env.REACT_APP_JUANAMANSO_REA}preview/footer`} external className="mr-1">
              Previsualizar Pie de página
            </PrimaryLinkButton>
            <PrimaryLinkButton to={routesApp.addFooter.path}>
              + Añadir Pie de Página
            </PrimaryLinkButton>
          </Box>
        }
        <BarraBusquedaHeaders />
        <Box mt={3}>
          <TableFooters
            auth={auth}
            data={footers.data}
            currentPage={footers.current_page}
            itemsCountPerPage={footers.per_page}
            totalItemsCount={footers.total}
            nextPage={footers.next_page_url}
            prevPage={footers.prev_page_url}
            lastPage={footers.last_page}
            loading={loading}
            getAllFooters={getAllFooters}
            deleteFooter={deleteFooter}
          />
        </Box>
    </PageContainer>
  );
};

export default Footers;
