import React from 'react';
import { Chip } from '@material-ui/core';
import { PORTADAS_STATES } from 'src/utils/constants/api';

/**
 * Chip para el estado de un componente de portada
 */
export const FrontPageComponentStatus = ({ state }) => {
  return (
    <Chip
      label={state.display_name}
      style={{
        color: 'white',
        backgroundColor: state.id === PORTADAS_STATES.PUBLICADO ? '#00D014' : '#FF3812',
        width: 'fit-content'
      }}
    />
  )
}
