import React from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import { Button, createTheme, FormControl, FormHelperText, Grid, makeStyles, TextField, ThemeProvider, Typography } from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons'
import { red } from '@material-ui/core/colors';
import { Link, useHistory } from 'react-router-dom';

import { routesApp } from 'src/routesService';
// eslint-disable-next-line camelcase
import { string_to_slug, getDataFormatedForSelects, slug_to_string, showDeveloperMessageConsole } from 'src/helpers/random';
import NewsPagesService from 'src/services/NewsPagesService';
import CKEditorFull from 'src/components/shared/CKEditor/CKEditorFull';
import CKEditorTitulo from 'src/components/shared/CKEditor/CKEditorTitulo';
import CKEditorCopete from 'src/components/shared/CKEditor/CKEditorCopete';
import SelectField from '../../shared/SelectField';
import DatePickerField from '../../shared/Forms/DatePickerField';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((_theme) => ({
  color: {
    margin: _theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

async function preview(page) {
    const { response } = await NewsPagesService.temporalPreview({
      body: page.body,
      title: page.title
    }).then((res) => res.data).catch((error) => {
      showDeveloperMessageConsole('Error al generar el archivo JSON de las noticias: ', error);
    });

    window.open(`${process.env.REACT_APP_JUANAMANSO_REA}preview/noticia/${response.id}`, '_blank', 'noopener, noreferrer');
  }

const FormNewsPage = (props) => {
  const { action, noticia, states, loading, user } = props;
  const classes = useStyles();
  const history = useHistory();

  const getEstadosOptions = () => {
    return getDataFormatedForSelects(states, 'id', 'display_name');
  };

  const slug = noticia.slug?.replace(/\//g, '').replace('noticias', '') ?? '';

  return (
    <Formik
      initialValues={{
        title: noticia.title ?? '',
        state: noticia.state ? { value: noticia.state?.id, label: noticia.state?.display_name } : '',
        copete: noticia.copete ?? '',
        enlace: `noticias/${slug}`,
        slug,
        body: noticia.body ?? '',
        publication_date: noticia.publication_date ?? null,
        url: (() =>
        {
          if(noticia.slug)
          {
            const splittedSlug = noticia.slug.split('/');
            if(splittedSlug.length > 1)
            {
              return slug_to_string(splittedSlug[1]);
            }
          }
          return '';
        })(),
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255, 'El título no debe superar los 255 caracteres').required('El título es requerido'),
        state: Yup.string().required('El estado es requerido'),
        copete: Yup.string().max(255, 'El copete no debe superar los 255 caracteres').required('El copete es requerido'),
        slug: Yup.string().max(255, 'El slug no debe superar los 255 caracteres').required('El slug es requerido'),
        body: Yup.string().required('El cuerpo de la página es requerido'),
        publication_date: Yup.string().required('La fecha de publicación es requerida').nullable(),
        url: Yup.string().required('El nombre de la URL es requerido'),
      })}
      onSubmit={async (data) => {
        const newsData = {
          id: noticia ? noticia.id : null,
          title: data.title,
          state_id: data.state.value,
          copete: data.copete,
          slug: data.slug,
          // link: `${process.env.REACT_APP_JUANAMANSO_REA}${data.enlace}`,
          body: data.body,
          publication_date: data.publication_date,
        }

        await action(noticia.id)(newsData);

        history.push('/app/contenidos/paginas-noticias');

      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        isSubmitting,
        values
      }) => (
        <Form>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">General</Typography>
              <FormControl margin="normal" fullWidth error={Boolean(touched.title && errors.title)}>
                <Typography color="textPrimary" variant="overline">Título</Typography>
                <CKEditorTitulo
                  id="ck-title-noticias"
                  value={values.title}
                  name="title"
                  styles={[
                    { name: 'height', value: '150px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.title && errors.title) && errors.title}</FormHelperText>
              </FormControl>
              <FormControl margin="normal" fullWidth error={Boolean(touched.copete && errors.copete)}>
                <Typography color="textPrimary" variant="overline">Copete</Typography>
                <CKEditorCopete
                  id="ck-copete-noticias"
                  value={values.copete}
                  name="copete"
                  styles={[
                    { name: 'height', value: '150px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.copete && errors.copete) && errors.copete}</FormHelperText>
              </FormControl>
              <TextField
                error={Boolean(touched.url && errors.url)}
                fullWidth
                helperText={touched.url && errors.url}
                label="Nombre URL"
                margin="normal"
                name="url"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue('url', e.target.value)
                  setFieldValue('slug', string_to_slug(e.target.value))
                  setFieldValue('enlace', `noticias/${string_to_slug(e.target.value)}`)
                }}
                type="text"
                value={values.url}
                variant="outlined"
              />
              <FormControl variant="outlined" fullWidth margin="normal" error={Boolean(touched.state && errors.state)}>
                <SelectField
                  placeholder="Seleccione estado..."
                  name="state"
                  getOptions={getEstadosOptions}
                  label="Estado"
                  setFieldValue={(value) => {
                    setFieldValue('state', value);
                  }}
                  isLoading={states.length < 1}
                  value={values.state}
                />
                <FormHelperText className="text-danger">{(touched.state && errors.state) && errors.state}</FormHelperText>
              </FormControl>
              <TextField
                error={Boolean(touched.slug && errors.slug)}
                fullWidth
                helperText={touched.slug && errors.slug}
                label="Slug"
                margin="normal"
                name="slug"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue('slug', string_to_slug(e.target.value))
                  setFieldValue('enlace', `noticias/${string_to_slug(e.target.value)}`)
                }}
                type="text"
                value={values.slug}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.enlace && errors.enlace)}
                helperText={touched.enlace && errors.enlace}
                label="Enlace"
                name="enlace"
                placeholder="https://www.enlace.com"
                value={`${process.env.REACT_APP_JUANAMANSO_REA}${values.enlace ?? ''}`}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                fullWidth
                disabled
              />
              <Grid item xs={12} md={12} style={{ margin: '1rem 0rem' }}>
                <FormControl margin="normal" fullWidth error={Boolean(touched.publication_date && errors.publication_date)}>
                  <DatePickerField
                    id="date-picker-publication-date"
                    label="Fecha de publicación"
                    value={values.publication_date}
                    error={Boolean(touched.publication_date && errors.publication_date)}
                    onChange={(date) => setFieldValue('publication_date', date)}
                    onBlur={handleBlur}
                    fullWidth
                  />
                  <FormHelperText className="text-danger">{(touched.publication_date && errors.publication_date) && errors.publication_date}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Cuerpo de la página</Typography>
              <FormControl margin="normal" fullWidth error={Boolean(touched.body && errors.body)}>
                <CKEditorFull
                  permissions={user.permissions}
                  id="noticias"
                  value={values.body}
                  name="body"
                  styles={[
                    { name: 'height', value: '400px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.body && errors.body) && errors.body}</FormHelperText>

              </FormControl>
            </Grid>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={theme}>
                <Link to={routesApp.newsPage.path}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading || isSubmitting}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
              <Button
                className="ml-3"
                color="secondary"
                onClick={async () => preview(values)}
              >
                Previsualizar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default FormNewsPage
