import React from 'react'
import { Card, CardContent, Container, Typography } from '@material-ui/core'

import Page from '../Page'
// import { useRouteMatch } from 'react-router'
import FormUser from '../users/shared/form'
import { FORM_ACTIONS } from 'src/utils/constants/general'
import ErrorsValidation from '../shared/displayErrorsValidations'
import PageContainer from 'src/containers/shared/PageContainer';

const UserProfile = (props) => {
  const {
    user,
    roles,
    errors,
    loading,
    updateUser,
  } = props;

  // const match = useRouteMatch()
  return (
    <PageContainer title="Perfil" maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          Perfil
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <FormUser 
              action={FORM_ACTIONS.EDIT}
              editProfile={true}
              user={user} roles={roles} 
              updateUser={updateUser} 
              loading={loading}
            />
          </CardContent>
        </Card>
    </PageContainer>
  )
}

export default UserProfile
