import api from '../api';

const AgrupadoresService = {
  getAgrupadores: ( page, limit, filters) => {
    return api.get(`/faq_group?page=${page}&limit=${limit}&${filters !== '' ? filters.substring(1) : filters}`);
  },
  getOneAgrupador: (agrupadorId) => {
    return api.get(`/faq_group/${agrupadorId}`);
  },
  addAgrupador: (data) => {
    return api.post(`/faq_group`, data);
  },
  updateAgrupador: (data, agrupadorId) => {
    return api.put(`/faq_group/${agrupadorId}`, data);
  },
  deleteAgrupador: (agrupadorId) => {
    return api.delete(`/faq_group/${agrupadorId}`);
  },
  getFAQs: (agrupadorId, page, limit, filters) => {
    return api.get(`/faq_group/${agrupadorId}/faq?page=${page}&limit=${limit}&${filters !== '' ? filters.substring(1) : filters}`);
  },
  getOneFAQ: (id, agrupadorId) => {
    return api.get(`/faq_group/${agrupadorId}/faq/${id}`);
  },
  addFAQ: (data, agrupadorId) => {
    return api.post(`/faq_group/${agrupadorId}/faq`, data)
  },
  updateFAQ: (data, id, agrupadorId) => {
    return api.put(`/faq_group/${agrupadorId}/faq/${id}`, data);
  },
  deleteFAQ: (id, agrupadorId) => {
    return api.delete(`/faq_group/${agrupadorId}/faq/${id}`);
  },
};

export { AgrupadoresService as default };


