import React from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  makeStyles,
  Grid,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import {
  useHistory,
  useLocation
} from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  root: {
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const BarraBusquedaPermissions = ({ className, ...props }) => {
  const classes = useStyles();
  const query = useQuery();
  const history=useHistory()
  return (
    <Box mt={3}>
      <Card color="#eeeeee">
        <CardContent>
          <Formik
            initialValues={{
              name: query.get('name_filter') ? query.get('name_filter'):'',
              displayName: query.get('display_name_filter') ? query.get('display_name_filter'):'',
              description: query.get('description_filter') ? query.get('description_filter'):'',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255, 'El nombre no debe superar los 255 caracteres'),
              displayName: Yup.string().max(255, 'El displayName no debe superar los 255 caracteres'),
              description: Yup.string().max(255, 'La descripción no debe superar los 255 caracteres')
            })}
            onSubmit={async (data) => {
              query.set('name_filter', data.name)
              query.set('display_name_filter', data.displayName)
              query.set('description_filter', data.description)
              history.push(`?${query.toString()}`)
            }}
            onReset={(data, actions)=>{
              actions.setValues({
                name:'',
                displayName:'',
                description:''
              })
              query.set('name_filter', "")
              query.set('display_name_filter', "")
              query.set('description_filter', "")
              history.push(`?${query.toString()}`)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleReset,
              isSubmitting,
              touched,
              values
            }) => (
              <Form className={classes.root} noValidate autoComplete="off">
                <Grid container>
                  <Grid xs={12} md={4} className="p-2" item>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      fullWidth
                      label="Nombre"
                      name="name"
                      variant="outlined"
                      type="input"
                    />
                  </Grid>
                  <Grid item xs={12} md={4} className="p-2">
                    <TextField
                      error={Boolean(touched.displayName && errors.displayName)}
                      helperText={touched.displayName && errors.displayName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.displayName}
                      fullWidth
                      label="Nombre a mostrar"
                      name="displayName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={4} className="p-2">
                    <TextField
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      fullWidth
                      label="Descripción"
                      name="description"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={3} className="p-2" />
                  <Grid item xs={12} className="pt-2 pl-2 text-right">
                    <Button
                      className={classes.importButton}
                      onClick={handleReset}
                    >
                      Limpiar
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BarraBusquedaPermissions;
