import React from 'react';
import logoJuanaManso from '../../assets/img/logo_jm.svg';

const Logo = (props) => {
  return (
    <img
      alt="Logo Juana Manso"
      src={logoJuanaManso}
      className="logo-jm"
      {...props}
    />
  );
};

export default Logo;
