import React from 'react'
import { useDispatch } from 'react-redux'

import LoginView from 'src/components/auth/LoginView'
import { showDeveloperMessageConsole } from 'src/helpers/random'
import { NotificationManager } from 'react-notifications'
import { routesApp } from 'src/routesService'
import AuthActions from 'src/actions/authActions'
import AuthService from '../../services/AuthService'

const LoginContainer = (props) => {
  const dispatch = useDispatch();
  const { history } = props

  const handleLogin = async (email, password) => {
    try {
      const result = await AuthService.login({ usernameOrEmail: email, password })
      localStorage.setItem('access_token', result.data.access_token)
      const userData = await AuthService.getUser().then((r) => r.data);

      dispatch(AuthActions.login(userData));

      history.push(routesApp.inicio.path)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        NotificationManager.error('Credenciales inválidas')
      } else {
        NotificationManager.error('Ha ocurrido un error. Intente nuevamente')
      }
      showDeveloperMessageConsole('Login error: ', error)
    }
  }

  return (
    <LoginView
      handleLogin={handleLogin}
    />
  )
}

export default LoginContainer
