import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactHtmlParser from 'react-html-parser'
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableHeader from 'src/components/shared/TableHeader';
import Actions from './actions';

const TableFAQs = ({ className, auth, data, currentPage, itemsCountPerPage, totalItemsCount, prevPage, nextPage, lastPage, loading, ...rest }) => {
  const handlePageChange = (event, page) => {
    rest.getAllFAQs(page + 1, parseInt(itemsCountPerPage));
  };

  const handleChangeRowsPerPage = (event) => {
    rest.getAllFAQs(1, event.target.value);
  };

  return (
    <Card
      className={className}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Typography variant="subtitle1" className="mt-3 ml-3 mb-2">
            Listado de FAQs del agrupador "{rest.agrupadorTitle}" { loading && <CircularProgress size={24} style={{ marginLeft: 15 }} /> }
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader property="question">Pregunta</TableHeader>
                <TableHeader property="answer">Respuesta</TableHeader>
                <TableHeader property="order">Orden</TableHeader>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((faq) => (
                <TableRow
                  hover
                  key={faq.id}
                >
                  <TableCell className="texto-truncate">
                    {ReactHtmlParser(faq.question)}
                  </TableCell>
                  <TableCell className="texto-truncate">
                    {ReactHtmlParser(faq.answer)}
                  </TableCell>
                  <TableCell>
                    {faq.order}
                  </TableCell>
                  <TableCell>
                    <Actions faq={faq} agrupadorId={rest.agrupadorId} deleteFAQ={rest.deleteFAQ} authPermissions={auth.permissions} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {
        data.length === 0 &&
        <div className="text-center mt-2" >
          No hay resultados para su búsqueda
                </div>
      }
      <TablePagination
        component="div"
        className="paginador" 
        count={data.length}//hasta que venga la api
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={currentPage - 1}
        rowsPerPage={parseInt(itemsCountPerPage)}
        rowsPerPageOptions={[5, 10, 20]}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </Card>
  );
};

export default TableFAQs;
