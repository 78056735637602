import axios from 'axios'
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { routes } from 'src/routesService';

// URL DE LA API
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 60000
});

axiosInstance.interceptors.request.use(
  request => requestHandler(request)
);

axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)

const requestHandler = (request) => {
  let jwt = localStorage.getItem('access_token');

  // Modify request here
  request.headers['Content-Type'] = 'application/json';
  request.headers['Accept'] = 'application/json';
  request.headers['Authorization'] = 'Bearer ' + jwt;

  return request;
}

const errorHandler = (error) => {
  if (error.response && error.response.status === 401 && window.location.pathname !==  `${routes.login.path}`) {
    window.location.pathname = `${routes.login.path}`
    localStorage.removeItem('access_token')
  } else {
    return Promise.reject(error)
      .catch((e) => {
        showDeveloperMessageConsole(e);
        throw e;
      })
  }
}

const successHandler = (response) => {
  return response
}

export default axiosInstance;
