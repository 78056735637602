import React, { useState } from 'react'

import AuthService from './../../services/AuthService'
import { showDeveloperMessageConsole } from 'src/helpers/random'
import ResetPassword from 'src/components/auth/ResetPassword'
import { NotificationManager } from 'react-notifications'

const ResetPasswordContainer = (props) => {
  const { match } = props
  const [errors, setErrors]=useState(null)
  const handleResetPassword = async (password, passwordConfirm, setPasswordCambiada, setSubmittingForm) => {
    NotificationManager.info("Cambiando contraseña")

    try {
      const data = {
        token: match.params.hash,
        email: match.params.email,
        password: password,
        password_confirmation: passwordConfirm
      }
      await AuthService.sendNewPassword(data)
      setPasswordCambiada(true)
      setErrors(null)
    } catch (error) {
      setSubmittingForm(false)
      if(error.response && error.response.status === 422){
        setErrors(error.response.data.errors)
      }
      NotificationManager.error("Error al cambiar la contraseña")
      showDeveloperMessageConsole("Error al restaurar contraseña: ", error)
    }
  }

  return (
    <ResetPassword
      resetPassword={handleResetPassword}
      errorsPassword={errors}
    />
  )
}

export default ResetPasswordContainer
