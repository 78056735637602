import React from 'react';
import { Box, Container, Button } from '@material-ui/core';

import { routesApp } from 'src/routesService';
import { PANEL_USER_PERMISSIONS } from 'src/utils/constants/permissions';
import { validateOnePermission } from 'src/helpers/permissions';
import BarraBusquedaHeaders from '../headers/shared/barraBusquedaHeaders';

import Page from '../Page';
import TablePanelUser from './shared/tablePanelUser';
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';
import PageContainer from 'src/containers/shared/PageContainer';

const PanelUser = (props) => {
  const {
    panelesUser,
    user,
    loading,
    getAllPanelesUser,
    deletePanelUser,
  } = props;

  // useEffect(() => {
  //   getAllPanelesUser();
  // }, []);

  return (
    <PageContainer title="Panel User" maxWidth={false}>
        {
          validateOnePermission(user.permissions, PANEL_USER_PERMISSIONS.CREATE_PANEL_USER) && (
            <Box
              display="flex"
              justifyContent="flex-end"
            >
              <PrimaryLinkButton to={routesApp.addPanelUser.path}>
                + Añadir elemento del panel
              </PrimaryLinkButton>
            </Box>
          )
        }
        <BarraBusquedaHeaders />
        <Box mt={3}>
          <TablePanelUser
            auth={user}
            data={panelesUser.data}
            currentPage={panelesUser.current_page}
            itemsCountPerPage={panelesUser.per_page}
            totalItemsCount={panelesUser.total}
            nextPage={panelesUser.next_page_url}
            prevPage={panelesUser.prev_page_url}
            lastPage={panelesUser.last_page}
            loading={loading}
            getAllPanelesUser={getAllPanelesUser}
            deletePanelUser={deletePanelUser}
          />
        </Box>
    </PageContainer>
  );
};

export default PanelUser;
