import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, createTheme, Grid, makeStyles, TextField, ThemeProvider, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText, Switch } from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';
import { routesApp } from 'src/routesService';
import { getDataFormatedForSelects, getDataSelected, URLRegex } from 'src/helpers/random';
import SelectField from '../../shared/SelectField';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) => ({
  color: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const FormHeader = (props) => {
  const classes = useStyles();
  const {
    header,
    loading,
    paginasInst,
    portadas,
    headers,
    updateHeader,
    addHeader,
  } = props;
  const [linkType, setLinkType] = useState(header ? header.link_type : 'url');

  const getPaginasInstitucionales = () => {
    return getDataFormatedForSelects(paginasInst, 'id', 'link');
  };

  const getPortadas = () => {
    return getDataFormatedForSelects(portadas, 'id', 'link');
  };

  const getHeaders = () => {
    let aux = Array.isArray(headers) ? headers : [];
    aux = aux.filter((_header) => _header.link_type === 'children');
    if (header) aux = aux.filter((_header) => _header.id !== header.id);
    const selectHeaders = getDataFormatedForSelects(aux, 'id', 'name');
    const nullHeader = [{ label: 'Ninguno', value: '' }];
    return nullHeader.concat(selectHeaders);
  };

  return (
    <Formik
      initialValues={{
        name: header ? header.name : '',
        order: header ? header.order : '',
        link: header ? header.link : '',
        instWeb: header ? (header.institutional_page !== null ? { value: header.institutional_page.id, label: header.institutional_page.link } : '')   : '',
        instMenu: header ? (header.parent !== null ? getDataSelected(headers, 'id', header.parent, 'name') : '')   : '',
        portadaId: header ? (header.front_page !== null ? { value: header.front_page.id, label: `${process.env.REACT_APP_JUANAMANSO_REA}${header.front_page.slug}` } : '')   : '',
        active: header ? header.active : true,
        parent: header ? header.parent : '',
        linkTypeForm: header ? header.link_type : 'url'
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(30, 'El nombre no debe superar los 30 caracteres').required('El nombre es requerido'),
        order: Yup.number().required('El orden es requerido'),
        link: linkType === 'url'
        && Yup.string()
          .max(255, 'El URL no debe superar los 255 caracteres')
          .required('El url es requerido')
          .test('valid-url', 'La URL ingresada es inválida',(str) => !!URLRegex.test(str)),
        instWeb: linkType === 'institutional' && Yup.string().nullable().required('Debe seleccionar una página institucional'),
        portadaId: linkType === 'front_page' && Yup.string().nullable().required('Debe seleccionar una portada'),
        active: Yup.boolean().required('El activo es requerido')
        // parent: Yup.string().required('El menu padre es requerido')
      })}
      onSubmit={async (data, actions) => {
        const newData = {
          name: data.name,
          order: data.order,
          active: data.active,
          parent: data.instMenu.value,
          link_type: data.linkTypeForm
        };

        switch (linkType) {
          case 'url':
            newData.link = data.link;
            break;
          case 'institutional':
            newData.institutional_page_id = data.instWeb ? data.instWeb.value : null;
            break;
          case 'front_page':
            newData.front_page_id = data.portadaId ? data.portadaId.value : null;
            break;
          case 'children':
            newData.link = null;
            newData.front_page_id = null;
            newData.institutional_page_id = null;
            break;
          default:
            console.log('error');
        }
        if (header) {
          newData.id=header.id
          await updateHeader(newData, header.id);
        } else {
          await addHeader(newData);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        touched,
        setFieldValue,
        values
      }) => (
        <Form>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">General</Typography>

              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Nombre*"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.order && errors.order)}
                fullWidth
                helperText={touched.order && errors.order}
                label="Orden"
                margin="normal"
                name="order"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.order}
                variant="outlined"
              />

              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Estado</Typography>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                  (
                    <Switch
                      checked={values.active}
                      onChange={handleChange}
                      name="active"
                      color="primary"
                    />
                  )
                  }
                  label="Activo"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Enlace</Typography>

              <FormControl fullWidth margin="normal" style={{ marginBottom: 6 }}>
                <FormLabel component="legend">Tipo Enlace</FormLabel>
                <RadioGroup row aria-label="gender" name="tipoEnlace" value={linkType} onChange={handleChange}>
                  <FormControlLabel value="url" onChange={() => { values.linkTypeForm = 'url'; setLinkType('url'); }} control={<Radio />} label="URL" />
                  <FormControlLabel value="institutional" onChange={() => { values.linkTypeForm = 'institutional'; setLinkType('institutional'); }} control={<Radio />} label="Páginas Institucionales" />
                  <FormControlLabel value="front_page" onChange={() => { values.linkTypeForm = 'front_page'; setLinkType('front_page'); }} control={<Radio />} label="Portadas" />
                  <FormControlLabel value="children" onChange={() => { values.linkTypeForm = 'children'; setLinkType('children'); }} control={<Radio />} label="Menu Padre" />
                </RadioGroup>
              </FormControl>
              {
                linkType === 'url'
                && (
                  <TextField
                    error={Boolean(touched.link && errors.link)}
                    fullWidth
                    helperText={touched.link && errors.link}
                    label="Enlace*"
                    name="link"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.link}
                    variant="outlined"
                  />
                )
              }
              {
                linkType === 'institutional'
                && (
                  <FormControl fullWidth error={Boolean(touched.instWeb && errors.instWeb)}>
                    <SelectField
                      placeholder="Seleccione enlace..."
                      name="instWeb"
                      getOptions={getPaginasInstitucionales}
                      label="Enlace*"
                      setFieldValue={(value) => {
                        setFieldValue('instWeb', value);
                      }}
                      value={values.instWeb}
                    />
                    <FormHelperText className="text-danger">{(touched.instWeb && errors.instWeb) && errors.instWeb}</FormHelperText>
                  </FormControl>
                )
              }
              {
                linkType === 'front_page'
                && (
                  <FormControl fullWidth error={Boolean(touched.portadaId && errors.portadaId)}>
                    <SelectField
                      placeholder="Seleccione enlace..."
                      name="portadaId"
                      getOptions={getPortadas}
                      label="Enlace*"
                      setFieldValue={(value) => {
                        setFieldValue('portadaId', value);
                      }}
                      value={values.portadaId}
                    />
                    <FormHelperText className="text-danger">{(touched.portadaId && errors.portadaId) && errors.portadaId}</FormHelperText>
                  </FormControl>
                )
              }

              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Menú Padre</Typography>
              <FormControl fullWidth error={Boolean(touched.instMenu && errors.instMenu)}>
                <SelectField
                  placeholder="Seleccione menú..."
                  name="instMenu"
                  getOptions={getHeaders}
                  setFieldValue={(value) => {
                    setFieldValue('instMenu', value);
                  }}
                  value={values.instMenu}
                />
                <FormHelperText className="text-danger">{(touched.instMenu && errors.instMenu) && errors.instMenu}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={theme}>
                <Link to={routesApp.headers.path}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default FormHeader;
