import {
  PORTADAS_TYPES
} from '../actions/types';

const INIT_STATE = {
  all: null,
  one_portada: null,
  componentes: null,
  one_component: null,
  types_component: null,
  listActive: null,
  loading: false,
  errors: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PORTADAS_TYPES.GET_PORTADAS:
      return {
        ...state,
        loading: true
      };
    case PORTADAS_TYPES.GET_PORTADAS_SUCCESS:
      return {
        ...state,
        loading: false,
        all: action.payload,
        errors: null
      };
    case PORTADAS_TYPES.GET_PORTADAS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case PORTADAS_TYPES.GET_ONE_PORTADA:
      return {
        ...state,
        loading: true
      }
    case PORTADAS_TYPES.GET_ONE_PORTADA_SUCCESS:
      return {
        ...state,
        errors: null,
        one_portada: action.payload,
        loading: false
      }
    case PORTADAS_TYPES.GET_ONE_PORTADA_FAILURE:
      return {
        ...state,
        errors: null,
        one_portada: null,
        loading: false
      }
    case PORTADAS_TYPES.STORE_PORTADA:
      return {
        ...state,
        loading: true
      }
    case PORTADAS_TYPES.STORE_PORTADA_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false
      }
    case PORTADAS_TYPES.STORE_PORTADA_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false
      }
    case PORTADAS_TYPES.GET_COMPONENTES:
      return {
        ...state,
        loading: true,
        errors: null
      };
    case PORTADAS_TYPES.GET_COMPONENTES_FAILURE:
      return {
        ...state,
        loading: false,
        componentes: null
      };
    case PORTADAS_TYPES.GET_COMPONENTES_SUCCESS:
      return {
        ...state,
        loading: false,
        componentes: action.payload,
        errors: null
      };

    case PORTADAS_TYPES.GET_ONE_COMPONENT:
      return {
        ...state,
        loading: true
      }
    case PORTADAS_TYPES.GET_ONE_COMPONENT_SUCCESS:
      return {
        ...state,
        errors: null,
        one_component: action.payload,
        loading: false
      }
    case PORTADAS_TYPES.GET_ONE_COMPONENT_FAILURE:
      return {
        ...state,
        errors: null,
        one_component: null,
        loading: false
      }
    case PORTADAS_TYPES.STORE_COMPONENT:
      return {
        ...state,
        loading: true
      }
    case PORTADAS_TYPES.STORE_COMPONENT_SUCCESS:
      return {
        ...state,
        errors: null,
        one_component: null,
        loading: false
      }
    case PORTADAS_TYPES.STORE_COMPONENT_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false
      }
    case PORTADAS_TYPES.GET_COMPONENT_TYPES:
      return {
        ...state,
        loading: true
      }
    case PORTADAS_TYPES.GET_COMPONENT_TYPES_SUCCESS:
      return {
        ...state,
        types_component: action.payload,
        loading: false
      }
    case PORTADAS_TYPES.GET_COMPONENT_TYPES_FAILURE:
      return {
        ...state,
        types_component: null,
        loading: false
      }
    case PORTADAS_TYPES.GET_LIST_ACTIVE:
      return {
        ...state,
        loading: true
      }
    case PORTADAS_TYPES.GET_LIST_ACTIVE_SUCCESS:
      return {
        ...state,
        listActive: action.payload,
        loading: false
      }
    case PORTADAS_TYPES.GET_LIST_ACTIVE_FAILURE:
      return {
        ...state,
        listActive: null,
        loading: false
      }
    default: return { ...state };
  }
};
