import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

import RestrictedRoute from 'src/components/shared/restrictedRoute';

import { getAllMenusUsr, getOneMenuUsr, storeMenuUsr, deleteMenuUsr } from 'src/actions/menuUsrActions';
import Loading from 'src/components/shared/loading';
import { routesApp } from 'src/routesService';
import { NotificationManager } from 'react-notifications';
import MenuUsr from 'src/components/menuUsr/MenuUsr';
import { FORM_ACTIONS } from 'src/utils/constants/general';
import FormMenuUsr from 'src/components/menuUsr/FormMenuUsr';
import { getListActive as getListActiveInstitucionales } from 'src/actions/paginasInstitucionalesActions';
import { getListActive as getListActivePortadas } from 'src/actions/portadasActions';

const MenuUsrContainer = (props) => {
  const {
    history,
    location,
    match,
    menuUsr,
    institucionales,
    portadas,
    user
  } = props;

  const menuUsrRoutes = {
    index: `${routesApp.menuUsr.path}`,
    add: `${routesApp.addMenuUsr.path}`,
    edit: `${routesApp.editMenuUsr.path}`,
  };

  const menuUsrPermissions = {
    index: routesApp.menuUsr.permission,
    add: routesApp.addMenuUsr.permission,
    edit: routesApp.editMenuUsr.permission,
  };

  const handleGetAllMenusUsr = (page, rows) => {
    props.getAllMenusUsr(page, rows, location.search);
  };

  const handleAddMenuUsr = (data) => {
    props.storeMenuUsr(null, data);
    history.push(menuUsrRoutes.index);
  };

  const handleUpdateMenuUsr = (data, id) => {
    props.storeMenuUsr(id, data);
    history.push(menuUsrRoutes.index);
  };

  const handleDeleteMenuUsr = (id) => {
    NotificationManager.info('Eliminando menu de usuario...', null, 1500);
    props.deleteMenuUsr(id);
    props.getAllMenusUsr();
  };

  useEffect(() => {
    if (match.path === menuUsrRoutes.index) {
      props.getAllMenusUsr(1, 10, location.search);
    } else if (match.path === menuUsrRoutes.edit) {
      props.getOneMenuUsr(match.params.menuUsrId);
      props.getListActiveInstitucionales();
      props.getListActivePortadas();
    } else if (match.path === menuUsrRoutes.add) {
      props.getListActiveInstitucionales();
      props.getListActivePortadas();
    }
    // eslint-disable-next-line
  }, [match.path, location.search]);

  return (
    <Switch>
      <RestrictedRoute exact path={menuUsrRoutes.index} permission={menuUsrPermissions.index} authPermissions={user.permissions}>
        {(menuUsr.all === null) ? (
          <Loading />
        ) : (
          <MenuUsr
            user={user}
            menusUsr={menuUsr.all}
            loading={menuUsr.loading}
            getAllMenusUsr={handleGetAllMenusUsr}
            deleteMenuUsr={handleDeleteMenuUsr}
          />
        )}
      </RestrictedRoute>
      <RestrictedRoute exact path={menuUsrRoutes.edit} permission={menuUsrPermissions.edit} authPermissions={user.permissions}>
        {(!menuUsr.loading && Boolean(menuUsr.one) && menuUsr.one.id.toString() === match.params.menuUsrId) ? (
          <FormMenuUsr
            action={FORM_ACTIONS.EDIT}
            errors={menuUsr.errors}
            loading={menuUsr.loading}
            menuUsr={menuUsr.one}
            handleMenuUsr={handleUpdateMenuUsr}
            paginasInst={institucionales}
            portadas={portadas}
          />
        ) : (
          <Loading />
        )}
      </RestrictedRoute>
      <RestrictedRoute exact path={menuUsrRoutes.add} permission={menuUsrPermissions.add} authPermissions={user.permissions}>
        <FormMenuUsr
          action={FORM_ACTIONS.ADD}
          errors={menuUsr.errors}
          loading={menuUsr.loading}
          menuUsr={{}}
          handleMenuUsr={handleAddMenuUsr}
          paginasInst={institucionales}
          portadas={portadas}
        />
      </RestrictedRoute>
    </Switch>
  );
};

const mapStateToProps = ({
  menuUsr,
  auth,
  paginasInstitucionales,
  portadas
}) => {
  return {
    menuUsr,
    institucionales: paginasInstitucionales.listActive,
    portadas: portadas.listActive,
    user: auth.data
  };
};

const mapDispatchToProps = {
  getAllMenusUsr,
  getOneMenuUsr,
  getListActiveInstitucionales,
  getListActivePortadas,
  storeMenuUsr,
  deleteMenuUsr
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuUsrContainer);
