import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { getAllUsers, storeUser, getOneUser } from 'src/actions/usersActions'
import { getAllRoles } from 'src/actions/rolesActions'

import Loading from 'src/components/shared/loading'
import AddUser from 'src/components/users/AddUser'
import EditUser from 'src/components/users/EditUser'
import Users from 'src/components/users/Users'
import { routesApp } from 'src/routesService'
import { showDeveloperMessageConsole } from 'src/helpers/random'
import { NotificationManager } from 'react-notifications'
import UsersService from './../services/UsersService'


const UsersContainer = (props) => {
  const { users, roles, auth, history, match, location } = props

  const handleGetAllUsers = (page, rows) => {
    props.getAllUsers(page, rows, location.search)
  }

  const handleAddUser = (data) => {
    props.storeUser(null, data, history)
  }

  const handleUpdateUser = (data, id) => {
    props.storeUser(id, data, history)
  }

  const handleDeleteUser = async (id) => {
    NotificationManager.info("Eliminando usuario...", null, 1500)
    try {
      await UsersService.deleteUser(id);
      await props.getAllUsers()
      NotificationManager.success("Usuario eliminado correctamente")

    } catch (error) {
      showDeveloperMessageConsole("Error al eliminar usuario: ", error)
      NotificationManager.error("Ha ocurrido un error. Intente nuevamente.")
    }
  }

  useEffect(() => {
    props.getAllRoles()
    if (match.path === routesApp.users.path) {
      props.getAllUsers(1, 10, location.search)
    } else if (match.path === routesApp.editUser.path) {
      props.getOneUser(match.params.userId)
    }

    //eslint-disable-next-line
  }, [match.path, location.search])

  if ((match.path === routesApp.users.path && users.all === null) || roles.all === null) {
    return <Loading />
  }

  if (match.path === routesApp.addUser.path) {
    return <AddUser
      roles={roles.all}
      errors={users.errors}
      loading={users.loading}
      addUser={handleAddUser}
    />
  }

  if (match.path === routesApp.editUser.path) {
    if (users.one !== null && users.one.id.toString() === match.params.userId) {
      return <EditUser
        user={users.one}
        roles={roles.all}
        errors={users.errors}
        loading={users.loading}
        updateUser={handleUpdateUser}
      />
    } else {
      return <Loading />
    }
  }

  return (
    <Users
      auth={auth.data}
      users={users.all}
      roles={roles.all}
      loading={users.loading}
      getAllUsers={handleGetAllUsers}
      deleteUser={handleDeleteUser}
    />
  )
}

const mapStateToProps = ({ users, roles, auth }) => {
  return {
    users,
    roles,
    auth
  };
};

const mapDispatchToProps = {
  getAllUsers,
  getAllRoles,
  storeUser,
  getOneUser
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer)
