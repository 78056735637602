import React from 'react'

import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import { Button, createTheme, FormControl, FormHelperText, Grid,  makeStyles, TextField, ThemeProvider, Typography } from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons'
import { red } from '@material-ui/core/colors';
import { getIdsFromDataSelects } from 'src/helpers/random';
import { Link } from 'react-router-dom';
import { routesApp } from 'src/routesService';
import { getDataFormatedForSelects } from 'src/helpers/random';
import SelectField from 'src/components/shared/SelectField'

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) => ({
  color: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const getTextRoles = (roles) => {
  let aux = []
  // eslint-disable-next-line
  roles.map(r => {
    aux.push(r.display_name)
  })

  return aux.join(" - ")
}
const FormUser = (props) => {
  const classes = useStyles()

  const {
    user,
    roles,
    loading,
    editProfile,
    updateUser,
    addUser,
  } = props;

  const getRolesOptions = () => {
    return getDataFormatedForSelects(roles, 'id', 'display_name');
  };

  return (
    <Formik
      initialValues={{
        name: user ? user.name : '',
        lastname: user ? user.lastname : '',
        username: user ? user.username : '',
        email: user ? user.email : '',
        password: '',
        confirmPassword: '',
        rol: user ? getDataFormatedForSelects(user.roles, 'id', 'display_name') : []
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255, 'El nombre no debe superar los 255 caracteres').required('El nombre es requerido'),
        lastname: Yup.string().max(255, 'El apellido no debe superar los 255 caracteres').required('El apellido es requerido'),
        username: Yup.string().max(255, 'El nombre de usuario no debe superar los 255 caracteres').required('El nombre de usuario es requerido'),
        email: Yup.string().email('Ingrese un correo electrónico').max(255, 'El correo electrónico no debe superar los 255 caracteres').required('El correo electrónico es requerido'),
        password: user ? Yup.string().max(255) : Yup.string().max(255, 'La contraseña no debe superar los 255 caracteres').required('La contraseña es requerida'),
        confirmPassword: user ? Yup.string().max(255, 'La contraseña no debe superar los 255 caracteres').test('passwords-match', 'Las contraseñas deben ser iguales', function (value) {
          return this.parent.password === value;
        }) : Yup.string().max(255, 'La confirmacion de contraseña no debe superar los 255 caracteres').required('La confirmación de contraseña es requerida').test('passwords-match', 'Las contraseñas deben ser iguales', function (value) {
          return this.parent.password === value;
        }),
        rol: Yup.array().nullable().required("El rol es requerido")
      })}
      onSubmit={async (data, actions) => {
        const newData = {
          username: data.username,
          name: data.name,
          lastname: data.lastname,
          email: data.email,
          password: data.password,
          password_confirmation: data.confirmPassword,
          roles: getIdsFromDataSelects(data.rol, 'value')
        }
        if (user) {
          await updateUser(newData, user.id)
        } else {
          await addUser(newData)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        isSubmitting,
        touched,
        values
      }) => (
        <Form>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Datos personales</Typography>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Nombre"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.lastname && errors.lastname)}
                fullWidth
                helperText={touched.lastname && errors.lastname}
                label="Apellido"
                margin="normal"
                name="lastname"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.lastname}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.username && errors.username)}
                fullWidth
                helperText={touched.username && errors.username}
                label="Nombre de usuario"
                margin="normal"
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.username}
                variant="outlined"
                disabled={editProfile ? true : false}
              />
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Correo electrónico"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Contraseña</Typography>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Contraseña"
                margin="normal"
                name="password"
                autoComplete="new-password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                label="Confirmación de contraseña"
                margin="normal"
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.confirmPassword}
                variant="outlined"
              />
              {
                editProfile
                  ?
                  <TextField
                    fullWidth
                    label="Rol"
                    margin="normal"
                    type="text"
                    value={getTextRoles(user.roles)}
                    variant="outlined"
                    disabled
                  />
                  :
                  <FormControl fullWidth margin="normal" error={Boolean(touched.rol && errors.rol)} >
                    <SelectField
                      placeholder="Selecciona roles..."
                      name="rol"
                      getOptions={getRolesOptions}
                      label="Rol"
                      isMulti
                      setFieldValue={(value)=>{
                        setFieldValue('rol', value)
                      }}
                      value={values.rol}
                    />
                    {/* <InputLabel id="label-rol">Rol</InputLabel>
                    <Select
                      labelId="label-rol"
                      name="rol"
                      label="Rol"
                      multiple
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.rol}
                      fullWidth
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => {
                        return (
                          <div className={classes.chips}>
                            {selected.map((value) => {
                              return (
                                <Chip className={classes.chip} key={value} label={roles.find(e => e.id === value).display_name} />
                              )
                            })}
                          </div>
                        )
                      }}
                    >
                      {
                        roles.map(r => {
                          return (
                            <MenuItem key={r.id} value={r.id}>{r.display_name}</MenuItem>
                          )
                        })
                      }
                    </Select> */}
                    <FormHelperText className="text-danger">{(touched.rol && errors.rol) && errors.rol}</FormHelperText>

                  </FormControl>
              }


            </Grid>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={theme}>
                <Link to={routesApp.users.path}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
                </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default FormUser
