import React from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router';
// import { NotificationManager } from 'react-notifications';
import { routesApp } from 'src/routesService';
import { validateOnePermission } from 'src/helpers/permissions';
import { PAGINAS_INSTITUCIONALES_PERMISSIONS } from 'src/utils/constants/permissions';
import DeleteItem from 'src/components/shared/DeleteItem';
import { generateTemporalPreview } from 'src/actions/paginasInstitucionalesActions';
// import PaginasInstitucionalesService from '../../../services/PaginasInstitucionalesService';

const ITEM_HEIGHT = 48;

function Actions({ authPermissions, pagina, deletePagina, preview, refresh }) {
  const { id: pageId, title: pageTilte} = pagina;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const history = useHistory();

  // const previewURL = `${process.env.REACT_APP_JUANAMANSO_REA}preview/pagina-institucional/${pageId}`;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {
          validateOnePermission(authPermissions, PAGINAS_INSTITUCIONALES_PERMISSIONS.EDIT_PAGINAS)
          && (
            <MenuItem
              onClick={() => {
                history.push(routesApp.editPaginasInstitucionales.path.replace(':paginaId', pageId));
              }}
            >
              Editar
            </MenuItem>
          )
        }
        <MenuItem onClick={() => preview(pagina)}
        >
          Previsualizar
        </MenuItem>
        {
          validateOnePermission(authPermissions, PAGINAS_INSTITUCIONALES_PERMISSIONS.DELETE_PAGINAS)
          && (
            <DeleteItem
              deleteAction={deletePagina}
              item={pageId}
              message={`¿Deseas eliminar la página "${pageTilte}"?`}
              title="Eliminar Página"
            />
          )
        }
      </Menu>
    </div>
  );
}

const mapDispatchToProps = {
  preview: generateTemporalPreview
};

export default connect(null, mapDispatchToProps)(Actions);
