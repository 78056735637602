import React from 'react';
import { useHistory } from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { routesApp } from 'src/routesService';
import PortadasService from 'src/services/PortadasService';
import DeleteItem from 'src/components/shared/DeleteItem';

const ITEM_HEIGHT = 48;

export default function Actions(props) {
  const {
    portada, hasPermission, publicarPortada, deletePortada
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const history = useHistory();

  const handleClick = (event) => {
    if (!loading) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDelete(item) {
    setLoading(true);
    handleClose();
    deletePortada(item);
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '24ch',
          },
        }}
      >
        {hasPermission.view && (
          <MenuItem onClick={() => {
            history.push(routesApp.componentes.path.replace(':portadaId', portada.id));
          }}
          >
            Administrar componentes
          </MenuItem>
        )}
        {hasPermission.view && (
          <MenuItem onClick={async () => {
            await PortadasService.generatePreviewPortada(portada.id);
            window.open(`${process.env.REACT_APP_JUANAMANSO_REA}preview/portada/${portada.id}`, '_blank', 'noopener, noreferrer');
          }}
          >
            Previsualizar
          </MenuItem>
        )}
        {hasPermission.edit && (
          <MenuItem onClick={() => {
            setLoading(true);
            handleClose();
            publicarPortada(portada.id);
          }}
          >
            Publicar
          </MenuItem>
        )}
        {hasPermission.edit && (
          <MenuItem onClick={() => {
            history.push(routesApp.editPortada.path.replace(':portadaId', portada.id));
          }}
          >
            Editar
          </MenuItem>
        )}
        {hasPermission.delete && (
          <DeleteItem
            deleteAction={handleDelete}
            item={portada.id}
            message={`¿Deseas eliminar la portada "${portada.name}"?`}
            title="Eliminar Portada"
          />
        )}
      </Menu>
    </div>
  );
}
