import React from 'react';
import { Chip } from '@material-ui/core';
import { PORTADAS_STATES } from 'src/utils/constants/api';

/**
 * Chip para el estado una portada
 */
export const FrontPageStatus = ({ state }) => {
  return (
    <Chip
      label={state.display_name}
      style={{
        color: 'white',
        backgroundColor: state.id === PORTADAS_STATES.PUBLICADO ? 'green' : 'red',
        width: 'fit-content'
      }}
    />
  )
}
