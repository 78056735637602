import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import NavBar from './NavBar';
import DashboardLayoutTopBar from './DashboardLayoutTopBar';

import { logout } from '../../actions/authActions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    padding: '1rem'
  }
}));

const DashboardLayout = (props) => {
  const { user, children } = props;

  const [navOpen, setNavOpen] = useState(false);

  const classes = useStyles();

  const toggleNavOpen = () => {
    setNavOpen(!navOpen);
  };

  const handleLogout = () => {
    props.logout();
  };

  return (
    <div className={classes.root}>
      <DashboardLayoutTopBar
        navOpen={navOpen}
        toggleNavOpen={toggleNavOpen}
        onNavOpen={() => setNavOpen(true)}
        logout={handleLogout}
      />
      <NavBar
        onNavClose={() => setNavOpen(false)}
        navOpen={navOpen}
        toggleNavOpen={toggleNavOpen}
        user={user.data}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  return {
    user: auth
  };
};

const mapDispatchToProps = {
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
