import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  createTheme,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  ThemeProvider
} from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

import SelectField from 'src/components/shared/SelectField';
import { routesApp } from 'src/routesService';
import { URLRegex } from 'src/helpers/random';
import { TAMANIOS_COMPONENTES_OPTIONS } from 'src/utils/constants/api';
import { getOptionsSize } from 'src/helpers/components';

const themeButton = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) => ({
  color: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const FormEmbebido = (props) => {
  const {
    componentType,
    component,
    loading,
    filas,
    addFila,
    updateComponent,
    addComponent,
  } = props;

  const classes = useStyles();
  const match = useRouteMatch();

  const [maxSize, setMaxSize] = useState(component ? getOptionsSize(component.col) : []);

  return (
    <Formik
      initialValues={{
        fila: component ? { label: component.row, value: component.row } : '',
        columna: component ? { label: component.col, value: component.col } : '',
        tamanio: component ? { label: component.size, value: component.size } : '',
        embedded: component?.embedded,
        url: component?.url ?? '',
      }}
      validationSchema={Yup.object().shape({
        fila: Yup.string().required('El número de fila es requerido'),
        columna: Yup.string().required('El número de columna es requerido'),
        tamanio: Yup.string().required('El tamaño es requerido'),
        embedded: Yup.string().required('El texto embebido es requerido.'),
        url: Yup.string().nullable()
          .test(
            'valid-url',
            'La URL ingresada es inválida',
            (str) => ((str === null || str === undefined || str === '') ? true : !!URLRegex.test(str))
          ),
      })}
      onSubmit={async (data) => {
        data = {
          ...data,
          ...{
            front_page_id: match.params.portadaId,
            component_type_id: componentType.value,
            row: data.fila.value,
            col: data.columna.value,
            size: data.tamanio.value,
          }
        };

        if (component) {
          await updateComponent(data, component.id)
        } else {
          await addComponent(data)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        // setFieldTouched,
        touched,
        values
      }) => (
        <Form style={{ width: '100%' }}>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <FormControl margin="normal" fullWidth error={Boolean(touched.fila && errors.fila)}>
                <SelectField
                  id="fila-titulo"
                  name="fila"
                  placeholder="Seleccione una fila..."
                  getOptions={() => filas}
                  label="Fila"
                  setFieldValue={(value) => {
                    setFieldValue('fila', value);
                    setFieldValue('columna', '');
                    setFieldValue('tamanio', '');
                  }}
                  value={values.fila}
                  addNewOption
                  addOption={() => addFila()}
                />
                <FormHelperText className="text-danger">{(touched.fila && errors.fila) && errors.fila}</FormHelperText>
              </FormControl>
              <FormControl margin="normal" fullWidth error={Boolean(touched.columna && errors.columna)}>
                <SelectField
                  id="columna-titulo"
                  name="columna"
                  placeholder="Seleccione una columna..."
                  getOptions={() => TAMANIOS_COMPONENTES_OPTIONS}
                  label="Columna"
                  setFieldValue={(value) => {
                    setFieldValue('columna', value);
                    setFieldValue('tamanio', '');
                    setMaxSize(getOptionsSize(value.value))
                  }}
                  value={values.columna}
                />
                <FormHelperText className="text-danger">{(touched.columna && errors.columna) && errors.columna}</FormHelperText>
              </FormControl>
              <FormControl margin="normal" fullWidth error={Boolean(touched.tamanio && errors.tamanio)}>
                <SelectField
                  id="tamanio-titulo"
                  name="tamanio"
                  placeholder="Seleccione un tamaño..."
                  getOptions={() => maxSize}
                  label="Tamaño"
                  setFieldValue={(value) => {
                    setFieldValue('tamanio', value);
                  }}
                  value={values.tamanio}
                />
                <FormHelperText className="text-danger">{(touched.tamanio && errors.tamanio) && errors.tamanio}</FormHelperText>
              </FormControl>
              <FormControl margin="normal" fullWidth error={Boolean(touched.embedded && errors.embedded)}>
                <TextField
                  error={Boolean(touched.embedded && errors.embedded)}
                  fullWidth
                  helperText={touched.embedded && errors.embedded}
                  id="visitor_register"
                  name="embedded"
                  label="Código"
                  multiline
                  rows={24}
                  value={values.embedded}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormHelperText className="text-danger">{(touched.embedded && errors.embedded) && errors.embedded}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <TextField
                error={Boolean(touched.url && errors.url)}
                fullWidth
                helperText={touched.url && errors.url}
                label="Enlace"
                margin="normal"
                name="url"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.url}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={themeButton}>
                <Link to={routesApp.componentes.path.replace(':portadaId', match.params.portadaId)}>
                  <Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button>
                </Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default FormEmbebido;
