import { showDeveloperMessageConsole } from 'src/helpers/random';
import { ROLE_TYPES } from './types';
import RolesService from '../services/RolesService';
import { routesApp } from 'src/routesService';
import { NotificationManager } from 'react-notifications';

export const getAllRoles = (filters='') => async (dispatch) => {
  dispatch({
    type: ROLE_TYPES.GET_ROLES
  });
  try {
    const result = await RolesService.getRoles(filters);
    dispatch({
      type: ROLE_TYPES.GET_ROLES_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: ROLE_TYPES.GET_ROLES_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener los roles: ', error);
  }
};

export const storeRol = (id, data, history) => async dispatch => {
  dispatch({
    type: ROLE_TYPES.STORE_ROL
  })
  try {
    if (id) {
      await RolesService.updateRol(data, id);
      NotificationManager.success("Rol actualizado exitosamente")
    } else {
      await RolesService.addRol(data);
      NotificationManager.success("Rol creado exitosamente")
    }
    
    dispatch({
      type: ROLE_TYPES.STORE_ROL_SUCCESS
    })

    //redirigir
    history.push(routesApp.roles.path)
  } catch (error) {
    dispatch({
      type: ROLE_TYPES.STORE_ROL_FAILURE,
      payload:error.response ? error.response.data.errors : null
    })
    NotificationManager.error("Error al guardar el rol.")

    showDeveloperMessageConsole("Error al guardar rol: ", error)
  }
}

export const getOneRol = (id) => async dispatch => {
  dispatch({
    type: ROLE_TYPES.GET_ONE_ROL
  })
  try {
    const result=await RolesService.getOneRol(id);
    dispatch({
      type: ROLE_TYPES.GET_ONE_ROL_SUCCESS,
      payload:result.data
    })
  } catch (error) {
    dispatch({
      type: ROLE_TYPES.GET_ONE_ROL_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener un rol: ", error)
  }
}
