import React from 'react';
import { Box, Button, Card, CardContent, Container, FormControl, FormHelperText, Grid, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Save as SaveIcon } from '@material-ui/icons';
//TODAVIA NO SE IMPLEMENTA
//import { validateOnePermission } from 'src/helpers/permissions';
//import { VISITOR_REGISTER_PERMISSIONS } from 'src/utils/constants/permissions';
import Page from '../Page';
import ErrorsValidation from '../shared/displayErrorsValidations';
import TextField from '@material-ui/core/TextField';

const VisitorRegister = (props) => {
  const {
    code,
    errors,
    loading,
    updateVisitorRegister,
  } = props;

  return (
    <Page
      className="root-pages"
      title={`Registro Visitas`}
    >
      <Container maxWidth={false}>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            color="textPrimary"
            variant="h2"
            className="mb-3"
          >
            Registro Visitas
          </Typography>
        </Box>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <Formik
              key={code}
              initialValues={{
                code: code ? code : '',
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string()
              })}
              onSubmit={async (data, actions) => {
                const newData = {
                  code: data.code,
                }
                await updateVisitorRegister(newData);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                touched,
                values
              }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={12} md={12} className="pr-3 pl-3 pt-2">
                      <FormControl margin="normal" fullWidth error={Boolean(touched.code && errors.code)}>
                          <TextField
                          error={Boolean(touched.code && errors.code)}
                          fullWidth
                          helperText={touched.code && errors.code}
                          id="visitor_register"
                          name="code"
                          label="Código"
                          multiline
                          rows={24}
                          value={values.code}
                          onBlur={handleBlur}
                          onChange={handleChange}
                         />
                        <FormHelperText className="text-danger">{(touched.code && errors.code) && errors.code}</FormHelperText>
                      </FormControl>
                    </Grid>
                    { 
                      /* validateOnePermission(auth.permissions, VISITOR_REGISTER_PERMISSIONS.EDIT_VISITOR_REGISTER) && */
                       <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
                        <Button
                          color="primary"
                          disabled={loading}
                          type="submit"
                          variant="contained"
                          startIcon={<SaveIcon />}
                        >
                          Guardar
                        </Button>
                      </Grid>
                    }
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default VisitorRegister;
