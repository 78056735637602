import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router';
import { routesApp } from 'src/routesService';
import DeleteItem from 'src/components/shared/DeleteItem';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import NewsPagesService from 'src/services/NewsPagesService';
import { showDeveloperMessageConsole, stripHTML } from 'src/helpers/random';

const ITEM_HEIGHT = 48;

function Actions({
  pagina, deletePagina, hasPermission
}) {
  const match = useRouteMatch();
  const { id: pageId, title: pageTilte } = pagina;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const history = useHistory();

  // const previewURL = `${process.env.REACT_APP_JUANAMANSO_REA}preview/noticia/${pageId}`;

  async function preview(page) {
    const { response } = await NewsPagesService.temporalPreview({
      body: page.body,
      title: page.title
    }).then((res) => res.data).catch((error) => {
      showDeveloperMessageConsole('Error al generar el archivo JSON de las paginas institucionales: ', error);
    });

    window.open(`${process.env.REACT_APP_JUANAMANSO_REA}preview/noticia/${response.id}`, '_blank', 'noopener, noreferrer');
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {
          hasPermission.edit
          && (
            <MenuItem
              onClick={() => {
                history.push(`${match.path}/${routesApp.newsPage.children.edit.path.replace(':paginaId', pageId)}`);
              }}
            >
              Editar
            </MenuItem>
          )
        }
        <MenuItem onClick={() => preview(pagina)}>
          Previsualizar
        </MenuItem>
        {
          hasPermission.delete
          && (
            <DeleteItem
              deleteAction={deletePagina}
              item={pageId}
              message={`¿Deseas eliminar la página "${stripHTML(pageTilte)}"?`}
              title="Eliminar Página"
            />
          )
        }
      </Menu>
    </div>
  );
}

export default Actions;
