import React from 'react'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import Page from '../Page'
import { FORM_ACTIONS } from 'src/utils/constants/general'
import ErrorsValidation from '../shared/displayErrorsValidations'
import FormAgrupador from './shared/form'
import PageContainer from 'src/containers/shared/PageContainer';

const AddAgrupador = (props) => {
  const {
    errors,
    loading,
    addAgrupador,
  } = props;

  return (
    <PageContainer title="Agregar Agrupador" maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          Agregando Agrupador
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <FormAgrupador
              action={FORM_ACTIONS.ADD}
              addAgrupador={addAgrupador}
              loading={loading}
            />
          </CardContent>
        </Card>
    </PageContainer>
  )
}

export default AddAgrupador
