export const validatePermissions = (permisosUser, permisos) => {
  let tienePermiso = false
  // eslint-disable-next-line
  Object.keys(permisos).forEach(permiso => {
    if (permisosUser.some((e) => e.name === permisos[permiso])) {
      tienePermiso = true
    }
  })

  return tienePermiso
}

export const validateOnePermission = (permisosUser, permiso) => {
  return permisosUser.some((e) => e.name === permiso)
}

export const checkPermissions = (permisosUser, permisos = []) => {
  return permisosUser.some((p) => permisos.includes(p.name))
}
