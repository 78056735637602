import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Page from 'src/components/Page';
import { routes } from 'src/routesService';
import ErrorsValidation from '../shared/displayErrorsValidations';
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ResetPassword = (props) => {
  const classes = useStyles();
  const {
    errorsPassword,
    resetPassword,
  } = props;

  const [cambiada, setCambiada] = useState(false)
  
  return (
    <Page
      className={classes.root}
      title="Restaurar contraseña"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          {
            cambiada
              ?
              <Box
                mb={5}
                display="inline-block"
                justifyContent="center"
                textAlign="center"
              >
                <CheckCircleIcon htmlColor="#00FF00" className="mb-3" style={{ fontSize: "5rem" }} />
                <Typography
                  color="textPrimary"
                  variant="h2"
                  className="text-center mb-3"
                >
                  ¡Contraseña actualizada correctamente!
                </Typography>

                <PrimaryLinkButton to={routes.login.path} size="large">
                  Ir al login
                </PrimaryLinkButton>
              </Box>
              :
              <Formik
                initialValues={{
                  password: '',
                  confirmPassword:''
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string().max(255, 'La contraseña no debe superar los 255 caracteres').required('La contraseña es requerida'),
                  confirmPassword: Yup.string().max(255, 'La confirmación de contraseña no debe superar los 255 caracteres').required('La confirmación de contraseña es requerida').test('passwords-match', 'Las contraseñas deben ser iguales', function(value) {
                    return this.parent.password === value;
                  })
                })}
                onSubmit={(data, actions) => {
                  resetPassword(data.password, data.confirmPassword, setCambiada, actions.setSubmitting)
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb={3}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Typography
                          color="textPrimary"
                          variant="h2"
                          className="text-center"
                        >
                          Cambiar contraseña
                        </Typography>
                      </Box>

                      <ErrorsValidation errors={errorsPassword} />
                      
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Contraseña"
                        margin="normal"
                        autoComplete="new-password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        fullWidth
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        label="Confirmación de contraseña"
                        margin="normal"
                        name="confirmPassword"
                        autoComplete="new-password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.confirmPassword}
                        variant="outlined"
                      />
                      <Box my={2}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Restaurar
                      </Button>
                      </Box>
                    </form>
                  )}
              </Formik>
          }
        </Container>
      </Box>
    </Page>
  );
};

export default ResetPassword;
