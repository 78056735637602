/* eslint-disable camelcase */
import moment from 'moment';

export const convertDate = (date, currentFormat, incomingFormat) => {
  moment.locale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),

  })
  return moment(date, currentFormat).format(incomingFormat)
}

/**
 * Function to return message console for only developers
 */
export function showDeveloperMessageConsole(message, vars = null) {
  if (process.env.NODE_ENV === 'development') {
    if (vars) {
      console.log(message, vars)
    } else {
      console.log(message)
    }
  }
}

/**
 * Function to return data for selects
 */
export function getDataFormatedForSelects(data, propertyId, propertyLabel) {
  const aux = []
  // eslint-disable-next-line
  data.map(d => {
    aux.push({
      label: d[propertyLabel],
      value: d[propertyId]
    })
  })
  return aux
}

/**
 * Function to return ids
 */
export function getIdsFromData(data) {
  const aux = []
  // eslint-disable-next-line
  data.map(d => {
    aux.push(d.id)
  })
  return aux
}

/**
 * Function to return ids from selects
 */
export function getIdsFromDataSelects(data, property) {
  const aux = []
  // eslint-disable-next-line
  data.map(d => {
    aux.push(d[property])
  })
  return aux
}

/**
 * Function to return slug from id
 */
export function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export function slug_to_string(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.replace(/[-]/g, ' ') // replace - by whitespace
  str = str.split(' ').map((substr) => {
    if(substr.length > 0)
        return substr[0].toUpperCase() + substr.substring(1);
    return "";
  }).join(' ');
  // str = str.toLowerCase();
  return str;
}

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) { byteString = atob(dataURI.split(',')[1]); } else { byteString = unescape(dataURI.split(',')[1]); }
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

export function base64toBlob(base64Data, contentType) {
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function getDataSelected(lista, search, value, display) {
  const obj = lista.find((o) => o[search] === value);
  let a = '';
  let b = '';
  if (obj) {
    a = obj[search];
    b = obj[display];
  }
  return { value: a, label: b };
}

export function allPublished(componentes) {
  return componentes.every((c) => c.state === 'publicado');
}

export function countCharacters(document) {
  const rootElement = document.getRoot();

  return countCharactersInElement(rootElement);

  // Returns length of the text in specified `node`
  //
  // @param {module:engine/model/node~Node} node
  // @returns {Number}
  function countCharactersInElement(node) {
    let chars = 0;

    for (const child of node.getChildren()) {
      if (child.is('text')) {
        chars += child.data.length;
      } else if (child.is('element')) {
        chars += countCharactersInElement(child);
      }
    }

    return chars;
  }
}

export function arrayMax(arr, field) {
  let len = arr.length; let
    max = 0;
  while (len--) {
    if (arr[len][field] > max) {
      max = arr[len][field];
    }
  }
  return max;
}

export const selectFileWithCKFinder = (setFieldValue, name) => {
  window.CKFinder.modal({
    chooseFiles: true,
    resourceType: 'Images',
    width: 800,
    height: 600,
    onInit(finder) {
      finder.config.connectorPath = `${process.env.REACT_APP_BASE_URL_API}/ckfinder/connector`
      finder.on('files:choose', (evt) => {
        const file = evt.data.files.first();
        // var output = document.getElementById(elementId);
        setFieldValue(name, file.getUrl());
      });

      finder.on('file:choose:resizedImage', (evt) => {
        // var output = document.getElementById(elementId);
        setFieldValue(name, evt.data.resizedUrl);
      });
    }
  });
}

export function ordernarXMenor(a, b, field) {
  if (a[field] > b[field]) {
    return 1;
  }
  if (a[field] < b[field]) {
    return -1;
  }
  return 0;
}

export function createListFromArray(arr, field) {
  const newList = [];
  arr.forEach((a) => {
    if (a[field] && !newList.includes(a[field])) { newList.push(a[field]); }
  });
  return newList.sort();
}

export const URLRegex = new RegExp(
  '^(https?:\\/\\/)' // protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
  + '((\\d{1,3}\\.){3}\\d{1,3})|' // OR ip (v4) address
  + '(localhost))' // OR localhost
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
  + '(\\?[\\/:;&a-z\\d%_.~+=-]*)?' // query string
  + '(\\#[-a-z\\d_]*)?$', 'i' // fragment locator
);

const decodeEntities = (function () {
  // this prevents any overhead from creating the object each time
  const element = document.createElement('div');

  function decodeHTMLEntities(str) {
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }

    return str;
  }

  return decodeHTMLEntities;
}());

export const stripHTML = (string) => {
  const htmlEl = document.createElement('html');
  htmlEl.innerHTML = string;
  return htmlEl.innerHTML.replace(/<\/?[^>]+(>|$)/g, '').replace(/(&[AaEeIiOoUu]acute;)/g, (match) => decodeEntities(match)).replace(/(&[AaEeIiOoUu]grave;)/g, (match) => decodeEntities(match))
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
};
