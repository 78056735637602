import {
    CONTACT_FORM_TYPES
  } from '../actions/types';
  
  const INIT_STATE = {
    data: null,
    errors: null,
    loading: false
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {

      case CONTACT_FORM_TYPES.GET_CONTACT:
        return {
          ...state,
          loading: true
        }
      case CONTACT_FORM_TYPES.GET_CONTACT_SUCCESS:
        return {
          ...state,
          data: action.payload,
          loading: false
        }
      case CONTACT_FORM_TYPES.GET_CONTACT_FAILURE:
        return {
          ...state,
          data: null,
          loading: false
        }
      case CONTACT_FORM_TYPES.STORE_TEXT_FORM:
        return {
          ...state,
          loading: true
        }
      case CONTACT_FORM_TYPES.STORE_TEXT_FORM_SUCCESS:
        return {
          ...state,
          errors:null,
          loading: false
        }
      case CONTACT_FORM_TYPES.STORE_TEXT_FORM_FAILURE:
        return {
          ...state,
          errors: action.payload,
          loading: false
        }
      default: return { ...state };
    }
  }
  