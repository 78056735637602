import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { NotificationManager } from 'react-notifications'

import { getAllRoles, getOneRol, storeRol } from 'src/actions/rolesActions'
import { getAllPermissions } from 'src/actions/permissionsActions'
import Loading from 'src/components/shared/loading'
import { routesApp } from 'src/routesService'
import { showDeveloperMessageConsole } from 'src/helpers/random'
import RolesService from './../services/RolesService'
import Roles from 'src/components/roles/Roles'
import AddRol from 'src/components/roles/AddRol'
import EditRol from 'src/components/roles/EditRol'

const RolesContainer = (props) => {
  const { auth, permissions, roles, history, match, location } = props

  const handleAddRol = (data) => {
    props.storeRol(null, data, history)
  }

  const handleUpdateRol = (data, id) => {
    props.storeRol(id, data, history)
  }

  const handleDeleteRol = async (id) => {
    NotificationManager.info("Eliminando rol...", null, 1500)
    try {
      await RolesService.deleteRol(id);
      await props.getAllRoles()
      NotificationManager.success("Rol eliminado correctamente")

    } catch (error) {
      showDeveloperMessageConsole("Error al eliminar rol: ", error)
      NotificationManager.error("Ha ocurrido un error. Intente nuevamente.")
    }
  }

  useEffect(() => {
    props.getAllPermissions()
    if(match.path === routesApp.roles.path){
      props.getAllRoles(location.search)
    }else if(match.path === routesApp.editRol.path){
      props.getOneRol(match.params.rolId)
    }
    //eslint-disable-next-line
  }, [match.path, location.search])


  if ((match.path === routesApp.roles.path && roles.all === null) || permissions.all === null) {
    return <Loading />
  }

  if (match.path === routesApp.addRol.path) {
      return <AddRol
        permissions={permissions.all}
        errors={roles.errors}
        loading={roles.loading}
        addRol={handleAddRol}
      />
  }

  if (match.path === routesApp.editRol.path) {
    if (roles.one !== null && roles.one.id.toString() === match.params.rolId) {
      return <EditRol
        rol={roles.one}
        permissions={permissions.all}
        errors={roles.errors}
        loading={roles.loading}
        updateRol={handleUpdateRol}
      />
    } else {
      return <Loading />
    }
  }

  return (
    <Roles
      auth={auth.data}
      roles={roles.all}
      permissions={permissions.all}
      loading={roles.loading}
      deleteRol={handleDeleteRol}
    />
  )
}

const mapStateToProps = ({ auth, roles, permissions }) => {
  return {
    auth,
    roles,
    permissions
  };
};

const mapDispatchToProps = {
  getAllRoles,
  getAllPermissions,
  storeRol,
  getOneRol
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesContainer)
