import 'react-perfect-scrollbar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './assets/css/all.css';

import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/assets/theme';
import { NotificationContainer } from 'react-notifications';
import { Switch, Route } from 'react-router-dom';
import AppContainer from './containers/AppContainer';
import MainLayout from './layouts/MainLayout/MainLayout';
import './assets/css/custom.css';
import { showDeveloperMessageConsole } from './helpers/random';

const App = () => {
  useEffect(() => {
    showDeveloperMessageConsole('env', process.env)
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <NotificationContainer />
      <Switch>
        <Route path="/app" component={AppContainer} />
        <Route path="/" component={MainLayout} />
      </Switch>
    </ThemeProvider>
  );
};

export default App;
