/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  Card, CardContent, Typography
} from '@material-ui/core'

import { FORM_ACTIONS } from 'src/utils/constants/general'
import Loading from 'src/components/shared/loading'
import PageContainer from 'src/containers/shared/PageContainer'
import ErrorsValidation from 'src/components/shared/displayErrorsValidations'
import NewsPagesService from 'src/services/NewsPagesService'
import { selectUser } from 'src/reducers'

import FormNewsPage from './shared/FormNewsPage'

const NewsPagesOne = (props) => {
  const {
    action, errors, loading, pageStates, ...rest
  } = props

  const params = useParams();
  const user = useSelector(selectUser);

  const formParams = {
    [FORM_ACTIONS.ADD]: {
      name: 'Agregar',
      // eslint-disable-next-line no-unused-vars
      action: (id) => (data) => {
        return NewsPagesService.addPagina(data).then((r) => r.data).then((_data) => {
          rest.refreshTable().then(() => NotificationManager.success('Página creada exitosamente'))
        })
      },
    },
    [FORM_ACTIONS.EDIT]: {
      name: 'Editar',
      action: (id) => (data) => {
        return NewsPagesService.updatePagina(data, id).then((r) => r.data).then((_data) => {
          rest.refreshTable().then(() => NotificationManager.success('Página actualizada exitosamente'))
        })
      },
    },
    [FORM_ACTIONS.SHOW]: {
      name: 'Mostrar',
      action: undefined,
    },
  }

  const form = formParams[action];
  const formName = form.name;

  const [newsPage, setNewsPage] = useState(null);

  useEffect(() => {
    const { paginaId: id } = params;
    if (id) {
      if (pageStates) {
        NewsPagesService.getOnePagina(id)
          .then((r) => r.data)
          .then((data) => {
            setNewsPage(data)
          });
      }
    } else {
      setNewsPage({});
    }
  }, [params, setNewsPage, pageStates])

  if (!newsPage) {
    return <Loading />
  }

  return (
    <PageContainer title={`${formName} Página de Noticias`} maxWidth={false}>
      <Typography
        color="textPrimary"
        variant="h2"
        className="mb-3"
      >
        {`${formName.replace(/.$/, 'ndo')} Página de Noticias`}
      </Typography>
      <ErrorsValidation errors={errors} />
      <Card>
        <CardContent>
          <FormNewsPage
            action={form.action}
            states={pageStates}
            noticia={newsPage}
            loading={loading}
            // generateTemporalPreview={props.generateTemporalPreview}
            user={user}
          />
        </CardContent>
      </Card>
    </PageContainer>
  )
}

export default NewsPagesOne
