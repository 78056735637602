import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import Page from 'src/components/Page';
import { routes } from 'src/routesService';
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Password = (props) => {
  const {
    resetPassword,
  } = props;
  const classes = useStyles();

  const [emailEnviado, setEmailEnviado] = useState(false)
  
  return (
    <Page
      className={classes.root}
      title="Restaurar contraseña"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          {
            emailEnviado
              ?
              <Box
                mb={5}
                display="inline-block"
                justifyContent="center"
                textAlign="center"
              >
                <CheckCircleIcon htmlColor="#00FF00" className="mb-3" style={{ fontSize: "5rem" }} />
                <Typography
                  color="textPrimary"
                  variant="h2"
                  className="text-center mb-3"
                >
                  Correo de restablecimento de contraseña enviado
                </Typography>
                <PrimaryLinkButton to={routes.login.path} size="large">
                  Volver al ingreso
                </PrimaryLinkButton>
              </Box>
              :
              <Formik
                initialValues={{
                  email: ''
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('Ingrese un correo electrónico').max(255, 'El correo electrónico no debe superar los 255 caracteres').required('El correo electrónico es requerido'),
                })}
                onSubmit={(data) => {
                  resetPassword(data.email, setEmailEnviado)
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched
                }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb={3}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Typography
                          color="textPrimary"
                          variant="h2"
                          className="text-center"
                        >
                          Olvidé mi contraseña
                        </Typography>
                      </Box>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                        className="text-center"
                      >
                        Te enviaremos un mensaje a tu correo electrónico registrado para que puedas ingresar una contraseña nueva
                      </Typography>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Correo electrónico"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        variant="outlined"
                      />
                      <Box my={2}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Restaurar
                      </Button>
                      </Box>
                    </form>
                  )}
              </Formik>
          }
        </Container>
      </Box>
    </Page>
  );
};

export default Password;
