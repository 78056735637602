import React from 'react';
import { Box, Container } from '@material-ui/core';

import Page from '../Page';
import TablePermissions from './shared/tablePermissions';
import BarraBusquedaPermissions from './shared/barraBusquedaPermissions';
import PageContainer from 'src/containers/shared/PageContainer';

const Permissions = (props) => {
  const { permissions, loading } = props;

  return (
    <PageContainer title="Permisos" maxWidth={false}>
        <BarraBusquedaPermissions />
        <Box mt={3}>
          <TablePermissions
            data={permissions}
            loading={loading}
          />
        </Box>
    </PageContainer>
  );
};

export default Permissions;
