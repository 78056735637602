import React from 'react';
import { Box, Container } from '@material-ui/core';

import { routesApp } from 'src/routesService';
import { validateOnePermission } from 'src/helpers/permissions';
import { AGRUPADORES_PERMISSIONS } from 'src/utils/constants/permissions';
import Page from '../Page';
import TableAgrupadores from './shared/tableAgrupadores';
import BarraBusquedaAgrupadores from './shared/barraBusquedaAgrupadores';
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';
import PageContainer from 'src/containers/shared/PageContainer';

const Agrupadores = (props) => {
  const {
    agrupadores,
    loading,
    auth,
    getAllAgrupadores,
    deleteAgrupador,
  } = props;

  return (
    <PageContainer title="Agrupadores" maxWidth={false}>
        {
          validateOnePermission(auth.permissions, AGRUPADORES_PERMISSIONS.VIEW_AGRUPADOR) && (
          <Box
            display="flex"
            justifyContent="space-between"
          >
            <PrimaryLinkButton to={routesApp.addAgrupador.path}>
              + Añadir Agrupador
            </PrimaryLinkButton>
          </Box>
          )
        }
        <BarraBusquedaAgrupadores />
        <Box mt={3}>
          <TableAgrupadores
            auth={auth}
            data={agrupadores.data}
            currentPage={agrupadores.current_page}
            itemsCountPerPage={agrupadores.per_page}
            totalItemsCount={agrupadores.total}
            nextPage={agrupadores.next_page_url}
            prevPage={agrupadores.prev_page_url}
            lastPage={agrupadores.last_page}
            loading={loading}
            getAllAgrupadores={getAllAgrupadores}
            deleteAgrupador={deleteAgrupador}
          />
        </Box>
    </PageContainer>
  );
};

export default Agrupadores;
