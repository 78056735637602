import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  createTheme,
  Grid,
  makeStyles,
  TextField,
  ThemeProvider,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Switch
} from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

import { routesApp } from 'src/routesService';
import { getDataFormatedForSelects, dataURItoBlob, URLRegex } from 'src/helpers/random';
// import FileInput from 'src/components/shared/Forms/FileInput';
import SelectField from 'src/components/shared/SelectField';
import EditorImage from 'src/components/shared/EditorImage';

const muiTheme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) => ({
  color: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const linkTypes = {
  URL: 'url',
  INSTITUTIONAL: 'institutional',
  FRONT_PAGE: 'front_page'
};

const MenuForm = ({
  menuUsr,
  loading,
  institucionales,
  portadas,
  submitAction
}) => {
  const classes = useStyles();
  const [linkType, setLinkType] = useState(menuUsr.link_type);
  const [touchedIcon, setTouchedIcon] = useState(false);
  const editorRefIcon = React.createRef();

  function formatSelect(menu, pages) {
    if (pages) {
      const pageId = menu.institutional_page ? menu.institutional_page.id : menu.front_page.id;
      const page = pages.find((p) => p.id === pageId);
      return { value: page.id, label: page.link };
    }
    return { value: 0, label: 'NULL' };
  }

  function handleChangeType(values, type) {
    values.link_type = type;
    values.link = '';
    setLinkType(type);
  }
  function getInstitucionales() {
    if (institucionales) {
      return getDataFormatedForSelects(institucionales, 'id', 'link');
    }

    return {
      label: '',
      value: []
    }
  }

  function getPortadas() {
    if (portadas) {
      return getDataFormatedForSelects(portadas, 'id', 'link');
    }

    return {
      label: '',
      value: []
    }
  }

  async function handleSubmit(values, formikBag) {
    const data = {
      name: values.name,
      order: values.order,
      active: values.active ? 1 : 0,
      link_type: values.link_type,
      target: values.target ? 'blank' : 'self'
    };
    switch (linkType) {
      case linkTypes.URL:
        data.link = values.link;
        break;
      case linkTypes.INSTITUTIONAL:
        data.institutional_page_id = values.institutional ? values.institutional.value : null;
        break;
      case linkTypes.FRONT_PAGE:
        data.front_page_id = values.portada ? values.portada.value : null;
        break;
      default:
        console.log('Error link type');
    }

    const formData = new FormData();
    Object.entries({ ...data })
      .forEach(([key, item]) => {
        formData.append(key, item);
      });

    if (typeof values.icon !== 'string' && !(values.icon instanceof String)) {
      if (values.icon.type === 'image/svg+xml' && !touchedIcon) {
        formData.append('icon', values.icon);
      } else {
        const canvas = editorRefIcon.current.getImageScaledToCanvas();
        const blob = dataURItoBlob(canvas.toDataURL());
        formData.append('icon', blob);
      }
    }

    await submitAction(formData, menuUsr.id);
  }

  return (
    <Formik
      initialValues={{
        name: menuUsr.name ?? '',
        order: menuUsr.order ?? '',
        link: menuUsr.link ?? '',
        institutional: menuUsr.institutional_page ? formatSelect(menuUsr, institucionales) : '',
        portada: menuUsr.front_page ? formatSelect(menuUsr, portadas) : '',
        active: Boolean(menuUsr.active),
        link_type: menuUsr.link_type ?? 'url',
        icon: menuUsr.icon ? `${process.env.REACT_APP_BASE_URL_STORAGE}${menuUsr.icon}` : '',
        target: menuUsr.target === 'blank'
      }}
      validationSchema={
        Yup.object().shape({
          name: Yup.string().max(25, 'El nombre no debe superar los 25 caracteres').required('El nombre es requerido'),
          order: Yup.number().required('El orden es requerido'),
          link: linkType === linkTypes.URL
            && Yup.string()
              .max(255, 'El URL no debe superar los 255 caracteres')
              .required('El url es requerido')
              .test('valid-url', 'La URL ingresada es inválida', (str) => !!URLRegex.test(str)),
          institutional: linkType === linkTypes.INSTITUTIONAL && Yup.string().nullable().required('Debe seleccionar una página institucional'),
          portada: linkType === linkTypes.FRONT_PAGE && Yup.string().nullable().required('Debe seleccionar una portada'),
          active: Yup.boolean().required('El estado es requerido'),
          target: Yup.string().required('Abrir nueva pestaña es requerido.')
        })
      }
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }) => (
        <Form>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">General</Typography>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Nombre*"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.name}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.order && errors.order)}
                fullWidth
                helperText={touched.order && errors.order}
                label="Orden"
                margin="normal"
                name="order"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.order}
                variant="outlined"
              />
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Estado</Typography>
              <FormControl fullWidth>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={values.active}
                      onChange={handleChange}
                      name="active"
                      color="primary"
                    />
                  )}
                  label="Activo"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Enlace</Typography>

              <FormControl fullWidth margin="normal" style={{ marginBottom: 6 }}>
                <FormLabel component="legend">Tipo Enlace</FormLabel>
                <RadioGroup row aria-label="gender" name="tipoEnlace" value={linkType} onChange={handleChange}>
                  <FormControlLabel value={linkTypes.URL} onChange={() => handleChangeType(values, linkTypes.URL)} control={<Radio />} label="URL" />
                  <FormControlLabel value={linkTypes.INSTITUTIONAL} onChange={() => handleChangeType(values, linkTypes.INSTITUTIONAL)} control={<Radio />} label="Páginas Institucionales" />
                  <FormControlLabel value={linkTypes.FRONT_PAGE} onChange={() => handleChangeType(values, linkTypes.FRONT_PAGE)} control={<Radio />} label="Portadas" />
                </RadioGroup>
              </FormControl>
              {
                linkType === linkTypes.URL && (
                  <TextField
                    error={Boolean(touched.link && errors.link)}
                    fullWidth
                    helperText={touched.link && errors.link}
                    label="Enlace*"
                    name="link"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.link}
                    variant="outlined"
                  />
                )
              }
              {
                linkType === linkTypes.INSTITUTIONAL && (
                  <FormControl
                    fullWidth
                    error={Boolean(touched.institutional && errors.institutional)}
                  >
                    <SelectField
                      placeholder="Seleccione enlace..."
                      name="institutional"
                      getOptions={getInstitucionales}
                      label="Enlace*"
                      setFieldValue={(value) => {
                        setFieldValue('institutional', value);
                      }}
                      value={values.institutional}
                    />
                    <FormHelperText className="text-danger">{(touched.institutional && errors.institutional) && errors.institutional}</FormHelperText>
                  </FormControl>
                )
              }
              {
                linkType === linkTypes.FRONT_PAGE && (
                  <FormControl
                    fullWidth
                    error={Boolean(touched.portada && errors.portada)}
                  >
                    <SelectField
                      placeholder="Seleccione enlace..."
                      name="portada"
                      getOptions={getPortadas}
                      label="Enlace*"
                      setFieldValue={(value) => {
                        setFieldValue('portada', value);
                      }}
                      value={values.portada}
                    />
                    <FormHelperText className="text-danger">{(touched.portada && errors.portada) && errors.portada}</FormHelperText>
                  </FormControl>
                )
              }

              <Typography color="textPrimary" variant="overline" className="font-weight-bold">Nueva pestaña</Typography>
              <FormControl fullWidth>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={values.target}
                      onChange={handleChange}
                      name="target"
                      color="primary"
                    />
                  )}
                  label="Nueva pestaña"
                />
              </FormControl>
            </Grid>

            <EditorImage
              label="Icono"
              editorRef={editorRefIcon}
              borderRadiusProp={0}
              widthProp={150}
              heightProp={150}
              positionXProp={0.5}
              positionYProp={0.5}
              rotateProp={0}
              scaleProp={1}
              handleBlur={handleBlur}
              handleChange={(value) => setFieldValue('icon', value)}
              setFileTouched={setTouchedIcon}
              touched={touchedIcon}
              errors={errors.icon}
              value={values.icon}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={muiTheme}>
                <Link to={routesApp.menuUsr.path}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading || isSubmitting}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default MenuForm
