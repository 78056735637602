import {
    AGRUPADORES_TYPES
  } from '../actions/types';
  
  const INIT_STATE = {
    all: null,
    one_agrupador: null,
    faqs: null,
    one_faq: null,
    types_faq: null,
    loading: false,
    errors: null
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case AGRUPADORES_TYPES.GET_AGRUPADORES:
        return {
          ...state,
          loading: true
        };
      case AGRUPADORES_TYPES.GET_AGRUPADORES_SUCCESS:
        return {
          ...state,
          loading: false,
          all: action.payload
        };
      case AGRUPADORES_TYPES.GET_AGRUPADORES_FAILURE:
        return {
          ...state,
          loading: false,
          errors: action.payload
        };
      case AGRUPADORES_TYPES.GET_ONE_AGRUPADOR:
        return {
          ...state,
          loading: true
        }
      case AGRUPADORES_TYPES.GET_ONE_AGRUPADOR_SUCCESS:
        return {
          ...state,
          errors: null,
          one_agrupador: action.payload,
          loading: false
        }
      case AGRUPADORES_TYPES.GET_ONE_AGRUPADOR_FAILURE:
        return {
          ...state,
          errors: null,
          one_agrupador: null,
          loading: false
        }
      case AGRUPADORES_TYPES.STORE_AGRUPADOR:
        return {
          ...state,
          loading: true
        }
      case AGRUPADORES_TYPES.STORE_AGRUPADOR_SUCCESS:
        return {
          ...state,
          errors: null,
          one_agrupador:null,
          loading: false
        }
      case AGRUPADORES_TYPES.STORE_AGRUPADOR_FAILURE:
        return {
          ...state,
          errors: action.payload,
          loading: false
        }
      case AGRUPADORES_TYPES.GET_FAQS:
        return {
          ...state,
          loading: true,
          errors:null,
        };
      case AGRUPADORES_TYPES.GET_FAQS_FAILURE:
        return {
          ...state,
          loading: false,
        };
      case AGRUPADORES_TYPES.GET_FAQS_SUCCESS:
        return {
          ...state,
          loading: false,
          faqs: action.payload
        };
  
      case AGRUPADORES_TYPES.GET_ONE_FAQ:
        return {
          ...state,
          loading: true
        }
      case AGRUPADORES_TYPES.GET_ONE_FAQ_SUCCESS:
        return {
          ...state,
          errors: null,
          one_faq: action.payload,
          loading: false
        }
      case AGRUPADORES_TYPES.GET_ONE_FAQ_FAILURE:
        return {
          ...state,
          errors: null,
          one_faq: null,
          loading: false
        }
      case AGRUPADORES_TYPES.STORE_FAQ:
        return {
          ...state,
          loading: true
        }
      case AGRUPADORES_TYPES.STORE_FAQ_SUCCESS:
        return {
          ...state,
          errors: null,
          loading: false
        }
      case AGRUPADORES_TYPES.STORE_FAQ_FAILURE:
        return {
          ...state,
          errors: action.payload,
          loading: false
        }
      case AGRUPADORES_TYPES.GET_FAQ_TYPES:
        return {
          ...state,
          loading: true
        }
      case AGRUPADORES_TYPES.GET_FAQ_TYPES_SUCCESS:
        return {
          ...state,
          types_faq: action.payload,
          loading: false
        }
      case AGRUPADORES_TYPES.GET_FAQ_TYPES_FAILURE:
        return {
          ...state,
          types_faq: null,
          loading: false
        }
      default: return { ...state };
    }
  };
  