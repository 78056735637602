import React from 'react'
import { Box, Container } from '@material-ui/core'

import Page from '../Page'
import TableUsers from './shared/tableUsers'
import BarraBusqueda from './shared/barraBusqueda'
import { routesApp } from 'src/routesService'
import { validateOnePermission } from 'src/helpers/permissions'
import { USERS_PERMISSIONS } from 'src/utils/constants/permissions'
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';
import PageContainer from 'src/containers/shared/PageContainer';

const Users = (props) => {
  const {
    users,
    roles,
    loading,
    auth,
    getAllUsers,
    deleteUser,
  } = props

  return (
    <PageContainer title="Usuarios" maxWidth={false}>
        {
          validateOnePermission(auth.permissions, USERS_PERMISSIONS.CREATE_USERS) &&
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <PrimaryLinkButton to={routesApp.addUser.path}>
              + Añadir Usuario
            </PrimaryLinkButton>
          </Box>
        }
        <BarraBusqueda roles={roles} />
        <Box mt={3}>
          <TableUsers
            auth={auth}
            data={users.data}
            // data={dataUsers}//prueba
            currentPage={users.current_page}
            itemsCountPerPage={users.per_page}
            totalItemsCount={users.total}
            nextPage={users.next_page_url}
            prevPage={users.prev_page_url}
            lastPage={users.last_page}
            loading={loading}
            getAllUsers={getAllUsers}
            deleteUser={deleteUser}
          />
        </Box>
    </PageContainer>
  )
}

export default Users
