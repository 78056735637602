import React from 'react'

import AuthService from './../../services/AuthService'
import {showDeveloperMessageConsole} from 'src/helpers/random'
import Password from 'src/components/auth/Password'
import { NotificationManager } from 'react-notifications'

const PasswordContainer = (props) => {
  // const {history}=props

  const handleResetPassword= async (email, setEmailEnviado)=>{
    NotificationManager.info("Enviando mail...")
    try {
      await AuthService.sendMailResetPassword({email})
      setEmailEnviado(true)
    } catch (error) {
      NotificationManager.error("Error al enviar el mail")
      showDeveloperMessageConsole("Error al enviar mail de restaurar contraseña: ", error)
    }
  }

  return (
    <Password
      resetPassword={handleResetPassword}
    />
  )
}

export default PasswordContainer
