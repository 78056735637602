import React from 'react'
import { Box, Container } from '@material-ui/core'

import Page from '../Page'
import BarraBusqueda from './shared/barraBusqueda'
import { routesApp } from 'src/routesService'
import TableRoles from './shared/tableRoles'
import { ROLES_PERMISSIONS } from 'src/utils/constants/permissions'
import { validateOnePermission } from 'src/helpers/permissions'
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';
import PageContainer from 'src/containers/shared/PageContainer';

const Roles = (props) => {
  const {
    auth,
    roles,
    permissions,
    loading,
    deleteRol,
  } = props;

  return (
    <PageContainer title="Roles" maxWidth={false}>
        {
          validateOnePermission(auth.permissions, ROLES_PERMISSIONS.CREATE_ROLES) &&
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <PrimaryLinkButton to={routesApp.addRol.path}>
              + Añadir Rol
            </PrimaryLinkButton>
          </Box>
        }
        <BarraBusqueda auth={auth} permissions={permissions} />
        <Box mt={3}>
          <TableRoles
            auth={auth}
            data={roles}
            loading={loading}
            deleteRol={deleteRol}
          />
        </Box>
    </PageContainer>
  )
}

export default Roles
