import { makeStyles, TableCell, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  // Fully visible for active icons
  activeSortIcon: {
    opacity: 1,
  },
  // Half visible for inactive icons
  inactiveSortIcon: {
    opacity: 0.3,
  },
}));

const TableHeader = ({ property, children }) => {
  const query = useQuery();
  const history = useHistory();
  const classes = useStyles();

  return (
    <TableCell
      sortDirection={query.get('s') === property ? query.get('o') : false}
    >
      <TableSortLabel
        active={query.get('s') === property}
        direction={query.get('s') === property ? query.get('o') : 'asc'}
        onClick={(event) => {
          const isAsc = query.get('s') === property && query.get('o') === 'asc';
          query.set('s', property);
          query.set('o', isAsc ? 'desc' : 'asc');
          history.push(`?${query.toString()}`);
        }}
        classes={{
          // Override with the active class if this is the selected column or inactive otherwise
          icon: ((query.get('s') === property) ? classes.activeSortIcon : classes.inactiveSortIcon)
        }}
      >
        {children}
        {query.get('s') === property ? (
          <span className={classes.visuallyHidden}>
            {query.get('o') === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    </TableCell>
  )
}

export default TableHeader
