import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Actions from './actions';
import TableHeader from 'src/components/shared/TableHeader';

const tablePanelUser = ({
  className, auth, data, currentPage, itemsCountPerPage, totalItemsCount, prevPage, nextPage, lastPage, loading, ...rest
}) => {
  const handlePageChange = (event, page) => {
    rest.getAllMenusUsr(page + 1, parseInt(itemsCountPerPage))
  };

  const handleChangeRowsPerPage = (event) => {
    rest.getAllMenusUsr(1, event.target.value)
  };

  const renderLinkCell = (panelUser) => {

    switch (panelUser.link_type) {
      case 'url':
        return panelUser.link;
      case 'institutional':
        return panelUser.institutional_page.link;
      case 'front_page':
        return `${process.env.REACT_APP_JUANAMANSO_REA}${panelUser.front_page.slug}`;
      default:
        return `${process.env.REACT_APP_JUANAMANSO_REA}#REA`;
    }
  };

  return (
    <Card
      className={className}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Typography variant="subtitle1" className="mt-3 ml-3 mb-2">
            Listado de elementos de menú
            {
              loading &&
              <CircularProgress size={24} style={{ marginLeft: 15 }} />
            }
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader property="id">ID</TableHeader>
                <TableHeader property="name">Nombre</TableHeader>
                <TableHeader property="order">Orden</TableHeader>
                <TableHeader property="link">Enlace</TableHeader>
                <TableHeader property="active">Activo</TableHeader>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((panelUser) => (
                <TableRow
                  hover
                  key={panelUser.id}
                >
                  <TableCell>
                    {panelUser.id}
                  </TableCell>
                  <TableCell>
                    {panelUser.name.replace(/<\/?[^>]*>/g, '')}
                  </TableCell>
                  <TableCell>
                    {panelUser.order}
                  </TableCell>
                  <TableCell>
                    {renderLinkCell(panelUser)}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      disabled
                      indeterminate={!panelUser.active}
                      style={{ color: panelUser.active ? 'green' : 'red' }}
                    />
                  </TableCell>
                  <TableCell>
                    <Actions panelUser={panelUser} deletePanelUser={rest.deletePanelUser} authPermissions={auth.permissions} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {
        data.length === 0 &&
        <div className="text-center mt-2" >
          No hay resultados para su búsqueda
        </div>
      }
      <TablePagination
        component="div"
        className="paginador"
        count={totalItemsCount}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={currentPage - 1}
        rowsPerPage={parseInt(itemsCountPerPage)}
        rowsPerPageOptions={[5, 10, 20]}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </Card>
  );
};

export default tablePanelUser;
