import {
  HEADER_TYPES, LOGO_TYPES
} from '../actions/types';

const INIT_STATE = {
  all: null,
  errors: null,
  listActive: null,
  loading: false,
  logo: null,
  one: null,
  prev: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case HEADER_TYPES.GET_HEADERS:
      return {
        ...state,
        loading: true
      };
    case HEADER_TYPES.GET_HEADERS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        one: null,
        loading: false
      };
    case HEADER_TYPES.GET_HEADERS_FAILURE:
      return {
        ...state,
        all: null,
        one: null,
        loading: false
      };
    case HEADER_TYPES.STORE_HEADER:
      return {
        ...state,
        loading: true
      };
    case HEADER_TYPES.STORE_HEADER_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false
      };
    case HEADER_TYPES.STORE_HEADER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case HEADER_TYPES.GET_ONE_HEADER:
      return {
        ...state,
        loading: true
      };
    case HEADER_TYPES.GET_ONE_HEADER_SUCCESS:
      return {
        ...state,
        errors: null,
        one: action.payload,
        loading: false
      };
    case HEADER_TYPES.GET_ONE_HEADER_FAILURE:
      return {
        ...state,
        errors: null,
        one: null,
        loading: false
      };
    case LOGO_TYPES.GET_LOGO:
      return {
        ...state,
        loading: true
      };
    case LOGO_TYPES.GET_LOGO_SUCCESS:
      return {
        ...state,
        errors: null,
        logo: action.payload,
        loading: false
      };
    case LOGO_TYPES.GET_LOGO_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case LOGO_TYPES.STORE_LOGO:
      return {
        ...state,
        loading: true
      };
    case LOGO_TYPES.STORE_LOGO_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false
      };
    case LOGO_TYPES.STORE_LOGO_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case HEADER_TYPES.GET_PREV_HEADERS:
      return {
        ...state,
        loading: true
      };
    case HEADER_TYPES.GET_PREV_HEADERS_SUCCESS:
      return {
        ...state,
        prev: action.payload,
        loading: false
      };
    case HEADER_TYPES.GET_PREV_HEADERS_FAILURE:
      return {
        ...state,
        prev: null,
        loading: false
      };
    case HEADER_TYPES.GET_LIST_ACTIVE:
      return {
        ...state,
        loading: true
      };
    case HEADER_TYPES.GET_LIST_ACTIVE_SUCCESS:
      return {
        ...state,
        listActive: action.payload,
        loading: false
      };
    case HEADER_TYPES.GET_LIST_ACTIVE_FAILURE:
      return {
        ...state,
        listActive: null,
        loading: false
      };
    default: return { ...state };
  }
}
