import { HEADER_TYPES, LOGO_TYPES } from './types'
import HeaderService from './../services/HeaderService';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { routesApp } from 'src/routesService';
import { NotificationManager } from 'react-notifications';

export const getAllHeaders = (page = 1, rows = 10, filters = '') => async dispatch => {
  dispatch({
    type: HEADER_TYPES.GET_HEADERS
  });
  try {
    const result = await HeaderService.getHeaders(page, rows, filters);
    dispatch({
      type: HEADER_TYPES.GET_HEADERS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: HEADER_TYPES.GET_HEADERS_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener los encabezados: ', error);
  }
};

export const storeHeader = (id, data, history) => async dispatch => {
  dispatch({
    type: HEADER_TYPES.STORE_HEADER
  });
  try {
    if (id) {
      await HeaderService.updateHeader(data, id);
      NotificationManager.success('Encabezado actualizado exitosamente');
    } else {
      await HeaderService.addHeader(data);
      NotificationManager.success('Encabezado creado exitosamente');
    }

    dispatch({
      type: HEADER_TYPES.STORE_HEADER_SUCCESS
    });

    history.push(routesApp.headers.path);
  } catch (error) {
    dispatch({
      type: HEADER_TYPES.STORE_HEADER_FAILURE,
      payload: error.response ? error.response.data.errors : null
    });
    NotificationManager.error('Error al guardar el encabezado.');

    showDeveloperMessageConsole('Error al guardar usuario: ', error);
  }
};

export const getOneHeader = (id) => async dispatch => {
  dispatch({
    type: HEADER_TYPES.GET_ONE_HEADER
  });
  try {
    const result = await HeaderService.getOneHeader(id);
    dispatch({
      type: HEADER_TYPES.GET_ONE_HEADER_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: HEADER_TYPES.GET_ONE_HEADER_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener un encabezado: ', error);
  }
};

export const getLogo = () => async dispatch => {
  dispatch({
    type: LOGO_TYPES.GET_LOGO
  });
  try {
    const result = await HeaderService.getLogo();
    dispatch({
      type: LOGO_TYPES.GET_LOGO_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: LOGO_TYPES.GET_LOGO_FAILURE,
      payload: error
    });
    showDeveloperMessageConsole('Error al obtener el logo: ', error);
  }
};

export const storeLogo = (data) => async dispatch => {
  dispatch({
    type: LOGO_TYPES.STORE_LOGO
  });
  try {
      await HeaderService.updateLogo(data);
      NotificationManager.success('Logo actualizado exitosamente');

    dispatch({
      type: LOGO_TYPES.STORE_LOGO_SUCCESS
    });

  } catch (error) {
    dispatch({
      type: LOGO_TYPES.STORE_LOGO_FAILURE,
      payload: error.response ? error.response.data.errors : null
    });
    NotificationManager.error('Error al guardar el logo.');

    showDeveloperMessageConsole('Error al guardar logo: ', error);
  }
};

export const getPrevHeader = () => async dispatch => {
  dispatch({
    type: HEADER_TYPES.GET_PREV_HEADERS
  });
  try {
    const logo = await HeaderService.getHeaderLogoPrev();
    const menu = await HeaderService.getHeaderMenuPrev();
    dispatch({
      type: HEADER_TYPES.GET_PREV_HEADERS_SUCCESS,
      payload: {
        logo: logo.data,
        menu: menu.data
      }
    });
  } catch (error) {
    dispatch({
      type: HEADER_TYPES.GET_PREV_HEADERS_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener la previzualizacion del ENCABEZADO: ', error);
  }
};

export const getListActive = () => async dispatch => {
  dispatch({
    type: HEADER_TYPES.GET_LIST_ACTIVE
  });
  try {
    const result = await HeaderService.getListActive();
    dispatch({
      type: HEADER_TYPES.GET_LIST_ACTIVE_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: HEADER_TYPES.GET_LIST_ACTIVE_FAILURE
    });
    showDeveloperMessageConsole("Error al obtener el listado activo de las paginas institucionales: ", error)
  }
};
