import api from '../api';

const ContactFormService = {
  getContactText: () => {
    return api.get('contact/text')
  },
  storeTextContact: (data) => {
    return api.post('contact/text', data)
  },
  fetchContactMessage: () => (
    api.get('contact/message')
  ),
  storeContactMessage: (data) => (
    api.post('contact/message', data)
  )
}

export { ContactFormService as default };
