import Axios from 'axios';
import api from '../api';

const FootersService = {
  getFooters: (page, limit, filters) => {
    //return all_mock;
    return api.get(`footer/menu?page=${page}&limit=${limit}&${filters !== '' ? filters.substring(1) : filters}`);
  },
  getFooterText: () => {
    return api.get(`footer/text`)
  },
  storeTextFooter:(data) => {
    return api.post(`footer/text`, data)
  },
  getLogo: () => {
    return api.get('footer/logo');
  },
  updateLogo: (data) => {
    return api.post(`footer/logo`, data, {
      headers:{
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Accept': 'application/x-www-form-urlencoded'
      }
    });
  },
  getLogoCentro: () => {
    return api.get('footer/logo-centro');
  },
  updateLogoCentro: (data) => {
    return api.post('footer/logo-centro', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });
  },
  addFooter: (data) => {
    return api.post('footer/menu', data);
  },
  getOneFooter: (id) => {
    //return one_mock;
    return api.get(`footer/menu/${id}`);
  },
  updateFooter: (data, id) => {
    return api.put(`footer/menu/${id}`, data);
  },
  deleteFooter: (id) => {
    return api.delete(`footer/menu/${id}`);
  },
  getFooterTextPrev: () => {
    //return one_mock;
    return Axios.get(`${process.env.REACT_APP_BASE_URL_STORAGE}footer/texto.json?${Date.now()}`);
  },
  getFooterLogoPrev: () => {
    //return one_mock;
    return Axios.get(`${process.env.REACT_APP_BASE_URL_STORAGE}footer/logo.json?${Date.now()}`);
  },
  getFooterMenuPrev: () => {
    //return one_mock;
    return Axios.get(`${process.env.REACT_APP_BASE_URL_STORAGE}footer/menu.json?${Date.now()}`);
  },
};

export { FootersService as default };
