import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getVisitorRegister, storeVisitorRegister } from 'src/actions/visitorRegisterActions';
import Loading from 'src/components/shared/loading';
import { routesApp } from 'src/routesService';
import VisitorRegister from 'src/components/visitor-register/VisitorRegister'

const VisitorRegisterContainer = (props) => {
  const {
    visitor, match, location, getVisitorRegister: fetchVisitor
  } = props;

  const handleUpdateVisitorRegister = (data) => {
    props.storeVisitorRegister(data);
  };

  useEffect(() => {
    fetchVisitor();
  }, [match.path, location.search, fetchVisitor])

  if (match.path === routesApp.registroVisitas.path) {
    if (visitor.code === null) {
      return <Loading />;
    }

    return (
      <VisitorRegister
        code={visitor.code}
        updateVisitorRegister={handleUpdateVisitorRegister}
        errors={visitor.errors}
        loading={visitor.loading}
      />
    );
  }

  return <></>;
}

const mapStateToProps = ({ visitor, auth }) => {
  return {
    visitor,
    auth,
  };
};

const mapDispatchToProps = {
  getVisitorRegister,
  storeVisitorRegister,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitorRegisterContainer);
