/* eslint-disable camelcase */
import React from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import {
  Button, createTheme, Grid, makeStyles, TextField, ThemeProvider, Typography
} from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons'
import { red } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';

import { routesApp } from 'src/routesService';
import { string_to_slug } from 'src/helpers/random';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((_theme) => ({
  color: {
    margin: _theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const FormPortada = (props) => {
  const classes = useStyles()
  const {
    portada,
    onSubmit,
  } = props;

  return (
    <Formik
      initialValues={{
        name: portada?.name ?? '',
        slug: portada?.slug ?? '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(30, 'El nombre no debe superar los 30 caracteres').required('El nombre es requerido'),
        slug: Yup.string().max(30, 'El slug no debe superar los 30 caracteres').required('El slug es requerido'),
      })}
      // eslint-disable-next-line no-unused-vars
      onSubmit={async (data, actions) => {
        const newData = {
          id: portada ? portada.id : null,
          name: data.name,
          slug: data.slug
        }

        await onSubmit(newData)
      }}
    >
      {({
        errors,
        handleBlur,
        setFieldValue,
        touched,
        isSubmitting,
        values
      }) => (
        <Form>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">General</Typography>
              <TextField
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Nombre"
                name="name"
                value={values.name}
                type="text"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue('name', e.target.value)
                  setFieldValue('slug', string_to_slug(e.target.value))
                }}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.slug && errors.slug)}
                fullWidth
                helperText={touched.slug && errors.slug}
                label="Slug"
                margin="normal"
                name="slug"
                onBlur={handleBlur}
                onChange={(e) => {
                  setFieldValue('slug', string_to_slug(e.target.value))
                }}
                type="text"
                value={values.slug}
                variant="outlined"
              />
            </Grid>
            {/* Submit */}
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={theme}>
                <Link to={routesApp.portadas.path}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<BackIcon />}
                    className={classes.color}
                  >
                    Atrás
                  </Button>
                </Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default FormPortada
