import api from '../api';

const VisitorRegisterService = {
  getVisitorRegister: () => {
    return api.get(`visitor_register`)
  },
  storeVisitorRegister:(data) => {
    return api.post(`visitor_register`, data)
  },
}
export { VisitorRegisterService as default };
