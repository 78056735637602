import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Loading from 'src/components/shared/loading';
import FAQs from 'src/components/agrupadores/faqs/FAQs';
import EditFAQ from 'src/components/agrupadores/faqs/EditFAQ';
import AddFAQ from 'src/components/agrupadores/faqs/AddFAQ';
import { routesApp } from 'src/routesService';
import { getOneFAQ, storeFAQ, getAllFAQs, getOneAgrupador} from 'src/actions/agrupadoresActions'
import { NotificationManager } from 'react-notifications';
import AgrupadoresService from './../../services/AgrupadoresService';
import { showDeveloperMessageConsole } from 'src/helpers/random';

const FAQsContainer = (props) => {
  const { agrupadores, location, history, match, auth } = props;

  const handleGetAllFAQs = (page, rows) => {
    props.getAllFAQs(match.params.agrupadorId, page, rows, location.search);
  };

     
  const handleAddFAQ=(data)=>{
    props.storeFAQ(null, match.params.agrupadorId, data, history)
  }

  const handleUpdateFAQ=(data)=>{
    props.storeFAQ(match.params.faqId, match.params.agrupadorId, data, history)
  }

  const handleDeleteFAQ = async (id) => {
    NotificationManager.info("Eliminando FAQ...", null, 1500);
    try {
      await AgrupadoresService.deleteFAQ(id, match.params.agrupadorId);
      await props.getAllFAQs(match.params.agrupadorId);
      NotificationManager.success('FAQ eliminado correctamente');

    } catch (error) {
      showDeveloperMessageConsole('Error al eliminar FAQ: ', error);
      NotificationManager.error('Ha ocurrido un error. Intente nuevamente.');
    }
  };

  useEffect(() => {
    if (match.path === routesApp.faqs.path) {
      props.getAllFAQs(match.params.agrupadorId, 1, 10, location.search);
      props.getOneAgrupador(match.params.agrupadorId);
    }else if (match.path === routesApp.editFaq.path) {
      props.getOneFAQ(match.params.faqId, match.params.agrupadorId)
    }
    
    // eslint-disable-next-line
  }, [match.path, location.search]);

  if (match.path === routesApp.addFaq.path) {
      return <AddFAQ
      errors={agrupadores.errors}
      loading={agrupadores.loading}
      addFAQ={handleAddFAQ}
    />
  }

  if (match.path === routesApp.editFaq.path) {
    if (agrupadores.one_faq !== null && agrupadores.one_faq.id.toString() === match.params.faqId) {
      return <EditFAQ
      faq={agrupadores.one_faq}
      errors={agrupadores.errors}
      loading={agrupadores.loading}
      updateFAQ={handleUpdateFAQ}
    />
    }
    return <Loading />;
    
  }
  
  if (agrupadores.faqs === null || agrupadores.one_agrupador === null ) {
    return <Loading />;
  }

  return (
    <FAQs
      faqs={agrupadores.faqs}
      loading={agrupadores.loading}
      auth={auth.data}
      getAllFAQs={handleGetAllFAQs}
      deleteFAQ={handleDeleteFAQ}
      agrupador={agrupadores.one_agrupador}
    />
  );
};

const mapStateToProps = ({ agrupadores, auth}) => {
  return {
    agrupadores,
    auth
  };
};

const mapDispatchToProps = {
  getAllFAQs,
  getOneFAQ,
  storeFAQ,
  getOneAgrupador,
};

export default connect(mapStateToProps, mapDispatchToProps)(FAQsContainer);
