import { INSTITUCIONALES_TYPES } from './types'
import PaginasInstitucionalesService from './../services/PaginasInstitucionalesService'
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { routesApp } from 'src/routesService';
import { NotificationManager } from 'react-notifications';

export const getAllPaginasInstitucionales = (page = 1, rows = 10, filters = '') => async dispatch => {
  dispatch({
    type: INSTITUCIONALES_TYPES.GET_INSTITUCIONALES
  })
  try {
    const result = await PaginasInstitucionalesService.getPaginas(page, rows, filters);
    dispatch({
      type: INSTITUCIONALES_TYPES.GET_INSTITUCIONALES_SUCCESS,
      payload: result.data
    })
  } catch (error) {
    dispatch({
      type: INSTITUCIONALES_TYPES.GET_INSTITUCIONALES_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener las páginas institucionales: ", error)
  }
}

export const storePaginaInstitucional = (id, data, history) => async dispatch => {
  dispatch({
    type: INSTITUCIONALES_TYPES.STORE_INSTITUCIONAL
  })
  try {
    if (id) {
      await PaginasInstitucionalesService.updatePagina(data, id);
      NotificationManager.success("Página actualizada exitosamente")
    } else {
      await PaginasInstitucionalesService.addPagina(data);
      NotificationManager.success("Página creada exitosamente")
    }

    dispatch({
      type: INSTITUCIONALES_TYPES.STORE_INSTITUCIONAL_SUCCESS
    })

    //redirigir
    history.push(routesApp.paginasInstitucionales.path)
  } catch (error) {
    dispatch({
      type: INSTITUCIONALES_TYPES.STORE_INSTITUCIONAL_FAILURE,
      payload: error.response ? error.response.data.errors : null
    })
    NotificationManager.error("Error al guardar la página.")

    showDeveloperMessageConsole("Error al guardar la página institucional: ", error)
  }
}


export const getOnePaginaInstitucional = (id) => async dispatch => {
  dispatch({
    type: INSTITUCIONALES_TYPES.GET_ONE_INSTITUCIONAL
  })
  try {
    const result=await PaginasInstitucionalesService.getOnePagina(id);
    dispatch({
      type: INSTITUCIONALES_TYPES.GET_ONE_INSTITUCIONAL_SUCCESS,
      payload:result.data
    })
  } catch (error) {
    dispatch({
      type: INSTITUCIONALES_TYPES.GET_ONE_INSTITUCIONAL_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener una pagina institucional: ", error)
  }
}

export const getStates = () => async dispatch => {
  dispatch({
    type: INSTITUCIONALES_TYPES.GET_STATES
  })
  try {
    const result=await PaginasInstitucionalesService.getStates();
    dispatch({
      type: INSTITUCIONALES_TYPES.GET_STATES_SUCCESS,
      payload:result.data
    })
  } catch (error) {
    dispatch({
      type: INSTITUCIONALES_TYPES.GET_STATES_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener los estados de las paginas institucionales: ", error)
  }
}

export const getListActive = () => async dispatch => {
  dispatch({
    type: INSTITUCIONALES_TYPES.GET_LIST_ACTIVE
  })
  try {
    const result=await PaginasInstitucionalesService.getListActive();
    dispatch({
      type: INSTITUCIONALES_TYPES.GET_LIST_ACTIVE_SUCCESS,
      payload:result.data
    })
  } catch (error) {
    dispatch({
      type: INSTITUCIONALES_TYPES.GET_LIST_ACTIVE_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener el listado activo de las paginas institucionales: ", error)
  }
}

const ActionsCreator = {
  previewLoading: () => ({
    type: INSTITUCIONALES_TYPES.POST_TEMPORAL_JSON
  }),
  previewSuccess: (response) => ({
    type: INSTITUCIONALES_TYPES.POST_TEMPORAL_JSON_SUCCESS, payload: response
  }),
  previewError: (error) => ({
    type: INSTITUCIONALES_TYPES.POST_TEMPORAL_JSON_FAILURE, payload: error
  }),
};

export const generateTemporalPreview = (data) => async (dispatch) => {
  dispatch(ActionsCreator.previewLoading());

  try {
    const { response } = await PaginasInstitucionalesService.temporalPreview({
      body: data.body, title: data.title
    }).then((res) => res.data);
    window.open(`${process.env.REACT_APP_JUANAMANSO_REA}preview/pagina-institucional/${response.id}`, '_blank', 'noopener, noreferrer');

    dispatch(ActionsCreator.previewSuccess(response));
  } catch (error) {
    dispatch(ActionsCreator.previewError(error.response ? error.response.data.errors : null));
    showDeveloperMessageConsole('Error al generar el archivo JSON de las paginas institucionales: ', error);
  }
};
