import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import Loading from 'src/components/shared/loading';
import ComponentsList from 'src/components/portadas/componentes/ComponentsList';
import { FORM_ACTIONS } from 'src/utils/constants/general'
import ComponentView from 'src/components/portadas/componentes/ComponentView';
import { routesApp } from 'src/routesService';
import {
  getTypesComponent, getOneComponent, storeComponent, getAllComponentes
} from 'src/actions/portadasActions'
import PortadasService from 'src/services/PortadasService';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { COMPONENTS_TYPES } from 'src/utils/constants/api';

const ComponentesContainer = (props) => {
  const {
    portadasStore,
    portadas,
    errors: portadasErrors,
    loading: portadasLoading,
    location,
    history,
    match
  } = props

  const handleGetAllComponentes = () => {
    props.getAllComponentes(match.params.portadaId, location.search);
  };

  const handleAddComponent = (data) => {
    props.storeComponent(null, match.params.portadaId, data, history)
  }

  const handleUpdateComponent = (data) => {
    props.storeComponent(match.params.componenteId, match.params.portadaId, data, history)
  }

  const handleDeleteComponente = async (id) => {
    NotificationManager.info('Eliminando componente...', null, 1500);
    try {
      await PortadasService.deleteComponente(id);
      await props.getAllComponentes(match.params.portadaId);
      NotificationManager.success('Componente eliminado correctamente');
    } catch (error) {
      showDeveloperMessageConsole('Error al eliminar componente: ', error);
      NotificationManager.error('Ha ocurrido un error. Intente nuevamente.');
    }
  };

  useEffect(() => {
    if (match.path === routesApp.editComponente.path) {
      props.getOneComponent(match.params.componenteId, match.params.portadaId)
    }

    props.getAllComponentes(match.params.portadaId, location.search);
    props.getTypesComponent()
    // eslint-disable-next-line
  }, [match.path, location.search]);

  if (portadas === null || portadasStore.componentes === null) {
    return <Loading />;
  }

  if (match.path === routesApp.addComponente.path) {
    if (portadasStore.types_component !== null) {
      const tiposComponente = portadasStore.types_component.filter(
        (tipo) => tipo.id !== COMPONENTS_TYPES.TITULO
      );

      return (
        <ComponentView
          action={FORM_ACTIONS.ADD}
          allComponents={portadasStore.componentes}
          componente={{}}
          errors={portadasStore.errors || portadasErrors}
          loading={portadasStore.loading || portadasLoading}
          componentTypes={tiposComponente}
          onFormSubmit={handleAddComponent}
        />
      )
    }
    return <Loading />;
  }

  if (match.path === routesApp.editComponente.path) {
    if (
      portadasStore.one_component !== null
      && portadasStore.one_component.id.toString() === match.params.componenteId
      && portadasStore.types_component !== null
    ) {
      const tiposComponente = portadasStore.types_component.filter(
        (tipo) => tipo.id !== COMPONENTS_TYPES.TITULO
      );
      return (
        <ComponentView
          action={FORM_ACTIONS.EDIT}
          allComponents={portadasStore.componentes}
          componente={portadasStore.one_component}
          errors={portadasStore.errors || portadasErrors}
          loading={portadasStore.loading || portadasLoading}
          componentTypes={tiposComponente}
          onFormSubmit={handleUpdateComponent}
          // deleteComponente={handleDeleteComponente}
        />
      )
    }
    return <Loading />;
  }

  const { componentes: allComponents } = portadasStore

  return (
    <ComponentsList
      portada={portadas.find((p) => p.id === Number(match.params.portadaId))}
      componentes={allComponents}
      pagination={{}}
      loading={portadasStore.loading || portadasLoading}
      getAllComponentes={handleGetAllComponentes}
      deleteComponente={handleDeleteComponente}
      portadaId={match.params.portadaId}
    />
  );
};

const mapStateToProps = ({ portadas: portadasStore }) => {
  return {
    portadasStore
  };
};

const mapDispatchToProps = {
  getAllComponentes,
  getTypesComponent,
  getOneComponent,
  storeComponent
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentesContainer);
