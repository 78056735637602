import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import AddAgrupador from 'src/components/agrupadores/AddAgrupador';
import Agrupadores from 'src/components/agrupadores/Agrupadores';
import Loading from 'src/components/shared/loading';
import AgrupadoresService from 'src/services/AgrupadoresService';
import { getAllAgrupadores, getOneAgrupador, storeAgrupador } from 'src/actions/agrupadoresActions';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { routesApp } from 'src/routesService';
import EditAgrupador from 'src/components/agrupadores/EditAgrupador';

const AgrupadoresContainer = (props) => {
  const {
    agrupadores, location, auth, history, match
  } = props;

  const handleGetAllAgrupadores = (page, rows) => {
    props.getAllAgrupadores(page, rows, location.search);
  };

  const handleAddAgrupador = (data) => {
    props.storeAgrupador(null, data, history);
  };

  const handleUpdateAgrupador = (data, id) => {
    props.storeAgrupador(id, data, history);
  };

  const handleDeleteAgrupador = async (id) => {
    NotificationManager.info('Eliminando Agrupador...', null, 1500);
    try {
      await AgrupadoresService.deleteAgrupador(id);
      await props.getAllAgrupadores();
      NotificationManager.success('Agrupador eliminado correctamente');
    } catch (error) {
      showDeveloperMessageConsole('Error al eliminar agrupador: ', error);
      NotificationManager.error('Ha ocurrido un error. Intente nuevamente.');
    }
  };

  useEffect(() => {
    if (match.path === routesApp.agrupadores.path) {
      props.getAllAgrupadores(1, 10, location.search);
    } else if (match.path === routesApp.editAgrupador.path) {
      props.getOneAgrupador(match.params.agrupadorId);
    }
    // eslint-disable-next-line
  }, [match.path, location.search]);

  if (match.path === routesApp.addAgrupador.path) {
    return (
      <AddAgrupador
        errors={agrupadores.errors}
        loading={agrupadores.loading}
        addAgrupador={handleAddAgrupador}
      />
    )
  }

  if (match.path === routesApp.editAgrupador.path) {
    if (
      agrupadores.one_agrupador !== null
      && agrupadores.one_agrupador.id.toString() === match.params.agrupadorId
    ) {
      return (
        <EditAgrupador
          agrupador={agrupadores.one_agrupador}
          errors={agrupadores.errors}
          loading={agrupadores.loading}
          updateAgrupador={handleUpdateAgrupador}
        />
      )
    }
    return <Loading />;
  }

  if (agrupadores.all === null) {
    return <Loading />;
  }

  return (
    <Agrupadores
      agrupadores={agrupadores.all}
      loading={agrupadores.loading}
      auth={auth.data}
      getAllAgrupadores={handleGetAllAgrupadores}
      deleteAgrupador={handleDeleteAgrupador}
    />
  );
};

const mapStateToProps = ({ agrupadores, auth }) => {
  return {
    agrupadores,
    auth
  };
};

const mapDispatchToProps = {
  getAllAgrupadores,
  storeAgrupador,
  getOneAgrupador
};

export default connect(mapStateToProps, mapDispatchToProps)(AgrupadoresContainer);
