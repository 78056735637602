import React from 'react';
import { Box, Container, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { routesApp } from 'src/routesService';
import { MENU_PERMISSIONS } from 'src/utils/constants/permissions';
import { validateOnePermission } from 'src/helpers/permissions';
import BarraBusquedaHeaders from '../headers/shared/barraBusquedaHeaders';

import Page from '../Page';
import TableMenuUsr from './shared/tableMenuUsr';
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';
import PageContainer from 'src/containers/shared/PageContainer';

const MenuUsr = (props) => {
  const {
    menusUsr, user, loading, getAllMenusUsr, deleteMenuUsr
  } = props;

  // useEffect(() => {
  //   getAllMenusUsr();
  // }, [getAllMenusUsr]);

  return (
    <PageContainer title="Menu Usr" maxWidth={false}>
        {
          validateOnePermission(user.permissions, MENU_PERMISSIONS.CREATE_MENU) && (
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <PrimaryLinkButton to={routesApp.addMenuUsr.path}>
              + Añadir elemento del menú
            </PrimaryLinkButton>
          </Box>
          )
        }
        <BarraBusquedaHeaders />
        <Box mt={3}>
          <TableMenuUsr
            auth={user}
            data={menusUsr.data}
            currentPage={menusUsr.current_page}
            itemsCountPerPage={menusUsr.per_page}
            totalItemsCount={menusUsr.total}
            nextPage={menusUsr.next_page_url}
            prevPage={menusUsr.prev_page_url}
            lastPage={menusUsr.last_page}
            loading={loading}
            getAllMenusUsr={getAllMenusUsr}
            deleteMenuUsr={deleteMenuUsr}
          />
        </Box>
    </PageContainer>
  );
};

export default MenuUsr;
