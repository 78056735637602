import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import UserActions from 'src/actions/authActions'
import { getAllRoles } from 'src/actions/rolesActions'
import Loading from 'src/components/shared/loading'
import { showDeveloperMessageConsole } from 'src/helpers/random'
import { NotificationManager } from 'react-notifications'
import UserProfile from 'src/components/account/UserProfile'
import AuthService from '../../services/AuthService'

const UserProfileContainer = (props) => {
  const { user, roles } = props

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)

  const handleUpdateUser = async (data) => {
    setLoading(true)
    NotificationManager.info('Actualizando perfil...', null, 1500)
    try {
      data.username = user.data.username
      const newData = await AuthService.updateProfile(data).then((res) => res.data.response);

      props.updateUser(newData);

      NotificationManager.success('Perfil actualizado correctamente')
      setLoading(false)
    } catch (error) {
      setLoading(false)

      showDeveloperMessageConsole('Error al actualizar el perfil: ', error)
      NotificationManager.error('Ha ocurrido un error. Intente nuevamente.')

      if (error.response && error.response.status === 422) {
        setErrors(error.response.data.errors)
      }
    }
  }

  useEffect(() => {
    // props.getDataUser()
    props.fetchRoles()
    // eslint-disable-next-line
  }, [])

  if (user.data === null || roles.all === null) {
    return <Loading />
  }

  return (
    <UserProfile
      user={user.data}
      roles={roles.all}
      loading={loading}
      errors={errors}
      updateUser={handleUpdateUser}
    />
  )
}

const mapStateToProps = ({ auth, roles }) => {
  return {
    user: auth,
    roles
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchRoles: (filters = '') => dispatch(getAllRoles(filters)),
  updateUser: (user) => dispatch(UserActions.update(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileContainer)
