import React from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import { Button, createTheme, Grid, makeStyles, TextField, ThemeProvider } from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons'
import { red } from '@material-ui/core/colors';
import { Link } from 'react-router-dom';

import { routesApp } from 'src/routesService';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) => ({
  color: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));


const FormAgrupador = (props) => {
  const classes = useStyles()
  const {
    agrupador,
    loading,
    updateAgrupador,
    addAgrupador,
  } = props;

  return (
    <Formik
      initialValues={{
        title: agrupador ? agrupador.title : '',
        order: agrupador ? agrupador.order : '',
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255, 'El titulo no debe superar los 255 caracteres').required('El titulo es requerido'),
        order: Yup.number().required('El orden es requerido').integer('Debe ingresar un entero para el orden'),
      })}
      onSubmit={async (data, actions) => {
        const newData={
          id: agrupador ? agrupador.id : null,
          title: data.title,
          order: data.order
        }

        if (agrupador) {
          await updateAgrupador(newData, agrupador.id)
        } else {
          await addAgrupador(newData)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        touched,
        values
      }) => (
          <Form>
            <Grid container>
              <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
                <TextField
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  label="Título"
                  name="title"
                  value={values.title}
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.order && errors.order)}
                  fullWidth
                  helperText={touched.order && errors.order}
                  label="Orden"
                  margin="normal"
                  name="order"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.order}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
                <ThemeProvider theme={theme}>
                  <Link to={routesApp.agrupadores.path}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
                </ThemeProvider>
                <Button
                  color="primary"
                  disabled={loading}
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
    </Formik>
  )
}

export default FormAgrupador
