import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { validateOnePermission } from 'src/helpers/permissions';

const RestrictedRoute = ({
  component: Component,
  permission,
  authPermissions,
  ...rest
}) => {
  return (

    // Solo muestra el componente si se tienen los permisos indicados
    // Sino, redirige a la pagina 403 Forbidden
    <Route
      render={(props) => (
        validateOnePermission(authPermissions, permission)
          ? <Component {...props} />
          : <Redirect to="/403" />
      )}
      {...rest}
    />
  );
};

export default RestrictedRoute;
