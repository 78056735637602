import React from 'react';
import { Box, Container } from '@material-ui/core';
import { routesApp } from 'src/routesService';
import { validateOnePermission } from 'src/helpers/permissions';
import { FAQS_PERMISSIONS } from 'src/utils/constants/permissions';
import Page from '../../Page';
import TableFAQs from './shared/tableFAQs';
import BarraBusquedaFAQs from './shared/barraBusquedaFAQs';
import { PrimaryLinkButton } from 'src/components/shared/Buttons/PrimaryLinkButton';
import PageContainer from 'src/containers/shared/PageContainer';

const FAQs = (props) => {
  const {
    faqs,
    agrupador,
    loading,
    auth,
    getAllFAQs,
    deleteFAQ,
  } = props;

  return (
    <PageContainer title="FAQs" maxWidth={false}>
        {
          validateOnePermission(auth.permissions, FAQS_PERMISSIONS.VIEW_FAQ) &&
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <PrimaryLinkButton to={routesApp.addFaq.path.replace(':agrupadorId', agrupador.id)}>
              + Añadir FAQ
            </PrimaryLinkButton>
          </Box>
        }
        <BarraBusquedaFAQs />
        <Box mt={3}>
          <TableFAQs
            auth={auth}
            data={faqs.data}
            currentPage={faqs.current_page}
            itemsCountPerPage={faqs.per_page}
            totalItemsCount={faqs.total}
            nextPage={faqs.next_page_url}
            prevPage={faqs.prev_page_url}
            lastPage={faqs.last_page}
            loading={loading}
            getAllFAQs={getAllFAQs}
            deleteFAQ={deleteFAQ}
            agrupadorId={agrupador.id}
            agrupadorTitle={agrupador.title}
          />
        </Box>
    </PageContainer>
  );
};

export default FAQs;
