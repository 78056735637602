import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router';
import { routesApp } from 'src/routesService';
import DeleteItem from 'src/components/shared/DeleteItem';

const ITEM_HEIGHT = 48;

export default function Actions(props) {
  const {
    componente, portadaId, abilities, ...rest
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleEditClick() {
    history.push(routesApp.editComponente.path.replace(':portadaId', portadaId).replace(':componenteId', componente.id))
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {abilities.edit && (
          <MenuItem onClick={handleEditClick}>
            Editar
          </MenuItem>
        )}
        {abilities.delete && (
          <DeleteItem
            deleteAction={rest.deleteComponente}
            item={componente.id}
            message="¿Deseas eliminar el componente seleccionado?"
            title="Eliminar Componente"
          />
        )}
      </Menu>
    </div>
  );
}
