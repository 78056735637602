import React from 'react';
import {
  Card,
  CardContent,
  Container,
  Typography
} from '@material-ui/core';

import { FORM_ACTIONS } from 'src/utils/constants/general';
import FormComponentMenuUsr from './shared/form';

import ErrorsValidation from '../shared/displayErrorsValidations';
import Page from '../Page';
import PageContainer from 'src/containers/shared/PageContainer';

const FormMenuUsr = ({
  action,
  errors,
  loading,
  handleMenuUsr,
  menuUsr,
  paginasInst,
  portadas
}) => {
  const title = action === FORM_ACTIONS.ADD
    ? `Agregar Menu de Usuario - ${process.env.REACT_APP_NAME}`
    : `Editar Menu de Usuario - ${process.env.REACT_APP_NAME}`;

  const name = action === FORM_ACTIONS.ADD
    ? 'Agregando Menu de Usuario'
    : `Editando Menu de Usuario ${menuUsr.name}`;

  return (
    <PageContainer title={title} maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          {name}
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <FormComponentMenuUsr
              menuUsr={menuUsr}
              loading={loading}
              institucionales={paginasInst}
              portadas={portadas}
              submitAction={handleMenuUsr}
            />
          </CardContent>
        </Card>
    </PageContainer>
  );
};

export default FormMenuUsr;
