import { showDeveloperMessageConsole } from 'src/helpers/random';
import { PERMISSION_TYPES } from './types';
import PermissionsService from '../services/PermissionsService';

export const getAllPermissions = (filters='') => async (dispatch) => {
  dispatch({
    type: PERMISSION_TYPES.GET_PERMISSIONS
  });
  try {
    const result = await PermissionsService.getPermissions(filters);
    dispatch({
      type: PERMISSION_TYPES.GET_PERMISSIONS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: PERMISSION_TYPES.GET_PERMISSIONS_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener los footers: ', error);
  }
};
