import {
  AUTH_TYPES
} from '../actions/types';

const INIT_STATE = {
  authenticated: Boolean(localStorage.getItem('access_token')),
  data: null,
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_TYPES.GET_DATA_USER:
      return {
        ...state,
        loading: true
      }
    case AUTH_TYPES.GET_DATA_USER_SUCCESS:
      return {
        ...state,
        authenticated: true,
        data: action.payload,
        loading: false
      }
    case AUTH_TYPES.GET_DATA_USER_FAILURE:
      return {
        ...state,
        authenticated: false,
        data: null,
        loading: false
      }
    case AUTH_TYPES.LOGOUT:
      return {
        ...state,
        loading: true
      }
    case AUTH_TYPES.LOGOUT_SUCCESS:
      return {
        ...INIT_STATE,
        authenticated: false,
      }
    case AUTH_TYPES.LOGOUT_FAILURE:
      return {
        ...state,
        authenticated: false,
        loading: false
      }
    case AUTH_TYPES.LOGIN:
      return {
        ...state,
        authenticated: true,
        data: action.payload
      }
    case AUTH_TYPES.UPDATE_USER:
      return {
        ...state,
        data: action.payload
      }

    default: return { ...state };
  }
}
