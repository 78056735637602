import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Permissions from 'src/components/permissions/Permissions';
import { getAllPermissions } from 'src/actions/permissionsActions';
import Loading from 'src/components/shared/loading';

const PermissionsContainer = (props) => {
  const { permissions, location } = props;

  useEffect(() => {
    props.getAllPermissions(location.search)
    // eslint-disable-next-line
  }, [location.search]);

  if (permissions.all === null) {
    return <Loading />;
  }

  return (
    <Permissions
      permissions={permissions.all}
      loading={permissions.loading}
    />
  );
};

const mapStateToProps = ({ permissions }) => {
  return {
    permissions
  };
};

const mapDispatchToProps = {
  getAllPermissions
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsContainer);
