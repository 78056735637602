import React from 'react'
import {
  Card, CardContent, Typography
} from '@material-ui/core'

import { FORM_NAMES } from 'src/utils/constants/general'
import Loading from 'src/components/shared/loading'
import PageContainer from 'src/containers/shared/PageContainer'
import ErrorsValidation from 'src/components/shared/displayErrorsValidations'

import FormComponent from './shared/form'

const ComponentView = ({
  action, errors, loading, componente, componentTypes, ...rest
}) => {
  const formTitle = FORM_NAMES[action];

  if (!componente) {
    return <Loading />
  }

  return (
    <PageContainer title={`${formTitle} Componente`} maxWidth={false}>
      <Typography
        color="textPrimary"
        variant="h2"
        className="mb-3"
      >
        {`${formTitle.replace(/.$/, 'ndo')} Componente`}
      </Typography>
      <ErrorsValidation errors={errors} />
      <Card>
        <CardContent>
          <FormComponent
            component={Object.keys(componente).length > 0 ? componente : undefined}
            types_component={componentTypes}
            addComponent={rest.onFormSubmit}
            updateComponent={rest.onFormSubmit}
            loading={loading}
            allComponents={rest.allComponents}
          />
        </CardContent>
      </Card>
    </PageContainer>
  )
}

export default ComponentView
