import api from '../api';

export const PanelUserService = {
  index: (page, limit, filters) => {
    return api.get(`user/panel?page=${page}&limit=${limit}&${filters !== '' ? filters.substring(1) : filters}`);
  },
  postPanelUser: (data) => {
    return api.post(`user/panel`, data)
  },
  deletePanelUser: (id) => {
    return api.delete(`user/panel/${id}`)
  },
  updatePanelUser: (data, id) => {
    return api.post(`user/panel/${id}?_method=PUT`, data)
  },
  showPanelUser: (id) => {
    return api.get(`user/panel/${id}`)
  },
}