import { TAMANIOS_COMPONENTES_OPTIONS } from 'src/utils/constants/api';
import { arrayMax } from './random';

export const getColumnasFormatedForSelectComponents = (lista, fila) => {
  const resultList = [];
  if (fila) {
    const obj = lista.find((f) => f.fila === fila.value);
    if (obj) {
      obj.columnas.forEach((c) => resultList.push({ value: c, label: c }));
    } else {
      return TAMANIOS_COMPONENTES_OPTIONS;
    }
  }
  return resultList;
};

export const addNewFila = (filas) => {
  const max = arrayMax(filas, 'value');
  return [...filas, { value: max + 1, label: max + 1 }];
};

export const getOptionsSize = (column) => {
  const aux = []
  const maxLength = TAMANIOS_COMPONENTES_OPTIONS.length - column + 1
  for (let index = 1; index <= maxLength; index++) {
    aux.push({ label: index, value: index })
  }
  return aux
}

export const getOptionsRows = (components) => {
  const rowArray = components.reduce((carry, comp) => {
    if (comp.row && !carry.includes(comp.row)) {
      const maxCarryElem = carry.length > 0 ? Math.max.apply(null, carry) : 0;
      const rowGap = comp.row - maxCarryElem

      for (let index = 1; index < rowGap; index++) {
        carry.push(maxCarryElem + index)
      }
      return [...carry, comp.row]
    }

    return carry
  }, [])

  return rowArray.map((row) => (
    { label: row, value: row }
  ))
}
