import {
  FOOTER_TYPES, LOGO_FOOTER_TYPES
} from '../actions/types';

const INIT_STATE = {
  all: null,
  one: null,
  text: null,
  logo: null,
  logo_centro: null,
  prev: null,
  errors: null,
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FOOTER_TYPES.GET_FOOTERS:
      return {
        ...state,
        loading: true
      };
    case FOOTER_TYPES.GET_FOOTERS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        loading: false
      };
    case FOOTER_TYPES.GET_FOOTERS_FAILURE:
      return {
        ...state,
        all: null,
        loading: false
      };
    case FOOTER_TYPES.GET_TEXT_FOOTER:
      return {
        ...state,
        loading: true
      };
    case FOOTER_TYPES.GET_TEXT_FOOTER_SUCCESS:
      return {
        ...state,
        errors: null,
        text: action.payload,
        loading: false
      };
    case FOOTER_TYPES.GET_TEXT_FOOTER_FAILURE:
      return {
        ...state,
        text: null,
        loading: false
      };
    case FOOTER_TYPES.STORE_TEXT_FOOTER:
      return {
        ...state,
        loading: true
      };
    case FOOTER_TYPES.STORE_TEXT_FOOTER_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false
      };
    case FOOTER_TYPES.STORE_TEXT_FOOTER_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case LOGO_FOOTER_TYPES.GET_LOGO_FOOTER:
      return {
        ...state,
        loading: true
      };
    case LOGO_FOOTER_TYPES.GET_LOGO_FOOTER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        logo: action.payload
      };
    case LOGO_FOOTER_TYPES.GET_LOGO_FOOTER_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case LOGO_FOOTER_TYPES.GET_LOGO_FOOTER_CENTRO:
      return {
        ...state,
        loading: true
      };
    case LOGO_FOOTER_TYPES.GET_LOGO_FOOTER_CENTRO_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        logo_centro: action.payload
      };
    case LOGO_FOOTER_TYPES.GET_LOGO_FOOTER_CENTRO_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER:
      return {
        ...state,
        loading: true
      };
    case LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER_CENTRO:
      return {
        ...state,
        loading: true
      };
    case LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER_CENTRO_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER_CENTRO_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case FOOTER_TYPES.GET_ONE_FOOTER:
      return {
        ...state,
        loading: true
      };
    case FOOTER_TYPES.GET_ONE_FOOTER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null,
        one: action.payload
      };
    case FOOTER_TYPES.GET_ONE_FOOTER_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        one: null
      };
    case FOOTER_TYPES.STORE_FOOTER:
      return {
        ...state,
        loading: true
      };
    case FOOTER_TYPES.STORE_FOOTER_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case FOOTER_TYPES.STORE_FOOTER_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    case FOOTER_TYPES.GET_PREV_FOOTERS:
      return {
        ...state,
        loading: true
      };
    case FOOTER_TYPES.GET_PREV_FOOTERS_SUCCESS:
      return {
        ...state,
        prev: action.payload,
        loading: false
      };
    case FOOTER_TYPES.GET_PREV_FOOTERS_FAILURE:
      return {
        ...state,
        prev: null,
        loading: false
      };
    default: return { ...state };
  }
};
