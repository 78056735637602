import { USER_TYPES } from './types'
import UsersService from './../services/UsersService'
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { routesApp } from 'src/routesService';
import { NotificationManager } from 'react-notifications';

export const getAllUsers = (page=1, rows=10, filters='') => async dispatch => {
  dispatch({
    type: USER_TYPES.GET_USERS
  })
  try {
    const result = await UsersService.getUsers(page, rows, filters);
    dispatch({
      type: USER_TYPES.GET_USERS_SUCCESS,
      payload: result.data
    })
  } catch (error) {
    dispatch({
      type: USER_TYPES.GET_USERS_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener los usuarios: ", error)
  }
}

export const storeUser = (id, data, history) => async dispatch => {
  dispatch({
    type: USER_TYPES.STORE_USER
  })
  try {
    if (id) {
      await UsersService.updateUser(data, id);
      NotificationManager.success("Usuario actualizado exitosamente")
    } else {
      await UsersService.addUser(data);
      NotificationManager.success("Usuario creado exitosamente")
    }
    
    dispatch({
      type: USER_TYPES.STORE_USER_SUCCESS
    })

    //redirigir
    history.push(routesApp.users.path)
  } catch (error) {
    dispatch({
      type: USER_TYPES.STORE_USER_FAILURE,
      payload:error.response ? error.response.data.errors : null
    })
    NotificationManager.error("Error al guardar el usuario.")

    showDeveloperMessageConsole("Error al guardar usuario: ", error)
  }
}


export const getOneUser = (id) => async dispatch => {
  dispatch({
    type: USER_TYPES.GET_ONE_USER
  })
  try {
    const result=await UsersService.getOneUser(id);
    dispatch({
      type: USER_TYPES.GET_ONE_USER_SUCCESS,
      payload:result.data
    })
  } catch (error) {
    dispatch({
      type: USER_TYPES.GET_ONE_USER_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener un usuario: ", error)
  }
}
