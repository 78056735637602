import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  IconButton,
  Button,
  createTheme,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { Link, useRouteMatch } from 'react-router-dom';
import CKEditorTitulo from 'src/components/shared/CKEditor/CKEditorTitulo';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { SketchPicker } from 'react-color';

import { routesApp } from 'src/routesService';
import { selectFileWithCKFinder } from 'src/helpers/random';

import SelectField from 'src/components/shared/SelectField';
import { TAMANIOS_COMPONENTES_OPTIONS } from 'src/utils/constants/api';
import EditorImage from 'src/components/shared/EditorImage';
import { getOptionsSize } from 'src/helpers/components';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((_theme) => ({
  color: {
    margin: _theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const FormBuscador = (props) => {
  const classes = useStyles()
  const match = useRouteMatch()

  const {
    componentType,
    component,
    loading,
    filas,
    addFila,
    appendBackground,
    updateComponent,
    addComponent,
  } = props;

  const editorRefImageBackground = React.createRef()

  const [background, setBackground] = useState(component ? (component.page_background.color_hex ? '2' : '1') : '1');
  const [maxSize, setMaxSize] = useState(component ? getOptionsSize(component.col) : []);
  const [backgroundFileTouched, setBackgroundFileTouched] = React.useState(false);

  return (
    <Formik
      initialValues={{
        fila: component ? { label: component.row, value: component.row } : '',
        columna: component ? { label: component.col, value: component.col } : '',
        tamanio: component ? { label: component.size, value: component.size } : '',
        title: component ? component.title : '',
        texto_buscador: component ? component.search_box_text : '',
        color_background: component && component.page_background.color_hex ? component.page_background.color_hex : '',
        image_background: component && component.page_background.color_hex === null ? `${process.env.REACT_APP_BASE_URL_API}/${component.page_background.image_file.filename}` : ''
      }}
      validationSchema={Yup.object().shape({
        fila: Yup.string().required('El número de fila es requerido'),
        columna: Yup.string().required('El número de columna es requerido'),
        tamanio: Yup.string().required('El tamaño es requerido'),
        title: Yup.string().required('El título es requerido'),
        image_background: background === '1' && Yup.string().required('La imagen del background es requerida'),
        color_background: background === '2' && Yup.string().required('El color del background es requerido'),
        texto_buscador: Yup.string().required('El texto del buscador es requerido'),
      })}
      // eslint-disable-next-line no-unused-vars
      onSubmit={async (data, actions) => {
        const formData = new FormData()

        formData.append('front_page_id', match.params.portadaId)
        formData.append('component_type_id', componentType.value)

        formData.append('row', data.fila.value)
        formData.append('col', data.columna.value)
        formData.append('size', data.tamanio.value)

        formData.append('title', data.title)
        formData.append('search_box_text', data.texto_buscador)

        appendBackground(formData, background, data, editorRefImageBackground.current, backgroundFileTouched);

        if (component) {
          await updateComponent(formData, component.id)
        } else {
          await addComponent(formData)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <Form style={{ width: '100%' }}>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <FormControl margin="normal" fullWidth error={Boolean(touched.fila && errors.fila)}>
                <SelectField
                  id="fila-titulo"
                  name="fila"
                  placeholder="Seleccione una fila..."
                  getOptions={() => filas}
                  label="Fila"
                  setFieldValue={(value) => {
                    setFieldValue('fila', value);
                    setFieldValue('columna', '');
                    setFieldValue('tamanio', '');
                  }}
                  value={values.fila}
                  addNewOption
                  addOption={() => addFila()}
                />
                <FormHelperText className="text-danger">{(touched.fila && errors.fila) && errors.fila}</FormHelperText>
              </FormControl>
              <FormControl margin="normal" fullWidth error={Boolean(touched.columna && errors.columna)}>
                <SelectField
                  id="columna-titulo"
                  name="columna"
                  placeholder="Seleccione una columna..."
                  getOptions={() => TAMANIOS_COMPONENTES_OPTIONS}
                  label="Columna"
                  setFieldValue={(value) => {
                    setFieldValue('columna', value);
                    setFieldValue('tamanio', '');
                    setMaxSize(getOptionsSize(value.value))
                  }}
                  value={values.columna}
                />
                <FormHelperText className="text-danger">{(touched.columna && errors.columna) && errors.columna}</FormHelperText>
              </FormControl>
              <FormControl margin="normal" fullWidth error={Boolean(touched.tamanio && errors.tamanio)}>
                <SelectField
                  id="tamanio-titulo"
                  name="tamanio"
                  placeholder="Seleccione un tamaño..."
                  getOptions={() => maxSize}
                  label="Tamaño"
                  setFieldValue={(value) => {
                    setFieldValue('tamanio', value);
                  }}
                  value={values.tamanio}
                />
                <FormHelperText className="text-danger">{(touched.tamanio && errors.tamanio) && errors.tamanio}</FormHelperText>
              </FormControl>
              <FormControl margin="normal" fullWidth error={Boolean((touched.title && errors.title))}>
                <Typography color="textPrimary" variant="overline">Título</Typography>
                <CKEditorTitulo
                  id="ck-title-desplazada"
                  value={values.title}
                  name="title"
                  styles={[
                    { name: 'height', value: '150px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.title && errors.title)}</FormHelperText>
              </FormControl>
              <TextField
                error={Boolean(touched.texto_buscador && errors.texto_buscador)}
                fullWidth
                helperText={touched.texto_buscador && errors.texto_buscador}
                label="Texto buscador"
                margin="normal"
                name="texto_buscador"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.texto_buscador}
                variant="outlined"
              />
              <FormControl fullWidth margin="normal" style={{ marginBottom: 6 }}>
                <Typography color="textPrimary" variant="overline" className="font-weight-bold">Background</Typography>

                <FormLabel component="legend">Tipo de background</FormLabel>
                <RadioGroup row aria-label="gender" name="tipoEnlace" value={background} onChange={handleChange}>
                  <FormControlLabel value="1" onChange={() => setBackground('1')} control={<Radio />} label="Imagen" />
                  <FormControlLabel value="2" onChange={() => setBackground('2')} control={<Radio />} label="Color" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {
            background === '1'
            && (
              process.env.REACT_APP_CKFINDER_ACTIVE === 'false'
                ? (
                  <EditorImage
                    label="Imagen del background"
                    editorRef={editorRefImageBackground}
                    borderRadiusProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file.styles).borderRadius ? JSON.parse(component.page_background.image_file.styles).borderRadius : 0}
                    widthProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file.styles).width ? JSON.parse(component.page_background.image_file.styles).width : 250}
                    heightProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file.styles).height ? JSON.parse(component.page_background.image_file.styles).height : 250}
                    positionXProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file.styles).positionX ? JSON.parse(component.page_background.image_file.styles).positionX : 0.5}
                    positionYProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file.styles).positionY ? JSON.parse(component.page_background.image_file.styles).positionY : 0.5}
                    rotateProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file.styles).rotate ? JSON.parse(component.page_background.image_file.styles).rotate : 0}
                    scaleProp={component && component.page_background.image_file && JSON.parse(component.page_background.image_file.styles).scale ? JSON.parse(component.page_background.image_file.styles).scale : 1}
                    handleBlur={handleBlur}
                    handleChange={(value) => setFieldValue('image_background', value)}
                    setFileTouched={setBackgroundFileTouched}
                    touched={backgroundFileTouched}
                    errors={errors.image_background}
                    value={values.image_background}
                  />
                )
                : (
                  <Grid container>
                    <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
                      <TextField
                        error={Boolean(errors.image_background && errors.image_background)}
                        fullWidth
                        helperText={touched.image_background && errors.image_background}
                        label="Imagen Background"
                        margin="normal"
                        name="image_background"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.image_background}
                        variant="outlined"
                      // disabled
                        InputProps={{ endAdornment: <IconButton onClick={() => selectFileWithCKFinder(setFieldValue, 'image_background')}><CloudUploadIcon /></IconButton> }}
                      />
                    </Grid>
                  </Grid>
                )
            )
          }
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              {
                background === '2'
                && (
                <FormControl fullWidth error={Boolean(touched.instWeb && errors.instWeb)}>
                  <SketchPicker
                    width="100%"
                    color={values.color_background}
                    onChangeComplete={(color) => setFieldValue('color_background', color.hex)}
                  />
                  <FormHelperText className="text-danger">{(touched.instWeb && errors.instWeb) && errors.instWeb}</FormHelperText>
                </FormControl>
                )
              }
            </Grid>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={theme}>
                <Link to={routesApp.componentes.path.replace(':portadaId', match.params.portadaId)}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default FormBuscador;
