import React from 'react'
import { Card, CardContent, Container, Typography } from '@material-ui/core'

import Page from '../Page'
// import { useRouteMatch } from 'react-router'
import { FORM_ACTIONS } from 'src/utils/constants/general'
import ErrorsValidation from '../shared/displayErrorsValidations'
import FormPagina from './shared/form'
import PageContainer from 'src/containers/shared/PageContainer';

const EditPagina = (props) => {
  const {
    pagina,
    states,
    errors,
    loading,
    auth,
    updatePagina,
    generateTemporalPreview,
  } = props;

  // const match = useRouteMatch()
  return (
    <PageContainer title="Editar Página Institucional" maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          Editando Página Institucional {`${pagina.title}`}
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <FormPagina
              action={FORM_ACTIONS.EDIT} 
              pagina={pagina} 
              states={states} 
              updatePagina={updatePagina} 
              loading={loading}
              auth={auth}
              generateTemporalPreview={generateTemporalPreview}
            />
          </CardContent>
        </Card>
    </PageContainer>
  )
}

export default EditPagina
