import React from 'react'
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import { Button, createTheme, FormControl, FormHelperText, Grid, makeStyles, TextField, ThemeProvider, Typography } from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons'
import { red } from '@material-ui/core/colors';
import { Link, useRouteMatch } from 'react-router-dom';

import CKEditorTexto from 'src/components/shared/CKEditor/CKEditorTexto';
import { routesApp } from 'src/routesService';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) => ({
  color: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));


const FormFAQ = (props) => {
  const classes = useStyles()
  const {
    faq,
    loading,
    updateFAQ,
    addFAQ,
  } = props;

  const match=useRouteMatch()
  return (
    <Formik
      initialValues={{
        question: faq ? faq.question : '',
        answer: faq ? faq.answer : '',
        order: faq ? faq.order : '',
      }}
      validationSchema={Yup.object().shape({
        question: Yup.string().required('La pregunta es requerida'),
        answer: Yup.string().required('La respuesta es requerida'),
        order: Yup.number().required('El orden es requerido').integer('Debe ingresar un entero para el orden'),
      })}
      onSubmit={async (data, actions) => {
        const newData = {
          id: faq ? faq.id : null,
          question: data.question,
          answer: data.answer,
          order: data.order
        }

        if (faq) {
          await updateFAQ(newData, faq.id)
        } else {
          await addFAQ(newData)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched,
        touched,
        values
      }) => (
        <Form>
          <Grid container>
            <Grid item xs={12} className="pr-3 pl-3 pt-2">
              <Typography color="textPrimary" variant="overline" className="font-weight-bold">General</Typography>
              <FormControl margin="normal" fullWidth error={Boolean(touched.question && errors.question)}>
                <Typography color="textPrimary" variant="overline">Pregunta</Typography>
                <CKEditorTexto
                  id="ck-question"
                  value={values.question}
                  name="question"
                  styles={[
                    { name: 'height', value: '200px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.question && errors.question) && errors.question}</FormHelperText>
              </FormControl>
              <FormControl margin="normal" fullWidth error={Boolean(touched.answer && errors.answer)}>
                <Typography color="textPrimary" variant="overline">Respuesta</Typography>
                <CKEditorTexto
                  id="ck-answer"
                  value={values.answer}
                  name="answer"
                  styles={[
                    { name: 'height', value: '200px' },
                  ]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
                <FormHelperText className="text-danger">{(touched.answer && errors.answer) && errors.answer}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
              <TextField
                error={Boolean(touched.order && errors.order)}
                fullWidth
                helperText={touched.order && errors.order}
                label="Orden"
                margin="normal"
                name="order"
                onBlur={handleBlur}
                onChange={handleChange}
                type="number"
                value={values.order}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
              <ThemeProvider theme={theme}>
                <Link to={routesApp.faqs.path.replace(':agrupadorId', match.params.agrupadorId)}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
              </ThemeProvider>
              <Button
                color="primary"
                disabled={loading}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Guardar
                </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default FormFAQ
