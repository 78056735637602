import { NotificationManager } from 'react-notifications';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { MENU_USR_TYPES } from './types';
import MenuUsrService from '../services/MenuUsrService';

export const getAllMenusUsr = (page = 1, rows = 10, filters = '') => {
  return async (dispatch) => {
    dispatch({
      type: MENU_USR_TYPES.GET_MENUS_USR
    });
    try {
      const result = await MenuUsrService.getMenusUsr(page, rows, filters);
      dispatch({
        type: MENU_USR_TYPES.GET_MENUS_USR_SUCCESS,
        payload: result.data
      });
    } catch (error) {
      dispatch({
        type: MENU_USR_TYPES.GET_MENUS_USR_FAILURE
      });
      showDeveloperMessageConsole('Error al obtener los menu de usuario: ', error);
    }
  }
};

export const getOneMenuUsr = (id) => async (dispatch) => {
  dispatch({
    type: MENU_USR_TYPES.GET_ONE_MENU_USR
  });
  try {
    const result = await MenuUsrService.getOneMenuUsr(id);
    dispatch({
      type: MENU_USR_TYPES.GET_ONE_MENU_USR_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: MENU_USR_TYPES.GET_ONE_MENU_USR_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener un menu de usuario: ', error);
  }
};

export const storeMenuUsr = (id, data) => async (dispatch) => {
  dispatch({
    type: MENU_USR_TYPES.STORE_MENU_USR
  });
  try {
    if (!id) {
      await MenuUsrService.addMenuUsr(data);
      NotificationManager.success('Menu de usuario actualizado exitosamente');
    } else {
      await MenuUsrService.updateMenuUsr(data, id);
      NotificationManager.success('Menu de usuario creado exitosamente');
    }

    dispatch({
      type: MENU_USR_TYPES.STORE_MENU_USR_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: MENU_USR_TYPES.STORE_MENU_USR_FAILURE,
      payload: error.response ? error.response.data.errors : null
    });
    NotificationManager.error('Error al guardar el menu de usuario.');

    showDeveloperMessageConsole('Error al guardar menu de usuario: ', error);
  }
};

export const deleteMenuUsr = (id) => async (dispatch) => {
  dispatch({
    type: MENU_USR_TYPES.LOADING
  });
  try {
    await MenuUsrService.deleteMenuUsr(id);
    NotificationManager.success('Menú de usuario eliminado correctamente');
  } catch (error) {
    NotificationManager.error('Error al borrar el menu de usuario.');
    showDeveloperMessageConsole('Error al borrar el menu de usuario: ', error);
  } finally {
    dispatch({
      type: MENU_USR_TYPES.NOT_LOADING
    });
  }
};

export const getPrevMenuUsr = () => async (dispatch) => {
  dispatch({
    type: MENU_USR_TYPES.GET_PREVS_MENU_USR
  });
  try {
    const text = await MenuUsrService.getMenuUsrTextPrev();
    const menu = await MenuUsrService.getMenuUsrMenuPrev();
    dispatch({
      type: MENU_USR_TYPES.GET_PREV_MENUS_USR_SUCCESS,
      payload: {
        text: text.data,
        menu: menu.data
      }
    });
  } catch (error) {
    dispatch({
      type: MENU_USR_TYPES.GET_PREV_MENUS_USR_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener la previzualizacion del menu de usuario: ', error);
  }
};
