import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import TableHeader from 'src/components/shared/TableHeader';

const TablePermissions = ({
  className, data, order, orderBy, loading, ...rest
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card
      className={className}
      {...rest}
    >
      <PerfectScrollbar>

        <Box minWidth={1050} >
          <Typography variant="subtitle1" className="mt-3 ml-3 mb-2">
            Listado de Permisos
              {
                loading &&
                  <CircularProgress size={24} style={{ marginLeft: 15}} />
              }
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader property="id">ID</TableHeader>
                <TableHeader property="name">Nombre</TableHeader>
                <TableHeader property="display_name">Nombre a mostrar</TableHeader>
                <TableHeader property="description">Descripción</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((permission, index) => (
                  <TableRow
                    hover
                    key={permission.id}
                  >
                    <TableCell>
                      {permission.id}
                    </TableCell>
                    <TableCell>
                      {permission.name}
                    </TableCell>
                    <TableCell>
                      {permission.display_name}
                    </TableCell>
                    <TableCell>
                      {permission.description}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {
        data.length === 0 &&
        <div className="text-center mt-2" >
          No hay resultados para su búsqueda
        </div>
      }
      <TablePagination
        component="div"
        className="paginador"
        count={data.length}
        rowsPerPageOptions={[5, 10, 20]}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default TablePermissions;
