import {
  ROLE_TYPES
} from '../actions/types';

const INIT_STATE = {
  all: null,
  one: null,
  errors: null,
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROLE_TYPES.GET_ROLES:
      return {
        ...state,
        loading: true
      };
    case ROLE_TYPES.GET_ROLES_SUCCESS:
      return {
        ...state,
        all: action.payload,
        loading: false
      };
    case ROLE_TYPES.GET_ROLES_FAILURE:
      return {
        ...state,
        all: null,
        loading: false
      };
    case ROLE_TYPES.STORE_ROL:
      return {
        ...state,
        loading: true
      }
    case ROLE_TYPES.STORE_ROL_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false
      }
    case ROLE_TYPES.STORE_ROL_FAILURE:
      return {
        ...state,
        errors: action.payload,
        loading: false
      }
    case ROLE_TYPES.GET_ONE_ROL:
      return {
        ...state,
        loading: true
      }
    case ROLE_TYPES.GET_ONE_ROL_SUCCESS:
      return {
        ...state,
        errors: null,
        one: action.payload,
        loading: false
      }
    case ROLE_TYPES.GET_ONE_ROL_FAILURE:
      return {
        ...state,
        errors: null,
        one: null,
        loading: false
      }
    default: return { ...state };
  }
};
