import React from 'react'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import Page from '../../Page'
import { FORM_ACTIONS } from 'src/utils/constants/general'
import ErrorsValidation from '../../shared/displayErrorsValidations'
import FormFAQ from './shared/form'
import PageContainer from 'src/containers/shared/PageContainer';

const EditFAQ = (props) => {
  const {
    errors,
    loading,
    faq,
    updateFAQ,
  } = props;

  return (
    <PageContainer title="Editar FAQ" maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          Editando FAQ
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <FormFAQ
              faq={faq}
              action={FORM_ACTIONS.EDIT}
              updateFAQ={updateFAQ}
              loading={loading}
            />
          </CardContent>
        </Card>
    </PageContainer>
  )
}

export default EditFAQ
