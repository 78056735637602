import React, { useCallback, useEffect, useState } from 'react'
import { Switch } from 'react-router-dom'

import { routesApp } from 'src/routesService'
import { NotificationManager } from 'react-notifications'
import { showDeveloperMessageConsole } from 'src/helpers/random'
import { FORM_ACTIONS } from 'src/utils/constants/general'
import AuthorizedRoute from 'src/components/shared/AuthorizedRoute'
import NewsPages from 'src/components/news-pages/NewsPages'
import NewsPagesService from 'src/services/NewsPagesService'
import NewsPagesOne from 'src/components/news-pages/NewsPagesOne'
import Loading from 'src/components/shared/loading'

const NewsPagesContainer = ({ location, match: { path } }) => {
  const newsRotues = routesApp.newsPage.children;
  const [newsPages, setNewsPages] = useState(null)
  const [pageStates, setPageStates] = useState([])
  const [pagination, setPagination] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchNews = useCallback(
    (page = 1, rows = 10) => {
      setLoading(true);
      return NewsPagesService.getPaginas(page, rows, location.search)
        .then((response) => response.data)
        .then(({ data, ...paginationData }) => {
          setNewsPages(data)
          setPagination(paginationData)
          setLoading(false);
        })
        .catch((error) => {
          setPagination({})
          setLoading(false);
          showDeveloperMessageConsole('Error al obtener las páginas de noticias: ', error)
        });
    }, [location.search]
  )

  const fetchStates = () => {
    return NewsPagesService.getStates()
      .then((response) => response.data)
      .then((data) => {
        setPageStates(data)
      })
      .catch((error) => {
        showDeveloperMessageConsole('Error al obtener los estados de las paginas de noticias: ', error)
      });
  };

  const handleDeletePagina = async (id) => {
    NotificationManager.info('Eliminando página...', null, 1500)
    try {
      await NewsPagesService.deletePagina(id);
      await fetchNews(pagination.current_page, pagination.per_page);
      NotificationManager.success('Página eliminada correctamente')
    } catch (error) {
      showDeveloperMessageConsole('Error al eliminar pagina: ', error)
      NotificationManager.error('Ha ocurrido un error. Intente nuevamente.')
    }
  }

  useEffect(() => {
    fetchStates();
    fetchNews();
  }, [path, location.search, fetchNews])

  if (!pagination) {
    return <Loading />
  }

  return (
    <Switch>
      <AuthorizedRoute
        exact
        path={path}
        component={(props) => (
          <NewsPages
            news={newsPages}
            pageStates={pageStates}
            pagination={pagination}
            fetchPage={fetchNews}
            deletePage={handleDeletePagina}
            loading={loading}
            {...props}
          />
        )}
        permission={newsRotues.index.permission}
        pageStates={pageStates}
      />
      <AuthorizedRoute
        exact
        path={`${path}/${newsRotues.add.path}`}
        component={(props) => (
          <NewsPagesOne
            action={FORM_ACTIONS.ADD}
            pageStates={pageStates}
            refreshTable={() => fetchNews(pagination.current_page, pagination.per_page)}
            {...props}
          />
        )}
        permission={newsRotues.add.permission}
        pageStates={pageStates}
      />
      <AuthorizedRoute
        exact
        path={`${path}/${newsRotues.edit.path}`}
        component={(props) => (
          <NewsPagesOne
            action={FORM_ACTIONS.EDIT}
            pageStates={pageStates}
            refreshTable={() => fetchNews(pagination.current_page, pagination.per_page)}
            {...props}
          />
        )}
        permission={newsRotues.edit.permission}
        pageStates={pageStates}
      />
    </Switch>
  );
};

export default NewsPagesContainer;
