import React from 'react'

import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import { Button, createTheme, FormControl, FormHelperText, Grid, makeStyles, TextField, ThemeProvider, Typography } from '@material-ui/core';
import { Save as SaveIcon, ArrowBack as BackIcon } from '@material-ui/icons'
import { red } from '@material-ui/core/colors';
import { getDataFormatedForSelects, getIdsFromDataSelects } from 'src/helpers/random';
import { Link } from 'react-router-dom';
import { routesApp } from 'src/routesService';
import SelectField from '../../shared/SelectField';

const theme = createTheme({
  palette: {
    secondary: red,
  },
});

const useStyles = makeStyles((theme) => ({
  color: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

const FormRol = (props) => {
  const classes = useStyles()

  const {
    rol,
    permissions,
    loading,
    updateRol,
    addRol,
  } = props;

  const getPermissionsOptions = () => {
    return getDataFormatedForSelects(permissions, 'id', 'display_name');
  };

  return (
    <Formik
      initialValues={{
        name: rol ? rol.name : '',
        displayName: rol ? rol.display_name : '',
        description: rol ? rol.description : '',
        permissions: rol ? getDataFormatedForSelects(rol.permissions, 'id', 'display_name') : []
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(255, 'El nombre no debe superar los 255 caracteres').required('El nombre es requerido'),
        displayName: Yup.string().max(255, 'El nombre a anotar no debe superar los 255 caracteres').required('El anombre a mostrar es requerido'),
        description: Yup.string().max(255, 'La descripción no debe superar los 255 caracteres').required('La descripción es requerida'),
        permissions: Yup.array().required("Los permisos son requeridos")
      })}
      onSubmit={async (data) => {
        const newData = {
          name: data.name,
          display_name: data.displayName,
          description: data.description,
          permissions: getIdsFromDataSelects(data.permissions, 'value')
        };

        if (rol) {
          await updateRol(newData, rol.id)
        } else {
          await addRol(newData)
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        setFieldValue,
        values
      }) => (
          <Form>
            <Grid container>
              <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
                <Typography color="textPrimary" variant="overline" className="font-weight-bold">General</Typography>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Nombre"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.displayName && errors.displayName)}
                  fullWidth
                  helperText={touched.displayName && errors.displayName}
                  label="Nombre a mostrar"
                  margin="normal"
                  name="displayName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.displayName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label="Descripción"
                  margin="normal"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.description}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6} className="pr-3 pl-3 pt-2">
                <Typography color="textPrimary" variant="overline" className="font-weight-bold">Permisos</Typography>
                <FormControl fullWidth margin="normal" error={Boolean(touched.permissions && errors.permissions)} >
                  <SelectField
                    isMulti
                    placeholder="Seleccione permiso..."
                    name="permissions"
                    getOptions={getPermissionsOptions}
                    label="Permiso"
                    setFieldValue={(value) => {
                      setFieldValue('permissions', value);
                    }}
                    value={values.permissions}
                  />
                  <FormHelperText className="text-danger">{(touched.permissions && errors.permissions) && errors.permissions}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
                <ThemeProvider theme={theme}>
                  <Link to={routesApp.roles.path}><Button variant="outlined" color="secondary" startIcon={<BackIcon />} className={classes.color}>Atrás</Button></Link>
                </ThemeProvider>
                <Button
                  color="primary"
                  disabled={loading}
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
    </Formik>
  )
}

export default FormRol
