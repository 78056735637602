import {
  PERMISSION_TYPES
} from '../actions/types';

const INIT_STATE = {
  all: null,
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PERMISSION_TYPES.GET_PERMISSIONS:
      return {
        ...state,
        loading: true
      };
    case PERMISSION_TYPES.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        all: action.payload,
        loading: false
      };
    case PERMISSION_TYPES.GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        all: null,
        loading: false
      };
    default: return { ...state };
  }
};
