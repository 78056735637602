import React from 'react';
import {
    Card, CardContent, Container, Typography
} from '@material-ui/core';
import { FORM_ACTIONS } from 'src/utils/constants/general';
import Page from '../Page';
import FormHeader from './shared/form';
import ErrorsValidation from '../shared/displayErrorsValidations';
import PageContainer from 'src/containers/shared/PageContainer';

const AddHeader = (props) => {
  const {
    errors,
    loading,
    paginasInst,
    portadas,
    headers,
    addHeader,
  } = props;

  return (
    <PageContainer title="Agregar Encabezado" maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          Agregando Encabezado
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <FormHeader
              action={FORM_ACTIONS.ADD}
              addHeader={addHeader}
              loading={loading}
              paginasInst={paginasInst}
              portadas={portadas}
              headers={headers}
            />
          </CardContent>
        </Card>
    </PageContainer>
  );
};

export default AddHeader;
