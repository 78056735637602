import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router';
import { routesApp } from 'src/routesService';
import { validateOnePermission } from 'src/helpers/permissions';
import { FAQS_PERMISSIONS } from 'src/utils/constants/permissions';
import DeleteItem from 'src/components/shared/DeleteItem';

const ITEM_HEIGHT = 48;

export default function Actions(props) {
  const {
    faq,
    agrupadorId,
    authPermissions,
    deleteFAQ,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {
          validateOnePermission(authPermissions, FAQS_PERMISSIONS.EDIT_FAQ) &&
          <MenuItem onClick={() => {
            history.push(routesApp.editFaq.path.replace(":agrupadorId", agrupadorId).replace(':faqId', faq.id));
          }}>
            Editar
          </MenuItem>
        }
        {
          validateOnePermission(authPermissions, FAQS_PERMISSIONS.DELETE_FAQ) &&
          <DeleteItem 
            deleteAction={deleteFAQ}
            item={faq.id}
            message={`¿Deseas eliminar el FAQ seleccionado?`}
            title="Eliminar FAQ"
          />
        }
      </Menu>
    </div>
  );
}

