import { VISITOR_REGISTER_TYPES } from './types';
import VisitorRegisterService from './../services/VisitorRegisterService';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { NotificationManager } from 'react-notifications';


export const getVisitorRegister = () => async dispatch => {
  dispatch({
    type: VISITOR_REGISTER_TYPES.GET_VISITOR_REGISTER
  })
  try {
    const result = await VisitorRegisterService.getVisitorRegister();

    dispatch({
      type: VISITOR_REGISTER_TYPES.GET_VISITOR_REGISTER_SUCCESS,
      payload: result.data
    })
  } catch (error) {
    dispatch({
      type: VISITOR_REGISTER_TYPES.GET_VISITOR_REGISTER_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener el codigo de Registro Visitas: ", error)
  }
}

export const storeVisitorRegister = (data) => async dispatch => {
  dispatch({
    type: VISITOR_REGISTER_TYPES.STORE_VISITOR_REGISTER
  })
  try {
    await VisitorRegisterService.storeVisitorRegister(data);

    dispatch({
      type: VISITOR_REGISTER_TYPES.STORE_VISITOR_REGISTER_SUCCESS,
    })

    NotificationManager.success("El codigo de Registro Visitas ha sido actualizado correctamente")

  } catch (error) {
    dispatch({
      type: VISITOR_REGISTER_TYPES.STORE_VISITOR_REGISTER_FAILURE,
      payload: error.response ? error.response.data.errors : null
    })

    NotificationManager.error("Error al guardar el codigo de Registro Visitas")
    showDeveloperMessageConsole("Error al guardar el codigo de Registro Visitas: ", error)
  }
};