import {
    VISITOR_REGISTER_TYPES,
  } from '../actions/types';
  
  const INIT_STATE = {
    code: null,
    errors: null,
    loading: false
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case VISITOR_REGISTER_TYPES.GET_VISITOR_REGISTER:
        return {
          ...state,
          loading: true
        };
      case VISITOR_REGISTER_TYPES.GET_VISITOR_REGISTER_SUCCESS:
        return {
          ...state,
          errors: null,
          code: action.payload,
          loading: false
        };
      case VISITOR_REGISTER_TYPES.GET_VISITOR_REGISTER_FAILURE:
        return {
          ...state,
          code: null,
          loading: false
        };
      case VISITOR_REGISTER_TYPES.STORE_VISITOR_REGISTER:
        return {
          ...state,
          loading: true
        };
      case VISITOR_REGISTER_TYPES.STORE_VISITOR_REGISTER_SUCCESS:
        return {
          ...state,
          errors: null,
          loading: false
        };
      case VISITOR_REGISTER_TYPES.STORE_VISITOR_REGISTER_FAILURE:
        return {
          ...state,
          errors: action.payload,
          loading: false
        };
      default: return { ...state };
    }
  };
  