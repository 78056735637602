import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Actions from 'src/components/users/shared/actions';
import { USERS_PERMISSIONS } from 'src/utils/constants/permissions';
import { validatePermissions } from 'src/helpers/permissions';
import TableHeader from 'src/components/shared/TableHeader';

const TableUsers = ({ auth, className, data, currentPage, itemsCountPerPage, totalItemsCount, prevPage, nextPage, lastPage, loading, ...rest }) => {
  const handlePageChange = (event, page) => {
    rest.getAllUsers(page + 1, parseInt(itemsCountPerPage))
  };

  const handleChangeRowsPerPage = (event) => {
    rest.getAllUsers(1, event.target.value)
  };

  return (
    <Card
      className={className}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Typography variant="subtitle1" className="mt-3 ml-3 mb-2">
            Listado de Usuarios
              {
              loading &&
              <CircularProgress size={24} style={{ marginLeft: 15 }} />
            }
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader property="id">ID</TableHeader>
                <TableHeader property="username">Usuario</TableHeader>
                <TableHeader property="name">Nombre</TableHeader>
                <TableHeader property="lastname">Apellido</TableHeader>
                <TableHeader property="email">Email</TableHeader>
                <TableCell>Rol</TableCell>
                {
                  validatePermissions(auth.permissions, { edit: USERS_PERMISSIONS.EDIT_USERS, delete: USERS_PERMISSIONS.DELETE_USERS }) &&
                  <TableCell>Acciones</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((user) => (
                <TableRow
                  hover
                  key={user.id}
                >
                  <TableCell>
                    {user.id}
                  </TableCell>
                  <TableCell>
                    {user.username}
                  </TableCell>
                  <TableCell>
                    {user.name}
                  </TableCell>
                  <TableCell>
                    {user.lastname}
                  </TableCell>
                  <TableCell>
                    {user.email}
                  </TableCell>
                  <TableCell>
                    <ul>
                      {
                        user.roles.map(r => {
                          return <li key={r.id}>{r.display_name}</li>
                        })
                      }
                    </ul>
                  </TableCell>
                  {
                    validatePermissions(auth.permissions, { edit: USERS_PERMISSIONS.EDIT_USERS, delete: USERS_PERMISSIONS.DELETE_USERS }) &&
                    <TableCell>
                      <Actions user={user} deleteUser={rest.deleteUser} authPermissions={auth.permissions} />
                    </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {
        data.length === 0 &&
        <div className="text-center mt-2" >
          No hay resultados para su búsqueda
        </div>
      }
      <TablePagination
        component="div"
        className="paginador"
        count={totalItemsCount}
        // count={data.length}//hasta que venga la api
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={currentPage - 1}
        rowsPerPage={parseInt(itemsCountPerPage)}
        rowsPerPageOptions={[5, 10, 20]}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      />
    </Card>
  );
};

export default TableUsers;
