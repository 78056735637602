import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Grid,
  Typography,
  Switch
} from '@material-ui/core';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Save as SaveIcon } from '@material-ui/icons';

// import { routesApp } from 'src/routesService';
// import { Link } from 'react-router-dom';
import { validateOnePermission } from 'src/helpers/permissions';
import { FOOTER_TEXT_PERMISSIONS } from 'src/utils/constants/permissions';
import { getTextFooter } from 'src/actions/footersActions';

import CKEditorTexto from 'src/components/shared/CKEditor/CKEditorTexto';
import Page from '../Page';
import ErrorsValidation from '../shared/displayErrorsValidations';
import Loading from '../shared/loading';
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';
import PageContainer from 'src/containers/shared/PageContainer';

const TextFooter = ({ auth, ...rest }) => {
  const footer = useSelector(({ footers }) => footers);
  const { text, errors: propsErrors, loading } = footer;

  useEffect(() => {
    rest.getTextFooter();
  }, []);

  if (footer.text === null) {
    return <Loading />;
  }

  return (
    <PageContainer title="Texto - Pie de página" maxWidth={false}>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            color="textPrimary"
            variant="h2"
            className="mb-3"
          >
            Texto - Pie de página
          </Typography>
          <PrimaryLinkButton to={`${process.env.REACT_APP_JUANAMANSO_REA}preview/footer`} external>
            Previsualizar Pie de página
          </PrimaryLinkButton>
        </Box>
        <ErrorsValidation errors={propsErrors} />
        <Card>
          <CardContent>
            <Formik
              initialValues={{
                active: text.active,
                text: text.text,
              }}
              validationSchema={Yup.object().shape({
                active: Yup.boolean(),
                text: Yup.string()
              })}
              onSubmit={async (data) => {
                const newData = {
                  text: data.text,
                  active: data.active
                };

                await rest.updateFooter(newData);
              }}
            >
              {({
                errors,
                // handleBlur,
                handleChange,
                setFieldValue,
                setFieldTouched,
                touched,
                values
              }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={12} md={12} className="pr-3 pl-3 pt-2">
                      <Typography color="textPrimary" variant="overline" className="font-weight-bold">Estado</Typography>
                      <FormControl fullWidth>
                        <FormControlLabel
                          control={(
                            <Switch
                              checked={values.active}
                              onChange={handleChange}
                              name="active"
                              color="primary"
                            />
                          )}
                          label="Activo"
                        />
                      </FormControl>
                      <Typography color="textPrimary" variant="overline" className="font-weight-bold">Texto</Typography>
                      <FormControl margin="normal" fullWidth error={Boolean(touched.body && errors.body)}>
                        <CKEditorTexto
                          id="text-footer"
                          value={values.text}
                          name="text"
                          styles={[
                            { name: 'height', value: '400px' },
                            { name: 'background-color', value: 'rgb(45, 176, 178)' },
                          ]}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                        <FormHelperText className="text-danger">{(touched.text && errors.text) && errors.text}</FormHelperText>
                      </FormControl>
                    </Grid>
                    {
                      validateOnePermission(auth.permissions, FOOTER_TEXT_PERMISSIONS.EDIT_FOOTER_TEXT) && (
                        <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
                          <Button
                            color="primary"
                            disabled={loading}
                            type="submit"
                            variant="contained"
                            startIcon={<SaveIcon />}
                          >
                            Guardar
                          </Button>
                        </Grid>
                      )
                    }
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
    </PageContainer>
  );
};

const mapDispatchToProps = {
  getTextFooter
}

export default connect(null, mapDispatchToProps)(TextFooter);
