import { PORTADAS_TYPES } from './types'
import PortadasService from './../services/PortadasService';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { routesApp } from 'src/routesService';
import { NotificationManager } from 'react-notifications';

export const getAllPortadas = (page = 1, rows = 10, filters = '') => async dispatch => {
  dispatch({
    type: PORTADAS_TYPES.GET_PORTADAS
  });
  try {
    const result = await PortadasService.getPortadas(page, rows, filters);
    dispatch({
      type: PORTADAS_TYPES.GET_PORTADAS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: PORTADAS_TYPES.GET_PORTADAS_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener las portadas: ', error);
  }
}

export const getOnePortada = (id) => async (dispatch) => {
  dispatch({
    type: PORTADAS_TYPES.GET_ONE_PORTADA
  });
  try {
    const result = await PortadasService.getOnePortada(id);
    dispatch({
      type: PORTADAS_TYPES.GET_ONE_PORTADA_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: PORTADAS_TYPES.GET_ONE_PORTADA_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener la portada: ', error);
  }
};

export const storePortada = (id, data, history) => async dispatch => {
  dispatch({
    type: PORTADAS_TYPES.STORE_PORTADA
  })
  try {
    if (id) {
      await PortadasService.updatePortada(data, id);
      NotificationManager.success("Portada actualizada exitosamente")
    } else {
      await PortadasService.addPortada(data);
      NotificationManager.success("Portada creada exitosamente")
    }

    dispatch({
      type: PORTADAS_TYPES.STORE_PORTADA_SUCCESS
    })

    //redirigir
    history.push(routesApp.portadas.path)
  } catch (error) {
    dispatch({
      type: PORTADAS_TYPES.STORE_PORTADA_FAILURE,
      payload: error.response ? error.response.data.errors : null
    })
    NotificationManager.error("Error al guardar la portada.")

    showDeveloperMessageConsole("Error al guardar la portada: ", error)
  }
}

export const getAllComponentes = (idPortada, filters="") => async dispatch => {
  dispatch({
    type: PORTADAS_TYPES.GET_COMPONENTES
  });
  try {
    const result = await PortadasService.getComponentes(idPortada, filters);
    dispatch({
      type: PORTADAS_TYPES.GET_COMPONENTES_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: PORTADAS_TYPES.GET_COMPONENTES_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener los componentes: ', error);
  }
};

export const getOneComponent = (id, portadaId) => async (dispatch) => {
  dispatch({
    type: PORTADAS_TYPES.GET_ONE_COMPONENT
  });
  try {
    const result = await PortadasService.getOneComponent(id);
    dispatch({
      type: PORTADAS_TYPES.GET_ONE_COMPONENT_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: PORTADAS_TYPES.GET_ONE_COMPONENT_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener el componente: ', error);
  }
};

export const storeComponent = (id, portadaId, data, history) => async dispatch => {
  dispatch({
    type: PORTADAS_TYPES.STORE_COMPONENT
  })
  try {
    if (id) {
      await PortadasService.updateComponent(data, id, portadaId);
      NotificationManager.success("Componente actualizado exitosamente")
    } else {
      await PortadasService.addComponent(data, portadaId);
      NotificationManager.success("Componente creado exitosamente")
    }

    dispatch({
      type: PORTADAS_TYPES.STORE_COMPONENT_SUCCESS
    })

    //redirigir
    history.push(routesApp.componentes.path.replace(":portadaId", portadaId))
  } catch (error) {
    dispatch({
      type: PORTADAS_TYPES.STORE_COMPONENT_FAILURE,
      payload: error.response ? error.response.data.errors : null
    })
    NotificationManager.error("Error al guardar el componente.")

    showDeveloperMessageConsole("Error al guardar el componente: ", error)
  }
}

export const getTypesComponent = () => async (dispatch) => {
  dispatch({
    type: PORTADAS_TYPES.GET_COMPONENT_TYPES
  });
  try {
    const result = await PortadasService.getTypesComponent();
    dispatch({
      type: PORTADAS_TYPES.GET_COMPONENT_TYPES_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: PORTADAS_TYPES.GET_COMPONENT_TYPES_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener la portada: ', error);
  }
};

export const getListActive = () => async dispatch => {
  dispatch({
    type: PORTADAS_TYPES.GET_LIST_ACTIVE
  })
  try {
    const result=await PortadasService.getListActive();
    const aux=[]
    // eslint-disable-next-line
    result.data.map(e=>{
      aux.push({id:e.id, link: `${process.env.REACT_APP_JUANAMANSO_REA}${e.slug}`})
    })
    dispatch({
      type: PORTADAS_TYPES.GET_LIST_ACTIVE_SUCCESS,
      payload:aux
    })
  } catch (error) {
    dispatch({
      type: PORTADAS_TYPES.GET_LIST_ACTIVE_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener el listado activo de las PORTADAS: ", error)
  }
}
