import { routesApp } from 'src/routesService';
import { FOOTER_TYPES, LOGO_FOOTER_TYPES } from './types';
import FootersService from './../services/FootersService';
import { showDeveloperMessageConsole } from 'src/helpers/random';
import { NotificationManager } from 'react-notifications';

export const getAllFooters = (page = 1, rows = 10, filters = '') => async dispatch => {
  dispatch({
    type: FOOTER_TYPES.GET_FOOTERS
  });
  try {
    const result = await FootersService.getFooters(page, rows, filters);
    dispatch({
      type: FOOTER_TYPES.GET_FOOTERS_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: FOOTER_TYPES.GET_FOOTERS_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener los pie de página: ', error);
  }
};

export const getTextFooter = () => async dispatch => {
  dispatch({
    type: FOOTER_TYPES.GET_TEXT_FOOTER
  })
  try {
    const result = await FootersService.getFooterText();

    dispatch({
      type: FOOTER_TYPES.GET_TEXT_FOOTER_SUCCESS,
      payload: result.data
      // payload: {
      //   id:1,
      //   text:"<p>Juanito alcachofaz</p>"
      // }
    })
  } catch (error) {
    dispatch({
      type: FOOTER_TYPES.GET_TEXT_FOOTER_FAILURE
    })
    showDeveloperMessageConsole("Error al obtener el texto del footer: ", error)
  }
}

export const storeTextFooter = (data) => async dispatch => {
  dispatch({
    type: FOOTER_TYPES.STORE_TEXT_FOOTER
  })
  try {
    await FootersService.storeTextFooter(data);

    dispatch({
      type: FOOTER_TYPES.STORE_TEXT_FOOTER_SUCCESS,
    });

    const result = await FootersService.getFooterText();
    dispatch({
      type: FOOTER_TYPES.GET_TEXT_FOOTER_SUCCESS,
      payload: result.data
    });

    NotificationManager.success("El texto del pie de página ha sido actualizado correctamente")

  } catch (error) {
    dispatch({
      type: FOOTER_TYPES.STORE_TEXT_FOOTER_FAILURE,
      payload: error.response ? error.response.data.errors : null
    })

    NotificationManager.error("Error al guardar el texto del pie de página")
    showDeveloperMessageConsole("Error al guardar el texto del footer: ", error)
  }
};

export const storeLogo = (data) => async dispatch => {
  dispatch({
    type: LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER
  });
  try {
    await FootersService.updateLogo(data);
    NotificationManager.success('Logo actualizado exitosamente');
    dispatch({
      type: LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER_SUCCESS
    });
    
  } catch (error) {
    dispatch({
      type: LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER_FAILURE,
      payload: error.response ? error.response.data.errors : null
    });
    NotificationManager.error('Error al guardar el logo.');

    showDeveloperMessageConsole('Error al guardar logo: ', error);
  }
};

export const getLogo = () => async dispatch => {
  dispatch({
    type: LOGO_FOOTER_TYPES.GET_LOGO_FOOTER
  });
  try {
    const result = await FootersService.getLogo();
    dispatch({
      type: LOGO_FOOTER_TYPES.GET_LOGO_FOOTER_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: LOGO_FOOTER_TYPES.GET_LOGO_FOOTER_FAILURE,
      payload: error
    });
    showDeveloperMessageConsole('Error al obtener el logo: ', error);
  }
};

export const storeLogoCentro = (data) => async (dispatch) => {
  dispatch({
    type: LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER_CENTRO
  });
  try {
    await FootersService.updateLogoCentro(data);
    NotificationManager.success('Logo centro actualizado exitosamente');
    dispatch({
      type: LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER_CENTRO_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: LOGO_FOOTER_TYPES.STORE_LOGO_FOOTER_CENTRO_FAILURE,
      payload: error.response ? error.response.data.errors : null
    });
    NotificationManager.error('Error al guardar el logo centro.');

    showDeveloperMessageConsole('Error al guardar logo centro: ', error);
  }
};

export const getLogoCentro = () => async (dispatch) => {
  dispatch({
    type: LOGO_FOOTER_TYPES.GET_LOGO_FOOTER_CENTRO
  });
  try {
    const result = await FootersService.getLogoCentro();
    dispatch({
      type: LOGO_FOOTER_TYPES.GET_LOGO_FOOTER_CENTRO_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: LOGO_FOOTER_TYPES.GET_LOGO_FOOTER_CENTRO_FAILURE,
      payload: error
    });
    showDeveloperMessageConsole('Error al obtener el logo: ', error);
  }
};

export const getOneFooter = (id) => async dispatch => {
  dispatch({
    type: FOOTER_TYPES.GET_ONE_FOOTER
  });
  try {
    const result = await FootersService.getOneFooter(id);
    dispatch({
      type: FOOTER_TYPES.GET_ONE_FOOTER_SUCCESS,
      payload: result.data
    });
  } catch (error) {
    dispatch({
      type: FOOTER_TYPES.GET_ONE_FOOTER_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener un pie de página: ', error);
  }
};

export const storeFooter = (id, data, history) => async dispatch => {
  dispatch({
    type: FOOTER_TYPES.STORE_FOOTER
  });
  try {
    if (id) {
      await FootersService.updateFooter(data, id);
      NotificationManager.success('Pie de página actualizado exitosamente');
    } else {
      await FootersService.addFooter(data);
      NotificationManager.success('Pie de página creado exitosamente');
    }

    dispatch({
      type: FOOTER_TYPES.STORE_FOOTER_SUCCESS
    });

    history.push(routesApp.footers.path);
  } catch (error) {
    dispatch({
      type: FOOTER_TYPES.STORE_FOOTER_FAILURE,
      payload: error.response ? error.response.data.errors : null
    });
    NotificationManager.error('Error al guardar el pie de página.');

    showDeveloperMessageConsole('Error al guardar pie de página: ', error);
  }
};

export const getPrevFooter = () => async dispatch => {
  dispatch({
    type: FOOTER_TYPES.GET_PREV_FOOTERS
  });
  try {
    const text = await FootersService.getFooterTextPrev();
    const logo = await FootersService.getFooterLogoPrev();
    const menu = await FootersService.getFooterMenuPrev();
    dispatch({
      type: FOOTER_TYPES.GET_PREV_FOOTERS_SUCCESS,
      payload: {
        text: text.data,
        logo: logo.data,
        menu: menu.data
      }
    });
  } catch (error) {
    dispatch({
      type: FOOTER_TYPES.GET_PREV_FOOTERS_FAILURE
    });
    showDeveloperMessageConsole('Error al obtener la previzualizacion del pie de página: ', error);
  }
};
