import React from 'react'
import { Box, Container } from '@material-ui/core'

import Page from '../Page'
import BarraBusqueda from './shared/barraBusqueda'
import { routesApp } from 'src/routesService'
import { validateOnePermission } from 'src/helpers/permissions'
import { PAGINAS_INSTITUCIONALES_PERMISSIONS } from 'src/utils/constants/permissions'
import TablePaginas from './shared/tablePaginas'
import { PrimaryLinkButton } from '../shared/Buttons/PrimaryLinkButton';
import PageContainer from 'src/containers/shared/PageContainer';

const PaginasInstitucionales = (props) => {
  const {
    paginas,
    states,
    loading,
    auth,
    getAllPaginas,
    deletePagina,
  } = props

  return (
    <PageContainer title="Páginas Institucionales" maxWidth={false}>
        {
          validateOnePermission(auth.permissions, PAGINAS_INSTITUCIONALES_PERMISSIONS.CREATE_PAGINAS) &&
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <PrimaryLinkButton to={routesApp.addPaginasInstitucionales.path}>
              + Añadir Página Institucional
            </PrimaryLinkButton>
          </Box>
        }
        <BarraBusqueda states={states} />
        <Box mt={3}>
          <TablePaginas
            auth={auth}
            data={paginas.data}
            // data={dataPaginas}//prueba
            currentPage={paginas.current_page}
            itemsCountPerPage={paginas.per_page}
            totalItemsCount={paginas.total}
            nextPage={paginas.next_page_url}
            prevPage={paginas.prev_page_url}
            lastPage={paginas.last_page}
            loading={loading}
            getAllPaginas={getAllPaginas}
            deletePagina={deletePagina}
          />
        </Box>
    </PageContainer>
  )
}

export default PaginasInstitucionales
