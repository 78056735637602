import React, { forwardRef } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { Container } from '@material-ui/core'

const PageContainer = forwardRef(({
  children, title = '', overrideTitle = false, ...rest
// eslint-disable-next-line no-unused-vars
}, ref) => {
  return (
    <Container className="root-pages" {...rest}>
      <Helmet>
        <title>{overrideTitle ? title : `${title} - ${process.env.REACT_APP_NAME}`}</title>
      </Helmet>
      {children}
    </Container>
  )
})

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  overrideTitle: PropTypes.bool
};

export default PageContainer
