import React from 'react';
import { Button, Card, CardContent, Container, FormControl, FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import { Save as SaveIcon } from '@material-ui/icons';

import CKEditorTexto from 'src/components/shared/CKEditor/CKEditorTexto';
import Page from '../Page';
import ErrorsValidation from '../shared/displayErrorsValidations';
import PageContainer from 'src/containers/shared/PageContainer';

const TextContactForm = (props) => {
  const {
    text,
    errors,
    loading,
    updateContact,
  } = props;

  return (
    <PageContainer title="Texto - Form. Contacto" maxWidth={false}>
        <Typography
          color="textPrimary"
          variant="h2"
          className="mb-3"
        >
          Texto - Form. Contacto
        </Typography>
        <ErrorsValidation errors={errors} />
        <Card>
          <CardContent>
            <Formik
              initialValues={{
                text: text ? text : '',
              }}
              validationSchema={Yup.object().shape({
                text: Yup.string().required("El texto del formulario del contacto es requerido")
              })}
              onSubmit={async (data, actions) => {
                const newData = {
                  contact: data.text
                }

                await updateContact(newData);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                setFieldValue,
                setFieldTouched,
                touched,
                values
              }) => (
                <Form>
                  <Grid container>
                    <Grid item xs={12} md={12} className="pr-3 pl-3 pt-2">
                      <Typography color="textPrimary" variant="overline" className="font-weight-bold">Texto</Typography>
                      <FormControl margin="normal" fullWidth error={Boolean(touched.body && errors.body)}>
                        <CKEditorTexto
                          id="contact-form"
                          value={values.text}
                          name="text"
                          styles={[
                            { name: 'height', value: '400px' },
                          ]}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                        <FormHelperText className="text-danger">{(touched.text && errors.text) && errors.text}</FormHelperText>
                      </FormControl>
                      <TextField
                        label="URL-Contacto"
                        value={`${process.env.REACT_APP_JUANAMANSO_REA}contacto`}
                        type="text"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} className="pr-3 pl-3 pt-2 text-right mt-2">
                      <Button
                        color="primary"
                        disabled={loading}
                        type="submit"
                        variant="contained"
                        startIcon={<SaveIcon />}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
    </PageContainer>
  );
};

export default TextContactForm;
