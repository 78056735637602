import api from '../api';

const RolesService = {
  getRoles: (filters) => {
    return api.get(`roles${filters}`);
  },
  addRol:(data)=>{
    return api.post(`roles`, data)
  },
  getOneRol:(id)=>{
    return api.get(`roles/${id}`)
  },
  updateRol:(data, id)=>{
    return api.put(`roles/${id}`, data)
  },
  deleteRol:(id)=>{
    return api.delete(`roles/${id}`)
  },
};

export { RolesService as default };
